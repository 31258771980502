const states = [
    { "sigla": "AC", "nome": "Acre" },
    { "sigla": "AL", "nome": "Alagoas" },
    { "sigla": "AM", "nome": "Amazonas" },
    { "sigla": "AP", "nome": "Amapá" },
    { "sigla": "BA", "nome": "Bahia" },
    { "sigla": "CE", "nome": "Ceará" },
    { "sigla": "DF", "nome": "Distrito Federal" },
    { "sigla": "ES", "nome": "Espírito Santo" },
    { "sigla": "GO", "nome": "Goiás" },
    { "sigla": "MA", "nome": "Maranhão" },
    { "sigla": "MG", "nome": "Minas Gerais" },
    { "sigla": "MS", "nome": "Mato Grosso do Sul" },
    { "sigla": "MT", "nome": "Mato Grosso" },
    { "sigla": "PA", "nome": "Pará" },
    { "sigla": "PB", "nome": "Paraíba" },
    { "sigla": "PE", "nome": "Pernambuco" },
    { "sigla": "PI", "nome": "Piauí" },
    { "sigla": "PR", "nome": "Paraná" },
    { "sigla": "RJ", "nome": "Rio de Janeiro" },
    { "sigla": "RN", "nome": "Rio Grande do Norte" },
    { "sigla": "RO", "nome": "Rondônia" },
    { "sigla": "RR", "nome": "Roraima" },
    { "sigla": "RS", "nome": "Rio Grande do Sul" },
    { "sigla": "SC", "nome": "Santa Catarina" },
    { "sigla": "SE", "nome": "Sergipe" },
    { "sigla": "SP", "nome": "São Paulo" },
    { "sigla": "TO", "nome": "Tocantins" }
]

const cities = {
    "AC": [
        { nome: "Acrelândia" },
        { nome: "Assis Brasil" },
        { nome: "Brasiléia" },
        { nome: "Bujari" },
        { nome: "Capixaba" },
        { nome: "Cruzeiro do Sul" },
        { nome: "Epitaciolândia" },
        { nome: "Feijó" },
        { nome: "Jordão" },
        { nome: "Mâncio Lima" },
        { nome: "Manoel Urbano" },
        { nome: "Marechal Thaumaturgo" },
        { nome: "Plácido de Castro" },
        { nome: "Porto Acre" },
        { nome: "Porto Walter" },
        { nome: "Rio Branco" },
        { nome: "Rodrigues Alves" },
        { nome: "Santa Rosa do Purus" },
        { nome: "Sena Madureira" },
        { nome: "Senador Guiomard" },
        { nome: "Tarauacá" },
        { nome: "Xapuri" }
    ],

    "AL": [
        { nome: "Água Branca" },
        { nome: "Anadia" },
        { nome: "Arapiraca" },
        { nome: "Atalaia" },
        { nome: "Barra de Santo Antônio" },
        { nome: "Barra de São Miguel" },
        { nome: "Batalha" },
        { nome: "Belém" },
        { nome: "Belo Monte" },
        { nome: "Boca da Mata" },
        { nome: "Branquinha" },
        { nome: "Cacimbinhas" },
        { nome: "Cajueiro" },
        { nome: "Campestre" },
        { nome: "Campo Alegre" },
        { nome: "Campo Grande" },
        { nome: "Canapi" },
        { nome: "Capela" },
        { nome: "Carneiros" },
        { nome: "Chã Preta" },
        { nome: "Coité do Nóia" },
        { nome: "Colônia Leopoldina" },
        { nome: "Coqueiro Seco" },
        { nome: "Coruripe" },
        { nome: "Craíbas" },
        { nome: "Delmiro Gouveia" },
        { nome: "Dois Riachos" },
        { nome: "Estrela de Alagoas" },
        { nome: "Feira Grande" },
        { nome: "Feliz Deserto" },
        { nome: "Flexeiras" },
        { nome: "Girau do Ponciano" },
        { nome: "Ibateguara" },
        { nome: "Igaci" },
        { nome: "Igreja Nova" },
        { nome: "Inhapi" },
        { nome: "Jacaré dos Homens" },
        { nome: "Jacuípe" },
        { nome: "Japaratinga" },
        { nome: "Jaramataia" },
        { nome: "Jequiá da Praia" },
        { nome: "Joaquim Gomes" },
        { nome: "Jundiá" },
        { nome: "Junqueiro" },
        { nome: "Lagoa da Canoa" },
        { nome: "Limoeiro de Anadia" },
        { nome: "Maceió" },
        { nome: "Major Isidoro" },
        { nome: "Mar Vermelho" },
        { nome: "Maragogi" },
        { nome: "Maravilha" },
        { nome: "Marechal Deodoro" },
        { nome: "Maribondo" },
        { nome: "Mata Grande" },
        { nome: "Matriz de Camaragibe" },
        { nome: "Messias" },
        { nome: "Minador do Negrão" },
        { nome: "Monteirópolis" },
        { nome: "Murici" },
        { nome: "Novo Lino" },
        { nome: "Olho d'Água das Flores" },
        { nome: "Olho d'Água do Casado" },
        { nome: "Olho d'Água Grande" },
        { nome: "Olivença" },
        { nome: "Ouro Branco" },
        { nome: "Palestina" },
        { nome: "Palmeira dos Índios" },
        { nome: "Pão de Açúcar" },
        { nome: "Pariconha" },
        { nome: "Paripueira" },
        { nome: "Passo de Camaragibe" },
        { nome: "Paulo Jacinto" },
        { nome: "Penedo" },
        { nome: "Piaçabuçu" },
        { nome: "Pilar" },
        { nome: "Pindoba" },
        { nome: "Piranhas" },
        { nome: "Poço das Trincheiras" },
        { nome: "Porto Calvo" },
        { nome: "Porto de Pedras" },
        { nome: "Porto Real do Colégio" },
        { nome: "Quebrangulo" },
        { nome: "Rio Largo" },
        { nome: "Roteiro" },
        { nome: "Santa Luzia do Norte" },
        { nome: "Santana do Ipanema" },
        { nome: "Santana do Mundaú" },
        { nome: "São Brás" },
        { nome: "São José da Laje" },
        { nome: "São José da Tapera" },
        { nome: "São Luís do Quitunde" },
        { nome: "São Miguel dos Campos" },
        { nome: "São Miguel dos Milagres" },
        { nome: "São Sebastião" },
        { nome: "Satuba" },
        { nome: "Senador Rui Palmeira" },
        { nome: "Tanque d'Arca" },
        { nome: "Taquarana" },
        { nome: "Teotônio Vilela" },
        { nome: "Traipu" },
        { nome: "União dos Palmares" },
        { nome: "Viçosa" }
    ],

    "AM": [
        { nome: "Alvarães" },
        { nome: "Amaturá" },
        { nome: "Anamã" },
        { nome: "Anori" },
        { nome: "Apuí" },
        { nome: "Atalaia do Norte" },
        { nome: "Autazes" },
        { nome: "Barcelos" },
        { nome: "Barreirinha" },
        { nome: "Benjamin Constant" },
        { nome: "Beruri" },
        { nome: "Boa Vista do Ramos" },
        { nome: "Boca do Acre" },
        { nome: "Borba" },
        { nome: "Caapiranga" },
        { nome: "Canutama" },
        { nome: "Carauari" },
        { nome: "Careiro" },
        { nome: "Careiro da Várzea" },
        { nome: "Coari" },
        { nome: "Codajás" },
        { nome: "Eirunepé" },
        { nome: "Envira" },
        { nome: "Fonte Boa" },
        { nome: "Guajará" },
        { nome: "Humaitá" },
        { nome: "Ipixuna" },
        { nome: "Iranduba" },
        { nome: "Itacoatiara" },
        { nome: "Itamarati" },
        { nome: "Itapiranga" },
        { nome: "Japurá" },
        { nome: "Juruá" },
        { nome: "Jutaí" },
        { nome: "Lábrea" },
        { nome: "Manacapuru" },
        { nome: "Manaquiri" },
        { nome: "Manaus" },
        { nome: "Manicoré" },
        { nome: "Maraã" },
        { nome: "Maués" },
        { nome: "Nhamundá" },
        { nome: "Nova Olinda do Norte" },
        { nome: "Novo Airão" },
        { nome: "Novo Aripuanã" },
        { nome: "Parintins" },
        { nome: "Pauini" },
        { nome: "Presidente Figueiredo" },
        { nome: "Rio Preto da Eva" },
        { nome: "Santa Isabel do Rio Negro" },
        { nome: "Santo Antônio do Içá" },
        { nome: "São Gabriel da Cachoeira" },
        { nome: "São Paulo de Olivença" },
        { nome: "São Sebastião do Uatumã" },
        { nome: "Silves" },
        { nome: "Tabatinga" },
        { nome: "Tapauá" },
        { nome: "Tefé" },
        { nome: "Tonantins" },
        { nome: "Uarini" },
        { nome: "Urucará" },
        { nome: "Urucurituba" }
    ],

    "AP": [
        { nome: "Amapá" },
        { nome: "Calçoene" },
        { nome: "Cutias" },
        { nome: "Ferreira Gomes" },
        { nome: "Itaubal" },
        { nome: "Laranjal do Jari" },
        { nome: "Macapá" },
        { nome: "Mazagão" },
        { nome: "Oiapoque" },
        { nome: "Pedra Branca do Amapari" },
        { nome: "Porto Grande" },
        { nome: "Pracuúba" },
        { nome: "Santana" },
        { nome: "Serra do Navio" },
        { nome: "Tartarugalzinho" },
        { nome: "Vitória do Jari" }
    ],

    "BA": [
        { nome: "Abaíra" },
        { nome: "Abaré" },
        { nome: "Acajutiba" },
        { nome: "Adustina" },
        { nome: "Água Fria" },
        { nome: "Aiquara" },
        { nome: "Alagoinhas" },
        { nome: "Alcobaça" },
        { nome: "Almadina" },
        { nome: "Amargosa" },
        { nome: "Amélia Rodrigues" },
        { nome: "América Dourada" },
        { nome: "Anagé" },
        { nome: "Andaraí" },
        { nome: "Andorinha" },
        { nome: "Angical" },
        { nome: "Anguera" },
        { nome: "Antas" },
        { nome: "Antônio Cardoso" },
        { nome: "Antônio Gonçalves" },
        { nome: "Aporá" },
        { nome: "Apuarema" },
        { nome: "Araças" },
        { nome: "Aracatu" },
        { nome: "Araci" },
        { nome: "Aramari" },
        { nome: "Arataca" },
        { nome: "Aratuípe" },
        { nome: "Aurelino Leal" },
        { nome: "Baianópolis" },
        { nome: "Baixa Grande" },
        { nome: "Banzaê" },
        { nome: "Barra" },
        { nome: "Barra da Estiva" },
        { nome: "Barra do Choça" },
        { nome: "Barra do Mendes" },
        { nome: "Barra do Rocha" },
        { nome: "Barreiras" },
        { nome: "Barro Alto" },
        { nome: "Barrocas" },
        { nome: "Barro Preto" },
        { nome: "Belmonte" },
        { nome: "Belo Campo" },
        { nome: "Biritinga" },
        { nome: "Boa Nova" },
        { nome: "Boa Vista do Tupim" },
        { nome: "Bom Jesus da Lapa" },
        { nome: "Bom Jesus da Serra" },
        { nome: "Boninal" },
        { nome: "Bonito" },
        { nome: "Boquira" },
        { nome: "Botuporã" },
        { nome: "Brejões" },
        { nome: "Brejolândia" },
        { nome: "Brotas de Macaúbas" },
        { nome: "Brumado" },
        { nome: "Buerarema" },
        { nome: "Buritirama" },
        { nome: "Caatiba" },
        { nome: "Cabaceiras do Paraguaçu" },
        { nome: "Cachoeira" },
        { nome: "Caculé" },
        { nome: "Caém" },
        { nome: "Caetanos" },
        { nome: "Caetité" },
        { nome: "Cafarnaum" },
        { nome: "Cairu" },
        { nome: "Caldeirão Grande" },
        { nome: "Camacan" },
        { nome: "Camaçari" },
        { nome: "Camamu" },
        { nome: "Campo Alegre de Lourdes" },
        { nome: "Campo Formoso" },
        { nome: "Canápolis" },
        { nome: "Canarana" },
        { nome: "Canavieiras" },
        { nome: "Candeal" },
        { nome: "Candeias" },
        { nome: "Candiba" },
        { nome: "Cândido Sales" },
        { nome: "Cansanção" },
        { nome: "Canudos" },
        { nome: "Capela do Alto Alegre" },
        { nome: "Capim Grosso" },
        { nome: "Caraíbas" },
        { nome: "Caravelas" },
        { nome: "Cardeal da Silva" },
        { nome: "Carinhanha" },
        { nome: "Casa Nova" },
        { nome: "Castro Alves" },
        { nome: "Catolândia" },
        { nome: "Catu" },
        { nome: "Caturama" },
        { nome: "Central" },
        { nome: "Chorrochó" },
        { nome: "Cícero Dantas" },
        { nome: "Cipó" },
        { nome: "Coaraci" },
        { nome: "Cocos" },
        { nome: "Conceição da Feira" },
        { nome: "Conceição do Almeida" },
        { nome: "Conceição do Coité" },
        { nome: "Conceição do Jacuípe" },
        { nome: "Conde" },
        { nome: "Condeúba" },
        { nome: "Contendas do Sincorá" },
        { nome: "Coração de Maria" },
        { nome: "Cordeiros" },
        { nome: "Coribe" },
        { nome: "Coronel João Sá" },
        { nome: "Correntina" },
        { nome: "Cotegipe" },
        { nome: "Cravolândia" },
        { nome: "Crisópolis" },
        { nome: "Cristópolis" },
        { nome: "Cruz das Almas" },
        { nome: "Curaçá" },
        { nome: "Dário Meira" },
        { nome: "Dias d'Ávila" },
        { nome: "Dom Basílio" },
        { nome: "Dom Macedo Costa" },
        { nome: "Elísio Medrado" },
        { nome: "Encruzilhada" },
        { nome: "Entre Rios" },
        { nome: "Érico Cardoso" },
        { nome: "Esplanada" },
        { nome: "Euclides da Cunha" },
        { nome: "Eunápolis" },
        { nome: "Fátima" },
        { nome: "Feira da Mata" },
        { nome: "Feira de Santana" },
        { nome: "Filadélfia" },
        { nome: "Firmino Alves" },
        { nome: "Floresta Azul" },
        { nome: "Formosa do Rio Preto" },
        { nome: "Gandu" },
        { nome: "Gavião" },
        { nome: "Gentio do Ouro" },
        { nome: "Glória" },
        { nome: "Gongogi" },
        { nome: "Governador Mangabeira" },
        { nome: "Guajeru" },
        { nome: "Guanambi" },
        { nome: "Guaratinga" },
        { nome: "Heliópolis" },
        { nome: "Iaçu" },
        { nome: "Ibiassucê" },
        { nome: "Ibicaraí" },
        { nome: "Ibicoara" },
        { nome: "Ibicuí" },
        { nome: "Ibipeba" },
        { nome: "Ibipitanga" },
        { nome: "Ibiquera" },
        { nome: "Ibirapitanga" },
        { nome: "Ibirapuã" },
        { nome: "Ibirataia" },
        { nome: "Ibitiara" },
        { nome: "Ibititá" },
        { nome: "Ibotirama" },
        { nome: "Ichu" },
        { nome: "Igaporã" },
        { nome: "Igrapiúna" },
        { nome: "Iguaí" },
        { nome: "Ilhéus" },
        { nome: "Inhambupe" },
        { nome: "Ipecaetá" },
        { nome: "Ipiaú" },
        { nome: "Ipirá" },
        { nome: "Ipupiara" },
        { nome: "Irajuba" },
        { nome: "Iramaia" },
        { nome: "Iraquara" },
        { nome: "Irará" },
        { nome: "Irecê" },
        { nome: "Itabela" },
        { nome: "Itaberaba" },
        { nome: "Itabuna" },
        { nome: "Itacaré" },
        { nome: "Itaeté" },
        { nome: "Itagi" },
        { nome: "Itagibá" },
        { nome: "Itagimirim" },
        { nome: "Itaguaçu da Bahia" },
        { nome: "Itaju do Colônia" },
        { nome: "Itajuípe" },
        { nome: "Itamaraju" },
        { nome: "Itamari" },
        { nome: "Itambé" },
        { nome: "Itanagra" },
        { nome: "Itanhém" },
        { nome: "Itaparica" },
        { nome: "Itapé" },
        { nome: "Itapebi" },
        { nome: "Itapetinga" },
        { nome: "Itapicuru" },
        { nome: "Itapitanga" },
        { nome: "Itaquara" },
        { nome: "Itarantim" },
        { nome: "Itatim" },
        { nome: "Itiruçu" },
        { nome: "Itiúba" },
        { nome: "Itororó" },
        { nome: "Ituaçu" },
        { nome: "Ituberá" },
        { nome: "Iuiú" },
        { nome: "Jaborandi" },
        { nome: "Jacaraci" },
        { nome: "Jacobina" },
        { nome: "Jaguaquara" },
        { nome: "Jaguarari" },
        { nome: "Jaguaripe" },
        { nome: "Jandaíra" },
        { nome: "Jequié" },
        { nome: "Jeremoabo" },
        { nome: "Jiquiriçá" },
        { nome: "Jitaúna" },
        { nome: "João Dourado" },
        { nome: "Juazeiro" },
        { nome: "Jucuruçu" },
        { nome: "Jussara" },
        { nome: "Jussari" },
        { nome: "Jussiape" },
        { nome: "Lafaiete Coutinho" },
        { nome: "Lagoa Real" },
        { nome: "Laje" },
        { nome: "Lajedão" },
        { nome: "Lajedinho" },
        { nome: "Lajedo do Tabocal" },
        { nome: "Lamarão" },
        { nome: "Lapão" },
        { nome: "Lauro de Freitas" },
        { nome: "Lençóis" },
        { nome: "Licínio de Almeida" },
        { nome: "Livramento de Nossa Senhora" },
        { nome: "Luís Eduardo Magalhães" },
        { nome: "Macajuba" },
        { nome: "Macarani" },
        { nome: "Macaúbas" },
        { nome: "Macururé" },
        { nome: "Madre de Deus" },
        { nome: "Maetinga" },
        { nome: "Maiquinique" },
        { nome: "Mairi" },
        { nome: "Malhada" },
        { nome: "Malhada de Pedras" },
        { nome: "Manoel Vitorino" },
        { nome: "Mansidão" },
        { nome: "Maracás" },
        { nome: "Maragogipe" },
        { nome: "Maraú" },
        { nome: "Marcionílio Souza" },
        { nome: "Mascote" },
        { nome: "Mata de São João" },
        { nome: "Matina" },
        { nome: "Medeiros Neto" },
        { nome: "Miguel Calmon" },
        { nome: "Milagres" },
        { nome: "Mirangaba" },
        { nome: "Mirante" },
        { nome: "Monte Santo" },
        { nome: "Morpará" },
        { nome: "Morro do Chapéu" },
        { nome: "Mortugaba" },
        { nome: "Mucugê" },
        { nome: "Mucuri" },
        { nome: "Mulungu do Morro" },
        { nome: "Mundo Novo" },
        { nome: "Muniz Ferreira" },
        { nome: "Muquém de São Francisco" },
        { nome: "Muritiba" },
        { nome: "Mutuípe" },
        { nome: "Nazaré" },
        { nome: "Nilo Peçanha" },
        { nome: "Nordestina" },
        { nome: "Nova Canaã" },
        { nome: "Nova Fátima" },
        { nome: "Nova Ibiá" },
        { nome: "Nova Itarana" },
        { nome: "Nova Redenção" },
        { nome: "Nova Soure" },
        { nome: "Nova Viçosa" },
        { nome: "Novo Horizonte" },
        { nome: "Novo Triunfo" },
        { nome: "Olindina" },
        { nome: "Oliveira dos Brejinhos" },
        { nome: "Ouriçangas" },
        { nome: "Ourolândia" },
        { nome: "Palmas de Monte Alto" },
        { nome: "Palmeiras" },
        { nome: "Paramirim" },
        { nome: "Paratinga" },
        { nome: "Paripiranga" },
        { nome: "Pau Brasil" },
        { nome: "Paulo Afonso" },
        { nome: "Pé de Serra" },
        { nome: "Pedrão" },
        { nome: "Pedro Alexandre" },
        { nome: "Piatã" },
        { nome: "Pilão Arcado" },
        { nome: "Pindaí" },
        { nome: "Pindobaçu" },
        { nome: "Pintadas" },
        { nome: "Piraí do Norte" },
        { nome: "Piripá" },
        { nome: "Piritiba" },
        { nome: "Planaltino" },
        { nome: "Planalto" },
        { nome: "Poções" },
        { nome: "Pojuca" },
        { nome: "Ponto Novo" },
        { nome: "Porto Seguro" },
        { nome: "Potiraguá" },
        { nome: "Prado" },
        { nome: "Presidente Dutra" },
        { nome: "Presidente Jânio Quadros" },
        { nome: "Presidente Tancredo Neves" },
        { nome: "Queimadas" },
        { nome: "Quijingue" },
        { nome: "Quixabeira" },
        { nome: "Rafael Jambeiro" },
        { nome: "Remanso" },
        { nome: "Retirolândia" },
        { nome: "Riachão das Neves" },
        { nome: "Riachão do Jacuípe" },
        { nome: "Riacho de Santana" },
        { nome: "Ribeira do Amparo" },
        { nome: "Ribeira do Pombal" },
        { nome: "Ribeirão do Largo" },
        { nome: "Rio de Contas" },
        { nome: "Rio do Antônio" },
        { nome: "Rio do Pires" },
        { nome: "Rio Real" },
        { nome: "Rodelas" },
        { nome: "Ruy Barbosa" },
        { nome: "Salinas da Margarida" },
        { nome: "Salvador" },
        { nome: "Santa Bárbara" },
        { nome: "Santa Brígida" },
        { nome: "Santa Cruz Cabrália" },
        { nome: "Santa Cruz da Vitória" },
        { nome: "Santa Inês" },
        { nome: "Santa Luzia" },
        { nome: "Santa Maria da Vitória" },
        { nome: "Santa Rita de Cássia" },
        { nome: "Santa Teresinha" },
        { nome: "Santaluz" },
        { nome: "Santana" },
        { nome: "Santanópolis" },
        { nome: "Santo Amaro" },
        { nome: "Santo Antônio de Jesus" },
        { nome: "Santo Estêvão" },
        { nome: "São Desidério" },
        { nome: "São Domingos" },
        { nome: "São Felipe" },
        { nome: "São Félix" },
        { nome: "São Félix do Coribe" },
        { nome: "São Francisco do Conde" },
        { nome: "São Gabriel" },
        { nome: "São Gonçalo dos Campos" },
        { nome: "São José da Vitória" },
        { nome: "São José do Jacuípe" },
        { nome: "São Miguel das Matas" },
        { nome: "São Sebastião do Passé" },
        { nome: "Sapeaçu" },
        { nome: "Sátiro Dias" },
        { nome: "Saubara" },
        { nome: "Saúde" },
        { nome: "Seabra" },
        { nome: "Sebastião Laranjeiras" },
        { nome: "Senhor do Bonfim" },
        { nome: "Sento Sé" },
        { nome: "Serra do Ramalho" },
        { nome: "Serra Dourada" },
        { nome: "Serra Preta" },
        { nome: "Serrinha" },
        { nome: "Serrolândia" },
        { nome: "Simões Filho" },
        { nome: "Sítio do Mato" },
        { nome: "Sítio do Quinto" },
        { nome: "Sobradinho" },
        { nome: "Souto Soares" },
        { nome: "Tabocas do Brejo Velho" },
        { nome: "Tanhaçu" },
        { nome: "Tanque Novo" },
        { nome: "Tanquinho" },
        { nome: "Taperoá" },
        { nome: "Tapiramutá" },
        { nome: "Teixeira de Freitas" },
        { nome: "Teodoro Sampaio" },
        { nome: "Teofilândia" },
        { nome: "Teolândia" },
        { nome: "Terra Nova" },
        { nome: "Tremedal" },
        { nome: "Tucano" },
        { nome: "Uauá" },
        { nome: "Ubaíra" },
        { nome: "Ubaitaba" },
        { nome: "Ubatã" },
        { nome: "Uibaí" },
        { nome: "Umburanas" },
        { nome: "Una" },
        { nome: "Urandi" },
        { nome: "Uruçuca" },
        { nome: "Utinga" },
        { nome: "Valença" },
        { nome: "Valente" },
        { nome: "Várzea da Roça" },
        { nome: "Várzea do Poço" },
        { nome: "Várzea Nova" },
        { nome: "Varzedo" },
        { nome: "Vera Cruz" },
        { nome: "Vereda" },
        { nome: "Vitória da Conquista" },
        { nome: "Wagner" },
        { nome: "Wanderley" },
        { nome: "Wenceslau Guimarães" },
        { nome: "Xique-Xique" }
    ],

    "CE": [
        { nome: "Abaiara" },
        { nome: "Acarapé" },
        { nome: "Acaraú" },
        { nome: "Acopiara" },
        { nome: "Aiuaba" },
        { nome: "Alcântaras" },
        { nome: "Altaneira" },
        { nome: "Alto Santo" },
        { nome: "Amontada" },
        { nome: "Antonina do Norte" },
        { nome: "Apuiarés" },
        { nome: "Aquiraz" },
        { nome: "Aracati" },
        { nome: "Aracoiaba" },
        { nome: "Ararendá" },
        { nome: "Araripe" },
        { nome: "Aratuba" },
        { nome: "Arneiroz" },
        { nome: "Assaré" },
        { nome: "Aurora" },
        { nome: "Baixio" },
        { nome: "Banabuiú" },
        { nome: "Barbalha" },
        { nome: "Barreira" },
        { nome: "Barro" },
        { nome: "Barroquinha" },
        { nome: "Baturité" },
        { nome: "Beberibe" },
        { nome: "Bela Cruz" },
        { nome: "Boa Viagem" },
        { nome: "Brejo Santo" },
        { nome: "Camocim" },
        { nome: "Campos Sales" },
        { nome: "Canindé" },
        { nome: "Capistrano" },
        { nome: "Caridade" },
        { nome: "Cariré" },
        { nome: "Caririaçu" },
        { nome: "Cariús" },
        { nome: "Carnaubal" },
        { nome: "Cascavel" },
        { nome: "Catarina" },
        { nome: "Catunda" },
        { nome: "Caucaia" },
        { nome: "Cedro" },
        { nome: "Chaval" },
        { nome: "Choró" },
        { nome: "Chorozinho" },
        { nome: "Coreaú" },
        { nome: "Crateús" },
        { nome: "Crato" },
        { nome: "Croatá" },
        { nome: "Cruz" },
        { nome: "Deputado Irapuan Pinheiro" },
        { nome: "Ererê" },
        { nome: "Eusébio" },
        { nome: "Farias Brito" },
        { nome: "Forquilha" },
        { nome: "Fortaleza" },
        { nome: "Fortim" },
        { nome: "Frecheirinha" },
        { nome: "General Sampaio" },
        { nome: "Graça" },
        { nome: "Granja" },
        { nome: "Granjeiro" },
        { nome: "Groaíras" },
        { nome: "Guaiúba" },
        { nome: "Guaraciaba do Norte" },
        { nome: "Guaramiranga" },
        { nome: "Hidrolândia" },
        { nome: "Horizonte" },
        { nome: "Ibaretama" },
        { nome: "Ibiapina" },
        { nome: "Ibicuitinga" },
        { nome: "Icapuí" },
        { nome: "Icó" },
        { nome: "Iguatu" },
        { nome: "Independência" },
        { nome: "Ipaporanga" },
        { nome: "Ipaumirim" },
        { nome: "Ipu" },
        { nome: "Ipueiras" },
        { nome: "Iracema" },
        { nome: "Irauçuba" },
        { nome: "Itaiçaba" },
        { nome: "Itaitinga" },
        { nome: "Itapagé" },
        { nome: "Itapipoca" },
        { nome: "Itapiúna" },
        { nome: "Itarema" },
        { nome: "Itatira" },
        { nome: "Jaguaretama" },
        { nome: "Jaguaribara" },
        { nome: "Jaguaribe" },
        { nome: "Jaguaruana" },
        { nome: "Jardim" },
        { nome: "Jati" },
        { nome: "Jijoca de Jericoaroara" },
        { nome: "Juazeiro do Norte" },
        { nome: "Jucás" },
        { nome: "Lavras da Mangabeira" },
        { nome: "Limoeiro do Norte" },
        { nome: "Madalena" },
        { nome: "Maracanaú" },
        { nome: "Maranguape" },
        { nome: "Marco" },
        { nome: "Martinópole" },
        { nome: "Massapê" },
        { nome: "Mauriti" },
        { nome: "Meruoca" },
        { nome: "Milagres" },
        { nome: "Milhã" },
        { nome: "Miraíma" },
        { nome: "Missão Velha" },
        { nome: "Mombaça" },
        { nome: "Monsenhor Tabosa" },
        { nome: "Morada Nova" },
        { nome: "Moraújo" },
        { nome: "Morrinhos" },
        { nome: "Mucambo" },
        { nome: "Mulungu" },
        { nome: "Nova Olinda" },
        { nome: "Nova Russas" },
        { nome: "Novo Oriente" },
        { nome: "Ocara" },
        { nome: "Orós" },
        { nome: "Pacajus" },
        { nome: "Pacatuba" },
        { nome: "Pacoti" },
        { nome: "Pacujá" },
        { nome: "Palhano" },
        { nome: "Palmácia" },
        { nome: "Paracuru" },
        { nome: "Paraipaba" },
        { nome: "Parambu" },
        { nome: "Paramoti" },
        { nome: "Pedra Branca" },
        { nome: "Penaforte" },
        { nome: "Pentecoste" },
        { nome: "Pereiro" },
        { nome: "Pindoretama" },
        { nome: "Piquet Carneiro" },
        { nome: "Pires Ferreira" },
        { nome: "Poranga" },
        { nome: "Porteiras" },
        { nome: "Potengi" },
        { nome: "Potiretama" },
        { nome: "Quiterianópolis" },
        { nome: "Quixadá" },
        { nome: "Quixelô" },
        { nome: "Quixeramobim" },
        { nome: "Quixeré" },
        { nome: "Redenção" },
        { nome: "Reriutaba" },
        { nome: "Russas" },
        { nome: "Saboeiro" },
        { nome: "Salitre" },
        { nome: "Santa Quitéria" },
        { nome: "Santana do Acaraú" },
        { nome: "Santana do Cariri" },
        { nome: "São Benedito" },
        { nome: "São Gonçalo do Amarante" },
        { nome: "São João do Jaguaribe" },
        { nome: "São Luís do Curu" },
        { nome: "Senador Pompeu" },
        { nome: "Senador Sá" },
        { nome: "Sobral" },
        { nome: "Solonópole" },
        { nome: "Tabuleiro do Norte" },
        { nome: "Tamboril" },
        { nome: "Tarrafas" },
        { nome: "Tauá" },
        { nome: "Tejuçuoca" },
        { nome: "Tianguá" },
        { nome: "Trairi" },
        { nome: "Tururu" },
        { nome: "Ubajara" },
        { nome: "Umari" },
        { nome: "Umirim" },
        { nome: "Uruburetama" },
        { nome: "Uruoca" },
        { nome: "Varjota" },
        { nome: "Várzea Alegre" },
        { nome: "Viçosa do Ceará" }
    ],

    "DF": [
        { nome: "Brasília" }
    ],

    "ES": [
        { nome: "Afonso Cláudio" },
        { nome: "Água Doce do Norte" },
        { nome: "Águia Branca" },
        { nome: "Alegre" },
        { nome: "Alfredo Chaves" },
        { nome: "Alto Rio Novo" },
        { nome: "Anchieta" },
        { nome: "Apiacá" },
        { nome: "Aracruz" },
        { nome: "Atilio Vivacqua" },
        { nome: "Baixo Guandu" },
        { nome: "Barra de São Francisco" },
        { nome: "Boa Esperança" },
        { nome: "Bom Jesus do Norte" },
        { nome: "Brejetuba" },
        { nome: "Cachoeiro de Itapemirim" },
        { nome: "Cariacica" },
        { nome: "Castelo" },
        { nome: "Colatina" },
        { nome: "Conceição da Barra" },
        { nome: "Conceição do Castelo" },
        { nome: "Divino de São Lourenço" },
        { nome: "Domingos Martins" },
        { nome: "Dores do Rio Preto" },
        { nome: "Ecoporanga" },
        { nome: "Fundão" },
        { nome: "Governador Lindenberg" },
        { nome: "Guaçuí" },
        { nome: "Guarapari" },
        { nome: "Ibatiba" },
        { nome: "Ibiraçu" },
        { nome: "Ibitirama" },
        { nome: "Iconha" },
        { nome: "Irupi" },
        { nome: "Itaguaçu" },
        { nome: "Itapemirim" },
        { nome: "Itarana" },
        { nome: "Iúna" },
        { nome: "Jaguaré" },
        { nome: "Jerônimo Monteiro" },
        { nome: "João Neiva" },
        { nome: "Laranja da Terra" },
        { nome: "Linhares" },
        { nome: "Mantenópolis" },
        { nome: "Marataizes" },
        { nome: "Marechal Floriano" },
        { nome: "Marilândia" },
        { nome: "Mimoso do Sul" },
        { nome: "Montanha" },
        { nome: "Mucurici" },
        { nome: "Muniz Freire" },
        { nome: "Muqui" },
        { nome: "Nova Venécia" },
        { nome: "Pancas" },
        { nome: "Pedro Canário" },
        { nome: "Pinheiros" },
        { nome: "Piúma" },
        { nome: "Ponto Belo" },
        { nome: "Presidente Kennedy" },
        { nome: "Rio Bananal" },
        { nome: "Rio Novo do Sul" },
        { nome: "Santa Leopoldina" },
        { nome: "Santa Maria de Jetibá" },
        { nome: "Santa Teresa" },
        { nome: "São Domingos do Norte" },
        { nome: "São Gabriel da Palha" },
        { nome: "São José do Calçado" },
        { nome: "São Mateus" },
        { nome: "São Roque do Canaã" },
        { nome: "Serra" },
        { nome: "Sooretama" },
        { nome: "Vargem Alta" },
        { nome: "Venda Nova do Imigrante" },
        { nome: "Viana" },
        { nome: "Vila Pavão" },
        { nome: "Vila Valério" },
        { nome: "Vila Velha" },
        { nome: "Vitória" }
    ],

    "GO": [
        { nome: "Abadia de Goiás" },
        { nome: "Abadiânia" },
        { nome: "Acreúna" },
        { nome: "Adelândia" },
        { nome: "Água Fria de Goiás" },
        { nome: "Água Limpa" },
        { nome: "Águas Lindas de Goiás" },
        { nome: "Alexânia" },
        { nome: "Aloândia" },
        { nome: "Alto Horizonte" },
        { nome: "Alto Paraíso de Goiás" },
        { nome: "Alvorada do Norte" },
        { nome: "Amaralina" },
        { nome: "Americano do Brasil" },
        { nome: "Amorinópolis" },
        { nome: "Anápolis" },
        { nome: "Anhanguera" },
        { nome: "Anicuns" },
        { nome: "Aparecida de Goiânia" },
        { nome: "Aparecida do Rio Doce" },
        { nome: "Aporé" },
        { nome: "Araçu" },
        { nome: "Aragarças" },
        { nome: "Aragoiânia" },
        { nome: "Araguapaz" },
        { nome: "Arenópolis" },
        { nome: "Aruanã" },
        { nome: "Aurilândia" },
        { nome: "Avelinópolis" },
        { nome: "Baliza" },
        { nome: "Barro Alto" },
        { nome: "Bela Vista de Goiás" },
        { nome: "Bom Jardim de Goiás" },
        { nome: "Bom Jesus de Goiás" },
        { nome: "Bonfinópolis" },
        { nome: "Bonópolis" },
        { nome: "Brazabrantes" },
        { nome: "Britânia" },
        { nome: "Buriti Alegre" },
        { nome: "Buriti de Goiás" },
        { nome: "Buritinópolis" },
        { nome: "Cabeceiras" },
        { nome: "Cachoeira Alta" },
        { nome: "Cachoeira de Goiás" },
        { nome: "Cachoeira Dourada" },
        { nome: "Caçu" },
        { nome: "Caiapônia" },
        { nome: "Caldas Novas" },
        { nome: "Caldazinha" },
        { nome: "Campestre de Goiás" },
        { nome: "Campinaçu" },
        { nome: "Campinorte" },
        { nome: "Campo Alegre de Goiás" },
        { nome: "Campos Limpo de Goiás" },
        { nome: "Campos Belos" },
        { nome: "Campos Verdes" },
        { nome: "Carmo do Rio Verde" },
        { nome: "Castelândia" },
        { nome: "Catalão" },
        { nome: "Caturaí" },
        { nome: "Cavalcante" },
        { nome: "Ceres" },
        { nome: "Cezarina" },
        { nome: "Chapadão do Céu" },
        { nome: "Cidade Ocidental" },
        { nome: "Cocalzinho de Goiás" },
        { nome: "Colinas do Sul" },
        { nome: "Córrego do Ouro" },
        { nome: "Corumbá de Goiás" },
        { nome: "Corumbaíba" },
        { nome: "Cristalina" },
        { nome: "Cristianópolis" },
        { nome: "Crixás" },
        { nome: "Cromínia" },
        { nome: "Cumari" },
        { nome: "Damianópolis" },
        { nome: "Damolândia" },
        { nome: "Davinópolis" },
        { nome: "Diorama" },
        { nome: "Divinópolis de Goiás" },
        { nome: "Doverlândia" },
        { nome: "Edealina" },
        { nome: "Edéia" },
        { nome: "Estrela do Norte" },
        { nome: "Faina" },
        { nome: "Fazenda Nova" },
        { nome: "Firminópolis" },
        { nome: "Flores de Goiás" },
        { nome: "Formosa" },
        { nome: "Formoso" },
        { nome: "Gameleira de Goiás" },
        { nome: "Goianápolis" },
        { nome: "Goiandira" },
        { nome: "Goianésia" },
        { nome: "Goiânia" },
        { nome: "Goianira" },
        { nome: "Goiás" },
        { nome: "Goiatuba" },
        { nome: "Gouvelândia" },
        { nome: "Guapó" },
        { nome: "Guaraíta" },
        { nome: "Guarani de Goiás" },
        { nome: "Guarinos" },
        { nome: "Heitoraí" },
        { nome: "Hidrolândia" },
        { nome: "Hidrolina" },
        { nome: "Iaciara" },
        { nome: "Inaciolândia" },
        { nome: "Indiara" },
        { nome: "Inhumas" },
        { nome: "Ipameri" },
        { nome: "Ipiranga de Goiás" },
        { nome: "Iporá" },
        { nome: "Israelândia" },
        { nome: "Itaberaí" },
        { nome: "Itaguari" },
        { nome: "Itaguaru" },
        { nome: "Itajá" },
        { nome: "Itapaci" },
        { nome: "Itapirapuã" },
        { nome: "Itapuranga" },
        { nome: "Itarumã" },
        { nome: "Itauçu" },
        { nome: "Itumbiara" },
        { nome: "Ivolândia" },
        { nome: "Jandaia" },
        { nome: "Jaraguá" },
        { nome: "Jataí" },
        { nome: "Jaupaci" },
        { nome: "Jesúpolis" },
        { nome: "Joviânia" },
        { nome: "Jussara" },
        { nome: "Lagoa Santa" },
        { nome: "Leopoldo de Bulhões" },
        { nome: "Luziânia" },
        { nome: "Mairipotaba" },
        { nome: "Mambaí" },
        { nome: "Mara Rosa" },
        { nome: "Marzagão" },
        { nome: "Matrinchã" },
        { nome: "Maurilândia" },
        { nome: "Mimoso de Goiás" },
        { nome: "Minaçu" },
        { nome: "Mineiros" },
        { nome: "Moiporá" },
        { nome: "Monte Alegre de Goiás" },
        { nome: "Montes Claros de Goiás" },
        { nome: "Montividiu" },
        { nome: "Montividiu do Norte" },
        { nome: "Morrinhos" },
        { nome: "Morro Agudo de Goiás" },
        { nome: "Mossâmedes" },
        { nome: "Mozarlândia" },
        { nome: "Mundo Novo" },
        { nome: "Mutunópolis" },
        { nome: "Nazário" },
        { nome: "Nerópolis" },
        { nome: "Niquelândia" },
        { nome: "Nova América" },
        { nome: "Nova Aurora" },
        { nome: "Nova Crixás" },
        { nome: "Nova Glória" },
        { nome: "Nova Iguaçu de Goiás" },
        { nome: "Nova Roma" },
        { nome: "Nova Veneza" },
        { nome: "Novo Brasil" },
        { nome: "Novo Gama" },
        { nome: "Novo Planalto" },
        { nome: "Orizona" },
        { nome: "Ouro Verde de Goiás" },
        { nome: "Ouvidor" },
        { nome: "Padre Bernardo" },
        { nome: "Palestina de Goiás" },
        { nome: "Palmeiras de Goiás" },
        { nome: "Palmelo" },
        { nome: "Palminópolis" },
        { nome: "Panamá" },
        { nome: "Paranaiguara" },
        { nome: "Paraúna" },
        { nome: "Perolândia" },
        { nome: "Petrolina de Goiás" },
        { nome: "Pilar de Goiás" },
        { nome: "Piracanjuba" },
        { nome: "Piranhas" },
        { nome: "Pirenópolis" },
        { nome: "Pires do Rio" },
        { nome: "Planaltina" },
        { nome: "Pontalina" },
        { nome: "Porangatu" },
        { nome: "Porteirão" },
        { nome: "Portelândia" },
        { nome: "Posse" },
        { nome: "Professor Jamil" },
        { nome: "Quirinópolis" },
        { nome: "Rialma" },
        { nome: "Rianápolis" },
        { nome: "Rio Quente" },
        { nome: "Rio Verde" },
        { nome: "Rubiataba" },
        { nome: "Sanclerlândia" },
        { nome: "Santa Bárbara de Goiás" },
        { nome: "Santa Cruz de Goiás" },
        { nome: "Santa Fé de Goiás" },
        { nome: "Santa Helena de Goiás" },
        { nome: "Santa Isabel" },
        { nome: "Santa Rita do Araguaia" },
        { nome: "Santa Rita do Novo Destino" },
        { nome: "Santa Rosa de Goiás" },
        { nome: "Santa Tereza de Goiás" },
        { nome: "Santa Terezinha de Goiás" },
        { nome: "Santo Antônio da Barra" },
        { nome: "Santo Antônio de Goiás" },
        { nome: "Santo Antônio do Descoberto" },
        { nome: "São Domingos" },
        { nome: "São Francisco de Goiás" },
        { nome: "São João d'Aliança" },
        { nome: "São João da Paraúna" },
        { nome: "São Luís de Montes Belos" },
        { nome: "São Luíz do Norte" },
        { nome: "São Miguel do Araguaia" },
        { nome: "São Miguel do Passa Quatro" },
        { nome: "São Patrício" },
        { nome: "São Simão" },
        { nome: "Senador Canedo" },
        { nome: "Serranópolis" },
        { nome: "Silvânia" },
        { nome: "Simolândia" },
        { nome: "Sítio d'Abadia" },
        { nome: "Taquaral de Goiás" },
        { nome: "Teresina de Goiás" },
        { nome: "Terezópolis de Goiás" },
        { nome: "Três Ranchos" },
        { nome: "Trindade" },
        { nome: "Trombas" },
        { nome: "Turvânia" },
        { nome: "Turvelândia" },
        { nome: "Uirapuru" },
        { nome: "Uruaçu" },
        { nome: "Uruana" },
        { nome: "Urutaí" },
        { nome: "Valparaíso de Goiás" },
        { nome: "Varjão" },
        { nome: "Vianópolis" },
        { nome: "Vicentinópolis" },
        { nome: "Vila Boa" },
        { nome: "Vila Propício" }
    ],

    "MA": [
        { nome: "Açailândia" },
        { nome: "Afonso Cunha" },
        { nome: "Água Doce do Maranhão" },
        { nome: "Alcântara" },
        { nome: "Aldeias Altas" },
        { nome: "Altamira do Maranhão" },
        { nome: "Alto Alegre do Maranhão" },
        { nome: "Alto Alegre do Pindaré" },
        { nome: "Alto Parnaíba" },
        { nome: "Amapá do Maranhão" },
        { nome: "Amarante do Maranhão" },
        { nome: "Anajatuba" },
        { nome: "Anapurus" },
        { nome: "Apicum-Açu" },
        { nome: "Araguanã" },
        { nome: "Araioses" },
        { nome: "Arame" },
        { nome: "Arari" },
        { nome: "Axixá" },
        { nome: "Bacabal" },
        { nome: "Bacabeira" },
        { nome: "Bacuri" },
        { nome: "Bacurituba" },
        { nome: "Balsas" },
        { nome: "Barão de Grajaú" },
        { nome: "Barra do Corda" },
        { nome: "Barreirinhas" },
        { nome: "Bela Vista do Maranhão" },
        { nome: "Belágua" },
        { nome: "Benedito Leite" },
        { nome: "Bequimão" },
        { nome: "Bernardo do Mearim" },
        { nome: "Boa Vista do Gurupi" },
        { nome: "Bom Jardim" },
        { nome: "Bom Jesus das Selvas" },
        { nome: "Bom Lugar" },
        { nome: "Brejo" },
        { nome: "Brejo de Areia" },
        { nome: "Buriti" },
        { nome: "Buriti Bravo" },
        { nome: "Buriticupu" },
        { nome: "Buritirana" },
        { nome: "Cachoeira Grande" },
        { nome: "Cajapió" },
        { nome: "Cajari" },
        { nome: "Campestre do Maranhão" },
        { nome: "Cândido Mendes" },
        { nome: "Cantanhede" },
        { nome: "Capinzal do Norte" },
        { nome: "Carolina" },
        { nome: "Carutapera" },
        { nome: "Caxias" },
        { nome: "Cedral" },
        { nome: "Central do Maranhão" },
        { nome: "Centro do Guilherme" },
        { nome: "Centro Novo do Maranhão" },
        { nome: "Chapadinha" },
        { nome: "Cidelândia" },
        { nome: "Codó" },
        { nome: "Coelho Neto" },
        { nome: "Colinas" },
        { nome: "Conceição do Lago-Açu" },
        { nome: "Coroatá" },
        { nome: "Cururupu" },
        { nome: "Davinópolis" },
        { nome: "Dom Pedro" },
        { nome: "Duque Bacelar" },
        { nome: "Esperantinópolis" },
        { nome: "Estreito" },
        { nome: "Feira Nova do Maranhão" },
        { nome: "Fernando Falcão" },
        { nome: "Formosa da Serra Negra" },
        { nome: "Fortaleza dos Nogueiras" },
        { nome: "Fortuna" },
        { nome: "Godofredo Viana" },
        { nome: "Gonçalves Dias" },
        { nome: "Governador Archer" },
        { nome: "Governador Edison Lobão" },
        { nome: "Governador Eugênio Barros" },
        { nome: "Governador Luiz Rocha" },
        { nome: "Governador Newton Bello" },
        { nome: "Governador Nunes Freire" },
        { nome: "Graça Aranha" },
        { nome: "Grajaú" },
        { nome: "Guimarães" },
        { nome: "Humberto de Campos" },
        { nome: "Icatu" },
        { nome: "Igarapé do Meio" },
        { nome: "Igarapé Grande" },
        { nome: "Imperatriz" },
        { nome: "Itaipava do Grajaú" },
        { nome: "Itapecuru Mirim" },
        { nome: "Itinga do Maranhão" },
        { nome: "Jatobá" },
        { nome: "Jenipapo dos Vieiras" },
        { nome: "João Lisboa" },
        { nome: "Joselândia" },
        { nome: "Junco do Maranhão" },
        { nome: "Lago da Pedra" },
        { nome: "Lago do Junco" },
        { nome: "Lago dos Rodrigues" },
        { nome: "Lago Verde" },
        { nome: "Lagoa do Mato" },
        { nome: "Lagoa Grande do Maranhão" },
        { nome: "Lajeado Novo" },
        { nome: "Lima Campos" },
        { nome: "Loreto" },
        { nome: "Luís Domingues" },
        { nome: "Magalhães de Almeida" },
        { nome: "Maracaçumé" },
        { nome: "Marajá do Sena" },
        { nome: "Maranhãozinho" },
        { nome: "Mata Roma" },
        { nome: "Matinha" },
        { nome: "Matões" },
        { nome: "Matões do Norte" },
        { nome: "Milagres do Maranhão" },
        { nome: "Mirador" },
        { nome: "Miranda do Norte" },
        { nome: "Mirinzal" },
        { nome: "Monção" },
        { nome: "Montes Altos" },
        { nome: "Morros" },
        { nome: "Nina Rodrigues" },
        { nome: "Nova Colinas" },
        { nome: "Nova Iorque" },
        { nome: "Nova Olinda do Maranhão" },
        { nome: "Olho d'Água das Cunhãs" },
        { nome: "Olinda Nova do Maranhão" },
        { nome: "Paço do Lumiar" },
        { nome: "Palmeirândia" },
        { nome: "Paraibano" },
        { nome: "Parnarama" },
        { nome: "Passagem Franca" },
        { nome: "Pastos Bons" },
        { nome: "Paulino Neves" },
        { nome: "Paulo Ramos" },
        { nome: "Pedreiras" },
        { nome: "Pedro do Rosário" },
        { nome: "Penalva" },
        { nome: "Peri Mirim" },
        { nome: "Peritoró" },
        { nome: "Pindaré Mirim" },
        { nome: "Pinheiro" },
        { nome: "Pio XII" },
        { nome: "Pirapemas" },
        { nome: "Poção de Pedras" },
        { nome: "Porto Franco" },
        { nome: "Porto Rico do Maranhão" },
        { nome: "Presidente Dutra" },
        { nome: "Presidente Juscelino" },
        { nome: "Presidente Médici" },
        { nome: "Presidente Sarney" },
        { nome: "Presidente Vargas" },
        { nome: "Primeira Cruz" },
        { nome: "Raposa" },
        { nome: "Riachão" },
        { nome: "Ribamar Fiquene" },
        { nome: "Rosário" },
        { nome: "Sambaíba" },
        { nome: "Santa Filomena do Maranhão" },
        { nome: "Santa Helena" },
        { nome: "Santa Inês" },
        { nome: "Santa Luzia" },
        { nome: "Santa Luzia do Paruá" },
        { nome: "Santa Quitéria do Maranhão" },
        { nome: "Santa Rita" },
        { nome: "Santana do Maranhão" },
        { nome: "Santo Amaro do Maranhão" },
        { nome: "Santo Antônio dos Lopes" },
        { nome: "São Benedito do Rio Preto" },
        { nome: "São Bento" },
        { nome: "São Bernardo" },
        { nome: "São Domingos do Azeitão" },
        { nome: "São Domingos do Maranhão" },
        { nome: "São Félix de Balsas" },
        { nome: "São Francisco do Brejão" },
        { nome: "São Francisco do Maranhão" },
        { nome: "São João Batista" },
        { nome: "São João do Carú" },
        { nome: "São João do Paraíso" },
        { nome: "São João do Soter" },
        { nome: "São João dos Patos" },
        { nome: "São José de Ribamar" },
        { nome: "São José dos Basílios" },
        { nome: "São Luís" },
        { nome: "São Luís Gonzaga do Maranhão" },
        { nome: "São Mateus do Maranhão" },
        { nome: "São Pedro da Água Branca" },
        { nome: "São Pedro dos Crentes" },
        { nome: "São Raimundo das Mangabeiras" },
        { nome: "São Raimundo do Doca Bezerra" },
        { nome: "São Roberto" },
        { nome: "São Vicente Ferrer" },
        { nome: "Satubinha" },
        { nome: "Senador Alexandre Costa" },
        { nome: "Senador La Rocque" },
        { nome: "Serrano do Maranhão" },
        { nome: "Sítio Novo" },
        { nome: "Sucupira do Norte" },
        { nome: "Sucupira do Riachão" },
        { nome: "Tasso Fragoso" },
        { nome: "Timbiras" },
        { nome: "Timon" },
        { nome: "Trizidela do Vale" },
        { nome: "Tufilândia" },
        { nome: "Tuntum" },
        { nome: "Turiaçu" },
        { nome: "Turilândia" },
        { nome: "Tutóia" },
        { nome: "Urbano Santos" },
        { nome: "Vargem Grande" },
        { nome: "Viana" },
        { nome: "Vila Nova dos Martírios" },
        { nome: "Vitória do Mearim" },
        { nome: "Vitorino Freire" },
        { nome: "Zé Doca" }
    ],

    "MG": [
        { nome: "Abadia dos Dourados" },
        { nome: "Abaeté" },
        { nome: "Abre Campo" },
        { nome: "Acaiaca" },
        { nome: "Açucena" },
        { nome: "Água Boa" },
        { nome: "Água Comprida" },
        { nome: "Aguanil" },
        { nome: "Águas Formosas" },
        { nome: "Águas Vermelhas" },
        { nome: "Aimorés" },
        { nome: "Aiuruoca" },
        { nome: "Alagoa" },
        { nome: "Albertina" },
        { nome: "Além Paraíba" },
        { nome: "Alfenas" },
        { nome: "Alfredo Vasconcelos" },
        { nome: "Almenara" },
        { nome: "Alpercata" },
        { nome: "Alpinópolis" },
        { nome: "Alterosa" },
        { nome: "Alto Caparaó" },
        { nome: "Alto Jequitibá" },
        { nome: "Alto Rio Doce" },
        { nome: "Alvarenga" },
        { nome: "Alvinópolis" },
        { nome: "Alvorada de Minas" },
        { nome: "Amparo do Serra" },
        { nome: "Andradas" },
        { nome: "Andrelândia" },
        { nome: "Angelândia" },
        { nome: "Antônio Carlos" },
        { nome: "Antônio Dias" },
        { nome: "Antônio Prado de Minas" },
        { nome: "Araçaí" },
        { nome: "Aracitaba" },
        { nome: "Araçuaí" },
        { nome: "Araguari" },
        { nome: "Arantina" },
        { nome: "Araponga" },
        { nome: "Araporã" },
        { nome: "Arapuá" },
        { nome: "Araújos" },
        { nome: "Araxá" },
        { nome: "Arceburgo" },
        { nome: "Arcos" },
        { nome: "Areado" },
        { nome: "Argirita" },
        { nome: "Aricanduva" },
        { nome: "Arinos" },
        { nome: "Astolfo Dutra" },
        { nome: "Ataléia" },
        { nome: "Augusto de Lima" },
        { nome: "Baependi" },
        { nome: "Baldim" },
        { nome: "Bambuí" },
        { nome: "Bandeira" },
        { nome: "Bandeira do Sul" },
        { nome: "Barão de Cocais" },
        { nome: "Barão de Monte Alto" },
        { nome: "Barbacena" },
        { nome: "Barra Longa" },
        { nome: "Barroso" },
        { nome: "Bela Vista de Minas" },
        { nome: "Belmiro Braga" },
        { nome: "Belo Horizonte" },
        { nome: "Belo Oriente" },
        { nome: "Belo Vale" },
        { nome: "Berilo" },
        { nome: "Berizal" },
        { nome: "Bertópolis" },
        { nome: "Betim" },
        { nome: "Bias Fortes" },
        { nome: "Bicas" },
        { nome: "Biquinhas" },
        { nome: "Boa Esperança" },
        { nome: "Bocaina de Minas" },
        { nome: "Bocaiúva" },
        { nome: "Bom Despacho" },
        { nome: "Bom Jardim de Minas" },
        { nome: "Bom Jesus da Penha" },
        { nome: "Bom Jesus do Amparo" },
        { nome: "Bom Jesus do Galho" },
        { nome: "Bom Repouso" },
        { nome: "Bom Sucesso" },
        { nome: "Bonfim" },
        { nome: "Bonfinópolis de Minas" },
        { nome: "Bonito de Minas" },
        { nome: "Borda da Mata" },
        { nome: "Botelhos" },
        { nome: "Botumirim" },
        { nome: "Brás Pires" },
        { nome: "Brasilândia de Minas" },
        { nome: "Brasília de Minas" },
        { nome: "Brasópolis" },
        { nome: "Braúnas" },
        { nome: "Brumadinho" },
        { nome: "Bueno Brandão" },
        { nome: "Buenópolis" },
        { nome: "Bugre" },
        { nome: "Buritis" },
        { nome: "Buritizeiro" },
        { nome: "Cabeceira Grande" },
        { nome: "Cabo Verde" },
        { nome: "Cachoeira da Prata" },
        { nome: "Cachoeira de Minas" },
        { nome: "Cachoeira de Pajeú" },
        { nome: "Cachoeira Dourada" },
        { nome: "Caetanópolis" },
        { nome: "Caeté" },
        { nome: "Caiana" },
        { nome: "Cajuri" },
        { nome: "Caldas" },
        { nome: "Camacho" },
        { nome: "Camanducaia" },
        { nome: "Cambuí" },
        { nome: "Cambuquira" },
        { nome: "Campanário" },
        { nome: "Campanha" },
        { nome: "Campestre" },
        { nome: "Campina Verde" },
        { nome: "Campo Azul" },
        { nome: "Campo Belo" },
        { nome: "Campo do Meio" },
        { nome: "Campo Florido" },
        { nome: "Campos Altos" },
        { nome: "Campos Gerais" },
        { nome: "Cana Verde" },
        { nome: "Canaã" },
        { nome: "Canápolis" },
        { nome: "Candeias" },
        { nome: "Cantagalo" },
        { nome: "Caparaó" },
        { nome: "Capela Nova" },
        { nome: "Capelinha" },
        { nome: "Capetinga" },
        { nome: "Capim Branco" },
        { nome: "Capinópolis" },
        { nome: "Capitão Andrade" },
        { nome: "Capitão Enéas" },
        { nome: "Capitólio" },
        { nome: "Caputira" },
        { nome: "Caraí" },
        { nome: "Caranaíba" },
        { nome: "Carandaí" },
        { nome: "Carangola" },
        { nome: "Caratinga" },
        { nome: "Carbonita" },
        { nome: "Careaçu" },
        { nome: "Carlos Chagas" },
        { nome: "Carmésia" },
        { nome: "Carmo da Cachoeira" },
        { nome: "Carmo da Mata" },
        { nome: "Carmo de Minas" },
        { nome: "Carmo do Cajuru" },
        { nome: "Carmo do Paranaíba" },
        { nome: "Carmo do Rio Claro" },
        { nome: "Carmópolis de Minas" },
        { nome: "Carneirinho" },
        { nome: "Carrancas" },
        { nome: "Carvalhópolis" },
        { nome: "Carvalhos" },
        { nome: "Casa Grande" },
        { nome: "Cascalho Rico" },
        { nome: "Cássia" },
        { nome: "Cataguases" },
        { nome: "Catas Altas" },
        { nome: "Catas Altas da Noruega" },
        { nome: "Catuji" },
        { nome: "Catuti" },
        { nome: "Caxambu" },
        { nome: "Cedro do Abaeté" },
        { nome: "Central de Minas" },
        { nome: "Centralina" },
        { nome: "Chácara" },
        { nome: "Chalé" },
        { nome: "Chapada do Norte" },
        { nome: "Chapada Gaúcha" },
        { nome: "Chiador" },
        { nome: "Cipotânea" },
        { nome: "Claraval" },
        { nome: "Claro dos Poções" },
        { nome: "Cláudio" },
        { nome: "Coimbra" },
        { nome: "Coluna" },
        { nome: "Comendador Gomes" },
        { nome: "Comercinho" },
        { nome: "Conceição da Aparecida" },
        { nome: "Conceição da Barra de Minas" },
        { nome: "Conceição das Alagoas" },
        { nome: "Conceição das Pedras" },
        { nome: "Conceição de Ipanema" },
        { nome: "Conceição do Mato Dentro" },
        { nome: "Conceição do Pará" },
        { nome: "Conceição do Rio Verde" },
        { nome: "Conceição dos Ouros" },
        { nome: "Cônego Marinho" },
        { nome: "Confins" },
        { nome: "Congonhal" },
        { nome: "Congonhas" },
        { nome: "Congonhas do Norte" },
        { nome: "Conquista" },
        { nome: "Conselheiro Lafaiete" },
        { nome: "Conselheiro Pena" },
        { nome: "Consolação" },
        { nome: "Contagem" },
        { nome: "Coqueiral" },
        { nome: "Coração de Jesus" },
        { nome: "Cordisburgo" },
        { nome: "Cordislândia" },
        { nome: "Corinto" },
        { nome: "Coroaci" },
        { nome: "Coromandel" },
        { nome: "Coronel Fabriciano" },
        { nome: "Coronel Murta" },
        { nome: "Coronel Pacheco" },
        { nome: "Coronel Xavier Chaves" },
        { nome: "Córrego Danta" },
        { nome: "Córrego do Bom Jesus" },
        { nome: "Córrego Fundo" },
        { nome: "Córrego Novo" },
        { nome: "Couto de Magalhães de Minas" },
        { nome: "Crisólita" },
        { nome: "Cristais" },
        { nome: "Cristália" },
        { nome: "Cristiano Otoni" },
        { nome: "Cristina" },
        { nome: "Crucilândia" },
        { nome: "Cruzeiro da Fortaleza" },
        { nome: "Cruzília" },
        { nome: "Cuparaque" },
        { nome: "Curral de Dentro" },
        { nome: "Curvelo" },
        { nome: "Datas" },
        { nome: "Delfim Moreira" },
        { nome: "Delfinópolis" },
        { nome: "Delta" },
        { nome: "Descoberto" },
        { nome: "Desterro de Entre Rios" },
        { nome: "Desterro do Melo" },
        { nome: "Diamantina" },
        { nome: "Diogo de Vasconcelos" },
        { nome: "Dionísio" },
        { nome: "Divinésia" },
        { nome: "Divino" },
        { nome: "Divino das Laranjeiras" },
        { nome: "Divinolândia de Minas" },
        { nome: "Divinópolis" },
        { nome: "Divisa Alegre" },
        { nome: "Divisa Nova" },
        { nome: "Divisópolis" },
        { nome: "Dom Bosco" },
        { nome: "Dom Cavati" },
        { nome: "Dom Joaquim" },
        { nome: "Dom Silvério" },
        { nome: "Dom Viçoso" },
        { nome: "Dona Euzébia" },
        { nome: "Dores de Campos" },
        { nome: "Dores de Guanhães" },
        { nome: "Dores do Indaiá" },
        { nome: "Dores do Turvo" },
        { nome: "Doresópolis" },
        { nome: "Douradoquara" },
        { nome: "Durandé" },
        { nome: "Elói Mendes" },
        { nome: "Engenheiro Caldas" },
        { nome: "Engenheiro Navarro" },
        { nome: "Entre Folhas" },
        { nome: "Entre Rios de Minas" },
        { nome: "Ervália" },
        { nome: "Esmeraldas" },
        { nome: "Espera Feliz" },
        { nome: "Espinosa" },
        { nome: "Espírito Santo do Dourado" },
        { nome: "Estiva" },
        { nome: "Estrela Dalva" },
        { nome: "Estrela do Indaiá" },
        { nome: "Estrela do Sul" },
        { nome: "Eugenópolis" },
        { nome: "Ewbank da Câmara" },
        { nome: "Extrema" },
        { nome: "Fama" },
        { nome: "Faria Lemos" },
        { nome: "Felício dos Santos" },
        { nome: "Felisburgo" },
        { nome: "Felixlândia" },
        { nome: "Fernandes Tourinho" },
        { nome: "Ferros" },
        { nome: "Fervedouro" },
        { nome: "Florestal" },
        { nome: "Formiga" },
        { nome: "Formoso" },
        { nome: "Fortaleza de Minas" },
        { nome: "Fortuna de Minas" },
        { nome: "Francisco Badaró" },
        { nome: "Francisco Dumont" },
        { nome: "Francisco Sá" },
        { nome: "Franciscópolis" },
        { nome: "Frei Gaspar" },
        { nome: "Frei Inocêncio" },
        { nome: "Frei Lagonegro" },
        { nome: "Fronteira" },
        { nome: "Fronteira dos Vales" },
        { nome: "Fruta de Leite" },
        { nome: "Frutal" },
        { nome: "Funilândia" },
        { nome: "Galiléia" },
        { nome: "Gameleiras" },
        { nome: "Glaucilândia" },
        { nome: "Goiabeira" },
        { nome: "Goianá" },
        { nome: "Gonçalves" },
        { nome: "Gonzaga" },
        { nome: "Gouveia" },
        { nome: "Governador Valadares" },
        { nome: "Grão Mogol" },
        { nome: "Grupiara" },
        { nome: "Guanhães" },
        { nome: "Guapé" },
        { nome: "Guaraciaba" },
        { nome: "Guaraciama" },
        { nome: "Guaranésia" },
        { nome: "Guarani" },
        { nome: "Guarará" },
        { nome: "Guarda-Mor" },
        { nome: "Guaxupé" },
        { nome: "Guidoval" },
        { nome: "Guimarânia" },
        { nome: "Guiricema" },
        { nome: "Gurinhatã" },
        { nome: "Heliodora" },
        { nome: "Iapu" },
        { nome: "Ibertioga" },
        { nome: "Ibiá" },
        { nome: "Ibiaí" },
        { nome: "Ibiracatu" },
        { nome: "Ibiraci" },
        { nome: "Ibirité" },
        { nome: "Ibitiúra de Minas" },
        { nome: "Ibituruna" },
        { nome: "Icaraí de Minas" },
        { nome: "Igarapé" },
        { nome: "Igaratinga" },
        { nome: "Iguatama" },
        { nome: "Ijaci" },
        { nome: "Ilicínea" },
        { nome: "Imbé de Minas" },
        { nome: "Inconfidentes" },
        { nome: "Indaiabira" },
        { nome: "Indianópolis" },
        { nome: "Ingaí" },
        { nome: "Inhapim" },
        { nome: "Inhaúma" },
        { nome: "Inimutaba" },
        { nome: "Ipaba" },
        { nome: "Ipanema" },
        { nome: "Ipatinga" },
        { nome: "Ipiaçu" },
        { nome: "Ipuiúna" },
        { nome: "Iraí de Minas" },
        { nome: "Itabira" },
        { nome: "Itabirinha de Mantena" },
        { nome: "Itabirito" },
        { nome: "Itacambira" },
        { nome: "Itacarambi" },
        { nome: "Itaguara" },
        { nome: "Itaipé" },
        { nome: "Itajubá" },
        { nome: "Itamarandiba" },
        { nome: "Itamarati de Minas" },
        { nome: "Itambacuri" },
        { nome: "Itambé do Mato Dentro" },
        { nome: "Itamogi" },
        { nome: "Itamonte" },
        { nome: "Itanhandu" },
        { nome: "Itanhomi" },
        { nome: "Itaobim" },
        { nome: "Itapagipe" },
        { nome: "Itapecerica" },
        { nome: "Itapeva" },
        { nome: "Itatiaiuçu" },
        { nome: "Itaú de Minas" },
        { nome: "Itaúna" },
        { nome: "Itaverava" },
        { nome: "Itinga" },
        { nome: "Itueta" },
        { nome: "Ituiutaba" },
        { nome: "Itumirim" },
        { nome: "Iturama" },
        { nome: "Itutinga" },
        { nome: "Jaboticatubas" },
        { nome: "Jacinto" },
        { nome: "Jacuí" },
        { nome: "Jacutinga" },
        { nome: "Jaguaraçu" },
        { nome: "Jaíba" },
        { nome: "Jampruca" },
        { nome: "Janaúba" },
        { nome: "Januária" },
        { nome: "Japaraíba" },
        { nome: "Japonvar" },
        { nome: "Jeceaba" },
        { nome: "Jenipapo de Minas" },
        { nome: "Jequeri" },
        { nome: "Jequitaí" },
        { nome: "Jequitibá" },
        { nome: "Jequitinhonha" },
        { nome: "Jesuânia" },
        { nome: "Joaíma" },
        { nome: "Joanésia" },
        { nome: "João Monlevade" },
        { nome: "João Pinheiro" },
        { nome: "Joaquim Felício" },
        { nome: "Jordânia" },
        { nome: "José Gonçalves de Minas" },
        { nome: "José Raydan" },
        { nome: "Josenópolis" },
        { nome: "Juatuba" },
        { nome: "Juiz de Fora" },
        { nome: "Juramento" },
        { nome: "Juruaia" },
        { nome: "Juvenília" },
        { nome: "Ladainha" },
        { nome: "Lagamar" },
        { nome: "Lagoa da Prata" },
        { nome: "Lagoa dos Patos" },
        { nome: "Lagoa Dourada" },
        { nome: "Lagoa Formosa" },
        { nome: "Lagoa Grande" },
        { nome: "Lagoa Santa" },
        { nome: "Lajinha" },
        { nome: "Lambari" },
        { nome: "Lamim" },
        { nome: "Laranjal" },
        { nome: "Lassance" },
        { nome: "Lavras" },
        { nome: "Leandro Ferreira" },
        { nome: "Leme do Prado" },
        { nome: "Leopoldina" },
        { nome: "Liberdade" },
        { nome: "Lima Duarte" },
        { nome: "Limeira do Oeste" },
        { nome: "Lontra" },
        { nome: "Luisburgo" },
        { nome: "Luislândia" },
        { nome: "Luminárias" },
        { nome: "Luz" },
        { nome: "Machacalis" },
        { nome: "Machado" },
        { nome: "Madre de Deus de Minas" },
        { nome: "Malacacheta" },
        { nome: "Mamonas" },
        { nome: "Manga" },
        { nome: "Manhuaçu" },
        { nome: "Manhumirim" },
        { nome: "Mantena" },
        { nome: "Mar de Espanha" },
        { nome: "Maravilhas" },
        { nome: "Maria da Fé" },
        { nome: "Mariana" },
        { nome: "Marilac" },
        { nome: "Mário Campos" },
        { nome: "Maripá de Minas" },
        { nome: "Marliéria" },
        { nome: "Marmelópolis" },
        { nome: "Martinho Campos" },
        { nome: "Martins Soares" },
        { nome: "Mata Verde" },
        { nome: "Materlândia" },
        { nome: "Mateus Leme" },
        { nome: "Mathias Lobato" },
        { nome: "Matias Barbosa" },
        { nome: "Matias Cardoso" },
        { nome: "Matipó" },
        { nome: "Mato Verde" },
        { nome: "Matozinhos" },
        { nome: "Matutina" },
        { nome: "Medeiros" },
        { nome: "Medina" },
        { nome: "Mendes Pimentel" },
        { nome: "Mercês" },
        { nome: "Mesquita" },
        { nome: "Minas Novas" },
        { nome: "Minduri" },
        { nome: "Mirabela" },
        { nome: "Miradouro" },
        { nome: "Miraí" },
        { nome: "Miravânia" },
        { nome: "Moeda" },
        { nome: "Moema" },
        { nome: "Monjolos" },
        { nome: "Monsenhor Paulo" },
        { nome: "Montalvânia" },
        { nome: "Monte Alegre de Minas" },
        { nome: "Monte Azul" },
        { nome: "Monte Belo" },
        { nome: "Monte Carmelo" },
        { nome: "Monte Formoso" },
        { nome: "Monte Santo de Minas" },
        { nome: "Monte Sião" },
        { nome: "Montes Claros" },
        { nome: "Montezuma" },
        { nome: "Morada Nova de Minas" },
        { nome: "Morro da Garça" },
        { nome: "Morro do Pilar" },
        { nome: "Munhoz" },
        { nome: "Muriaé" },
        { nome: "Mutum" },
        { nome: "Muzambinho" },
        { nome: "Nacip Raydan" },
        { nome: "Nanuque" },
        { nome: "Naque" },
        { nome: "Natalândia" },
        { nome: "Natércia" },
        { nome: "Nazareno" },
        { nome: "Nepomuceno" },
        { nome: "Ninheira" },
        { nome: "Nova Belém" },
        { nome: "Nova Era" },
        { nome: "Nova Lima" },
        { nome: "Nova Módica" },
        { nome: "Nova Ponte" },
        { nome: "Nova Porteirinha" },
        { nome: "Nova Resende" },
        { nome: "Nova Serrana" },
        { nome: "Nova União" },
        { nome: "Novo Cruzeiro" },
        { nome: "Novo Oriente de Minas" },
        { nome: "Novorizonte" },
        { nome: "Olaria" },
        { nome: "Olhos-d'Água" },
        { nome: "Olímpio Noronha" },
        { nome: "Oliveira" },
        { nome: "Oliveira Fortes" },
        { nome: "Onça de Pitangui" },
        { nome: "Oratórios" },
        { nome: "Orizânia" },
        { nome: "Ouro Branco" },
        { nome: "Ouro Fino" },
        { nome: "Ouro Preto" },
        { nome: "Ouro Verde de Minas" },
        { nome: "Padre Carvalho" },
        { nome: "Padre Paraíso" },
        { nome: "Pai Pedro" },
        { nome: "Paineiras" },
        { nome: "Pains" },
        { nome: "Paiva" },
        { nome: "Palma" },
        { nome: "Palmópolis" },
        { nome: "Papagaios" },
        { nome: "Pará de Minas" },
        { nome: "Paracatu" },
        { nome: "Paraguaçu" },
        { nome: "Paraisópolis" },
        { nome: "Paraopeba" },
        { nome: "Passa Quatro" },
        { nome: "Passa Tempo" },
        { nome: "Passa-Vinte" },
        { nome: "Passabém" },
        { nome: "Passos" },
        { nome: "Patis" },
        { nome: "Patos de Minas" },
        { nome: "Patrocínio" },
        { nome: "Patrocínio do Muriaé" },
        { nome: "Paula Cândido" },
        { nome: "Paulistas" },
        { nome: "Pavão" },
        { nome: "Peçanha" },
        { nome: "Pedra Azul" },
        { nome: "Pedra Bonita" },
        { nome: "Pedra do Anta" },
        { nome: "Pedra do Indaiá" },
        { nome: "Pedra Dourada" },
        { nome: "Pedralva" },
        { nome: "Pedras de Maria da Cruz" },
        { nome: "Pedrinópolis" },
        { nome: "Pedro Leopoldo" },
        { nome: "Pedro Teixeira" },
        { nome: "Pequeri" },
        { nome: "Pequi" },
        { nome: "Perdigão" },
        { nome: "Perdizes" },
        { nome: "Perdões" },
        { nome: "Periquito" },
        { nome: "Pescador" },
        { nome: "Piau" },
        { nome: "Piedade de Caratinga" },
        { nome: "Piedade de Ponte Nova" },
        { nome: "Piedade do Rio Grande" },
        { nome: "Piedade dos Gerais" },
        { nome: "Pimenta" },
        { nome: "Pingo-d'Água" },
        { nome: "Pintópolis" },
        { nome: "Piracema" },
        { nome: "Pirajuba" },
        { nome: "Piranga" },
        { nome: "Piranguçu" },
        { nome: "Piranguinho" },
        { nome: "Pirapetinga" },
        { nome: "Pirapora" },
        { nome: "Piraúba" },
        { nome: "Pitangui" },
        { nome: "Piumhi" },
        { nome: "Planura" },
        { nome: "Poço Fundo" },
        { nome: "Poços de Caldas" },
        { nome: "Pocrane" },
        { nome: "Pompéu" },
        { nome: "Ponte Nova" },
        { nome: "Ponto Chique" },
        { nome: "Ponto dos Volantes" },
        { nome: "Porteirinha" },
        { nome: "Porto Firme" },
        { nome: "Poté" },
        { nome: "Pouso Alegre" },
        { nome: "Pouso Alto" },
        { nome: "Prados" },
        { nome: "Prata" },
        { nome: "Pratápolis" },
        { nome: "Pratinha" },
        { nome: "Presidente Bernardes" },
        { nome: "Presidente Juscelino" },
        { nome: "Presidente Kubitschek" },
        { nome: "Presidente Olegário" },
        { nome: "Prudente de Morais" },
        { nome: "Quartel Geral" },
        { nome: "Queluzito" },
        { nome: "Raposos" },
        { nome: "Raul Soares" },
        { nome: "Recreio" },
        { nome: "Reduto" },
        { nome: "Resende Costa" },
        { nome: "Resplendor" },
        { nome: "Ressaquinha" },
        { nome: "Riachinho" },
        { nome: "Riacho dos Machados" },
        { nome: "Ribeirão das Neves" },
        { nome: "Ribeirão Vermelho" },
        { nome: "Rio Acima" },
        { nome: "Rio Casca" },
        { nome: "Rio do Prado" },
        { nome: "Rio Doce" },
        { nome: "Rio Espera" },
        { nome: "Rio Manso" },
        { nome: "Rio Novo" },
        { nome: "Rio Paranaíba" },
        { nome: "Rio Pardo de Minas" },
        { nome: "Rio Piracicaba" },
        { nome: "Rio Pomba" },
        { nome: "Rio Preto" },
        { nome: "Rio Vermelho" },
        { nome: "Ritápolis" },
        { nome: "Rochedo de Minas" },
        { nome: "Rodeiro" },
        { nome: "Romaria" },
        { nome: "Rosário da Limeira" },
        { nome: "Rubelita" },
        { nome: "Rubim" },
        { nome: "Sabará" },
        { nome: "Sabinópolis" },
        { nome: "Sacramento" },
        { nome: "Salinas" },
        { nome: "Salto da Divisa" },
        { nome: "Santa Bárbara" },
        { nome: "Santa Bárbara do Leste" },
        { nome: "Santa Bárbara do Monte Verde" },
        { nome: "Santa Bárbara do Tugúrio" },
        { nome: "Santa Cruz de Minas" },
        { nome: "Santa Cruz de Salinas" },
        { nome: "Santa Cruz do Escalvado" },
        { nome: "Santa Efigênia de Minas" },
        { nome: "Santa Fé de Minas" },
        { nome: "Santa Helena de Minas" },
        { nome: "Santa Juliana" },
        { nome: "Santa Luzia" },
        { nome: "Santa Margarida" },
        { nome: "Santa Maria de Itabira" },
        { nome: "Santa Maria do Salto" },
        { nome: "Santa Maria do Suaçuí" },
        { nome: "Santa Rita de Caldas" },
        { nome: "Santa Rita de Ibitipoca" },
        { nome: "Santa Rita de Jacutinga" },
        { nome: "Santa Rita de Minas" },
        { nome: "Santa Rita do Itueto" },
        { nome: "Santa Rita do Sapucaí" },
        { nome: "Santa Rosa da Serra" },
        { nome: "Santa Vitória" },
        { nome: "Santana da Vargem" },
        { nome: "Santana de Cataguases" },
        { nome: "Santana de Pirapama" },
        { nome: "Santana do Deserto" },
        { nome: "Santana do Garambéu" },
        { nome: "Santana do Jacaré" },
        { nome: "Santana do Manhuaçu" },
        { nome: "Santana do Paraíso" },
        { nome: "Santana do Riacho" },
        { nome: "Santana dos Montes" },
        { nome: "Santo Antônio do Amparo" },
        { nome: "Santo Antônio do Aventureiro" },
        { nome: "Santo Antônio do Grama" },
        { nome: "Santo Antônio do Itambé" },
        { nome: "Santo Antônio do Jacinto" },
        { nome: "Santo Antônio do Monte" },
        { nome: "Santo Antônio do Retiro" },
        { nome: "Santo Antônio do Rio Abaixo" },
        { nome: "Santo Hipólito" },
        { nome: "Santos Dumont" },
        { nome: "São Bento Abade" },
        { nome: "São Brás do Suaçuí" },
        { nome: "São Domingos das Dores" },
        { nome: "São Domingos do Prata" },
        { nome: "São Félix de Minas" },
        { nome: "São Francisco" },
        { nome: "São Francisco de Paula" },
        { nome: "São Francisco de Sales" },
        { nome: "São Francisco do Glória" },
        { nome: "São Geraldo" },
        { nome: "São Geraldo da Piedade" },
        { nome: "São Geraldo do Baixio" },
        { nome: "São Gonçalo do Abaeté" },
        { nome: "São Gonçalo do Pará" },
        { nome: "São Gonçalo do Rio Abaixo" },
        { nome: "São Gonçalo do Rio Preto" },
        { nome: "São Gonçalo do Sapucaí" },
        { nome: "São Gotardo" },
        { nome: "São João Batista do Glória" },
        { nome: "São João da Lagoa" },
        { nome: "São João da Mata" },
        { nome: "São João da Ponte" },
        { nome: "São João das Missões" },
        { nome: "São João del Rei" },
        { nome: "São João do Manhuaçu" },
        { nome: "São João do Manteninha" },
        { nome: "São João do Oriente" },
        { nome: "São João do Pacuí" },
        { nome: "São João do Paraíso" },
        { nome: "São João Evangelista" },
        { nome: "São João Nepomuceno" },
        { nome: "São Joaquim de Bicas" },
        { nome: "São José da Barra" },
        { nome: "São José da Lapa" },
        { nome: "São José da Safira" },
        { nome: "São José da Varginha" },
        { nome: "São José do Alegre" },
        { nome: "São José do Divino" },
        { nome: "São José do Goiabal" },
        { nome: "São José do Jacuri" },
        { nome: "São José do Mantimento" },
        { nome: "São Lourenço" },
        { nome: "São Miguel do Anta" },
        { nome: "São Pedro da União" },
        { nome: "São Pedro do Suaçuí" },
        { nome: "São Pedro dos Ferros" },
        { nome: "São Romão" },
        { nome: "São Roque de Minas" },
        { nome: "São Sebastião da Bela Vista" },
        { nome: "São Sebastião da Vargem Alegre" },
        { nome: "São Sebastião do Anta" },
        { nome: "São Sebastião do Maranhão" },
        { nome: "São Sebastião do Oeste" },
        { nome: "São Sebastião do Paraíso" },
        { nome: "São Sebastião do Rio Preto" },
        { nome: "São Sebastião do Rio Verde" },
        { nome: "São Thomé das Letras" },
        { nome: "São Tiago" },
        { nome: "São Tomás de Aquino" },
        { nome: "São Vicente de Minas" },
        { nome: "Sapucaí-Mirim" },
        { nome: "Sardoá" },
        { nome: "Sarzedo" },
        { nome: "Sem-Peixe" },
        { nome: "Senador Amaral" },
        { nome: "Senador Cortes" },
        { nome: "Senador Firmino" },
        { nome: "Senador José Bento" },
        { nome: "Senador Modestino Gonçalves" },
        { nome: "Senhora de Oliveira" },
        { nome: "Senhora do Porto" },
        { nome: "Senhora dos Remédios" },
        { nome: "Sericita" },
        { nome: "Seritinga" },
        { nome: "Serra Azul de Minas" },
        { nome: "Serra da Saudade" },
        { nome: "Serra do Salitre" },
        { nome: "Serra dos Aimorés" },
        { nome: "Serrania" },
        { nome: "Serranópolis de Minas" },
        { nome: "Serranos" },
        { nome: "Serro" },
        { nome: "Sete Lagoas" },
        { nome: "Setubinha" },
        { nome: "Silveirânia" },
        { nome: "Silvianópolis" },
        { nome: "Simão Pereira" },
        { nome: "Simonésia" },
        { nome: "Sobrália" },
        { nome: "Soledade de Minas" },
        { nome: "Tabuleiro" },
        { nome: "Taiobeiras" },
        { nome: "Taparuba" },
        { nome: "Tapira" },
        { nome: "Tapiraí" },
        { nome: "Taquaraçu de Minas" },
        { nome: "Tarumirim" },
        { nome: "Teixeiras" },
        { nome: "Teófilo Otoni" },
        { nome: "Timóteo" },
        { nome: "Tiradentes" },
        { nome: "Tiros" },
        { nome: "Tocantins" },
        { nome: "Tocos do Moji" },
        { nome: "Toledo" },
        { nome: "Tombos" },
        { nome: "Três Corações" },
        { nome: "Três Marias" },
        { nome: "Três Pontas" },
        { nome: "Tumiritinga" },
        { nome: "Tupaciguara" },
        { nome: "Turmalina" },
        { nome: "Turvolândia" },
        { nome: "Ubá" },
        { nome: "Ubaí" },
        { nome: "Ubaporanga" },
        { nome: "Uberaba" },
        { nome: "Uberlândia" },
        { nome: "Umburatiba" },
        { nome: "Unaí" },
        { nome: "União de Minas" },
        { nome: "Uruana de Minas" },
        { nome: "Urucânia" },
        { nome: "Urucuia" },
        { nome: "Vargem Alegre" },
        { nome: "Vargem Bonita" },
        { nome: "Vargem Grande do Rio Pardo" },
        { nome: "Varginha" },
        { nome: "Varjão de Minas" },
        { nome: "Várzea da Palma" },
        { nome: "Varzelândia" },
        { nome: "Vazante" },
        { nome: "Verdelândia" },
        { nome: "Veredinha" },
        { nome: "Veríssimo" },
        { nome: "Vermelho Novo" },
        { nome: "Vespasiano" },
        { nome: "Viçosa" },
        { nome: "Vieiras" },
        { nome: "Virgem da Lapa" },
        { nome: "Virgínia" },
        { nome: "Virginópolis" },
        { nome: "Virgolândia" },
        { nome: "Visconde do Rio Branco" },
        { nome: "Volta Grande" },
        { nome: "Wenceslau Braz" }
    ],

    "MS": [
        { nome: "Água Clara" },
        { nome: "Alcinópolis" },
        { nome: "Amambaí" },
        { nome: "Anastácio" },
        { nome: "Anaurilândia" },
        { nome: "Angélica" },
        { nome: "Antônio João" },
        { nome: "Aparecida do Taboado" },
        { nome: "Aquidauana" },
        { nome: "Aral Moreira" },
        { nome: "Bandeirantes" },
        { nome: "Bataguassu" },
        { nome: "Bataiporã" },
        { nome: "Bela Vista" },
        { nome: "Bodoquena" },
        { nome: "Bonito" },
        { nome: "Brasilândia" },
        { nome: "Caarapó" },
        { nome: "Camapuã" },
        { nome: "Campo Grande" },
        { nome: "Caracol" },
        { nome: "Cassilândia" },
        { nome: "Chapadão do Sul" },
        { nome: "Corguinho" },
        { nome: "Coronel Sapucaia" },
        { nome: "Corumbá" },
        { nome: "Costa Rica" },
        { nome: "Coxim" },
        { nome: "Deodápolis" },
        { nome: "Dois Irmãos do Buriti" },
        { nome: "Douradina" },
        { nome: "Dourados" },
        { nome: "Eldorado" },
        { nome: "Fátima do Sul" },
        { nome: "Glória de Dourados" },
        { nome: "Guia Lopes da Laguna" },
        { nome: "Iguatemi" },
        { nome: "Inocência" },
        { nome: "Itaporã" },
        { nome: "Itaquiraí" },
        { nome: "Ivinhema" },
        { nome: "Japorã" },
        { nome: "Jaraguari" },
        { nome: "Jardim" },
        { nome: "Jateí" },
        { nome: "Juti" },
        { nome: "Ladário" },
        { nome: "Laguna Carapã" },
        { nome: "Maracaju" },
        { nome: "Miranda" },
        { nome: "Mundo Novo" },
        { nome: "Naviraí" },
        { nome: "Nioaque" },
        { nome: "Nova Alvorada do Sul" },
        { nome: "Nova Andradina" },
        { nome: "Novo Horizonte do Sul" },
        { nome: "Paranaíba" },
        { nome: "Paranhos" },
        { nome: "Pedro Gomes" },
        { nome: "Ponta Porã" },
        { nome: "Porto Murtinho" },
        { nome: "Ribas do Rio Pardo" },
        { nome: "Rio Brilhante" },
        { nome: "Rio Negro" },
        { nome: "Rio Verde de Mato Grosso" },
        { nome: "Rochedo" },
        { nome: "Santa Rita do Pardo" },
        { nome: "São Gabriel do Oeste" },
        { nome: "Selvíria" },
        { nome: "Sete Quedas" },
        { nome: "Sidrolândia" },
        { nome: "Sonora" },
        { nome: "Tacuru" },
        { nome: "Taquarussu" },
        { nome: "Terenos" },
        { nome: "Três Lagoas" },
        { nome: "Vicentina" }
    ],

    "MT": [
        { nome: "Acorizal" },
        { nome: "Água Boa" },
        { nome: "Alta Floresta" },
        { nome: "Alto Araguaia" },
        { nome: "Alto Boa Vista" },
        { nome: "Alto Garças" },
        { nome: "Alto Paraguai" },
        { nome: "Alto Taquari" },
        { nome: "Apiacás" },
        { nome: "Araguaiana" },
        { nome: "Araguainha" },
        { nome: "Araputanga" },
        { nome: "Arenápolis" },
        { nome: "Aripuanã" },
        { nome: "Barão de Melgaço" },
        { nome: "Barra do Bugres" },
        { nome: "Barra do Garças" },
        { nome: "Bom Jesus do Araguaia" },
        { nome: "Brasnorte" },
        { nome: "Cáceres" },
        { nome: "Campinápolis" },
        { nome: "Campo Novo do Parecis" },
        { nome: "Campo Verde" },
        { nome: "Campos de Júlio" },
        { nome: "Canabrava do Norte" },
        { nome: "Canarana" },
        { nome: "Carlinda" },
        { nome: "Castanheira" },
        { nome: "Chapada dos Guimarães" },
        { nome: "Cláudia" },
        { nome: "Cocalinho" },
        { nome: "Colíder" },
        { nome: "Colniza" },
        { nome: "Comodoro" },
        { nome: "Confresa" },
        { nome: "Conquista d'Oeste" },
        { nome: "Cotriguaçu" },
        { nome: "Curvelândia" },
        { nome: "Cuiabá" },
        { nome: "Denise" },
        { nome: "Diamantino" },
        { nome: "Dom Aquino" },
        { nome: "Feliz Natal" },
        { nome: "Figueirópolis d'Oeste" },
        { nome: "Gaúcha do Norte" },
        { nome: "General Carneiro" },
        { nome: "Glória d'Oeste" },
        { nome: "Guarantã do Norte" },
        { nome: "Guiratinga" },
        { nome: "Indiavaí" },
        { nome: "Itaúba" },
        { nome: "Itiquira" },
        { nome: "Jaciara" },
        { nome: "Jangada" },
        { nome: "Jauru" },
        { nome: "Juara" },
        { nome: "Juína" },
        { nome: "Juruena" },
        { nome: "Juscimeira" },
        { nome: "Lambari d'Oeste" },
        { nome: "Lucas do Rio Verde" },
        { nome: "Luciára" },
        { nome: "Marcelândia" },
        { nome: "Matupá" },
        { nome: "Mirassol d'Oeste" },
        { nome: "Nobres" },
        { nome: "Nortelândia" },
        { nome: "Nossa Senhora do Livramento" },
        { nome: "Nova Bandeirantes" },
        { nome: "Nova Brasilândia" },
        { nome: "Nova Canãa do Norte" },
        { nome: "Nova Guarita" },
        { nome: "Nova Lacerda" },
        { nome: "Nova Marilândia" },
        { nome: "Nova Maringá" },
        { nome: "Nova Monte Verde" },
        { nome: "Nova Mutum" },
        { nome: "Nova Nazaré" },
        { nome: "Nova Olímpia" },
        { nome: "Nova Santa Helena" },
        { nome: "Nova Ubiratã" },
        { nome: "Nova Xavantina" },
        { nome: "Novo Horizonte do Norte" },
        { nome: "Novo Mundo" },
        { nome: "Novo Santo Antônio" },
        { nome: "Novo São Joaquim" },
        { nome: "Paranaíta" },
        { nome: "Paranatinga" },
        { nome: "Pedra Preta" },
        { nome: "Peixoto de Azevedo" },
        { nome: "Planalto da Serra" },
        { nome: "Poconé" },
        { nome: "Pontal do Araguaia" },
        { nome: "Ponte Branca" },
        { nome: "Pontes e Lacerda" },
        { nome: "Porto Alegre do Norte" },
        { nome: "Porto dos Gaúchos" },
        { nome: "Porto Esperidião" },
        { nome: "Porto Estrela" },
        { nome: "Poxoréo" },
        { nome: "Primavera do Leste" },
        { nome: "Querência" },
        { nome: "Reserva do Cabaçal" },
        { nome: "Ribeirão Cascalheira" },
        { nome: "Ribeirãozinho" },
        { nome: "Rio Branco" },
        { nome: "Rondolândia" },
        { nome: "Rondonópolis" },
        { nome: "Rosário Oeste" },
        { nome: "Salto do Céu" },
        { nome: "Santa Carmem" },
        { nome: "Santa Cruz do Xingu" },
        { nome: "Santa Rita do Trivelato" },
        { nome: "Santa Terezinha" },
        { nome: "Santo Afonso" },
        { nome: "Santo Antônio do Leste" },
        { nome: "Santo Antônio do Leverger" },
        { nome: "São Félix do Araguaia" },
        { nome: "São José do Povo" },
        { nome: "São José do Rio Claro" },
        { nome: "São José do Xingu" },
        { nome: "São José dos Quatro Marcos" },
        { nome: "São Pedro da Cipa" },
        { nome: "Sapezal" },
        { nome: "Serra Nova Dourada" },
        { nome: "Sinop" },
        { nome: "Sorriso" },
        { nome: "Tabaporã" },
        { nome: "Tangará da Serra" },
        { nome: "Tapurah" },
        { nome: "Terra Nova do Norte" },
        { nome: "Tesouro" },
        { nome: "Torixoréu" },
        { nome: "União do Sul" },
        { nome: "Vale de São Domingos" },
        { nome: "Várzea Grande" },
        { nome: "Vera" },
        { nome: "Vila Bela da Santíssima Trindade" },
        { nome: "Vila Rica" }
    ],

    "PA": [
        { nome: "Abaetetuba" },
        { nome: "Abel Figueiredo" },
        { nome: "Acará" },
        { nome: "Afuá" },
        { nome: "Água Azul do Norte" },
        { nome: "Alenquer" },
        { nome: "Almeirim" },
        { nome: "Altamira" },
        { nome: "Anajás" },
        { nome: "Ananindeua" },
        { nome: "Anapu" },
        { nome: "Augusto Corrêa" },
        { nome: "Aurora do Pará" },
        { nome: "Aveiro" },
        { nome: "Bagre" },
        { nome: "Baião" },
        { nome: "Bannach" },
        { nome: "Barcarena" },
        { nome: "Belém" },
        { nome: "Belterra" },
        { nome: "Benevides" },
        { nome: "Bom Jesus do Tocantins" },
        { nome: "Bonito" },
        { nome: "Bragança" },
        { nome: "Brasil Novo" },
        { nome: "Brejo Grande do Araguaia" },
        { nome: "Breu Branco" },
        { nome: "Breves" },
        { nome: "Bujaru" },
        { nome: "Cachoeira do Arari" },
        { nome: "Cachoeira do Piriá" },
        { nome: "Cametá" },
        { nome: "Canaã dos Carajás" },
        { nome: "Capanema" },
        { nome: "Capitão Poço" },
        { nome: "Castanhal" },
        { nome: "Chaves" },
        { nome: "Colares" },
        { nome: "Conceição do Araguaia" },
        { nome: "Concórdia do Pará" },
        { nome: "Cumaru do Norte" },
        { nome: "Curionópolis" },
        { nome: "Curralinho" },
        { nome: "Curuá" },
        { nome: "Curuçá" },
        { nome: "Dom Eliseu" },
        { nome: "Eldorado dos Carajás" },
        { nome: "Faro" },
        { nome: "Floresta do Araguaia" },
        { nome: "Garrafão do Norte" },
        { nome: "Goianésia do Pará" },
        { nome: "Gurupá" },
        { nome: "Igarapé-Açu" },
        { nome: "Igarapé-Miri" },
        { nome: "Inhangapi" },
        { nome: "Ipixuna do Pará" },
        { nome: "Irituia" },
        { nome: "Itaituba" },
        { nome: "Itupiranga" },
        { nome: "Jacareacanga" },
        { nome: "Jacundá" },
        { nome: "Juruti" },
        { nome: "Limoeiro do Ajuru" },
        { nome: "Mãe do Rio" },
        { nome: "Magalhães Barata" },
        { nome: "Marabá" },
        { nome: "Maracanã" },
        { nome: "Marapanim" },
        { nome: "Marituba" },
        { nome: "Medicilândia" },
        { nome: "Melgaço" },
        { nome: "Mocajuba" },
        { nome: "Moju" },
        { nome: "Monte Alegre" },
        { nome: "Muaná" },
        { nome: "Nova Esperança do Piriá" },
        { nome: "Nova Ipixuna" },
        { nome: "Nova Timboteua" },
        { nome: "Novo Progresso" },
        { nome: "Novo Repartimento" },
        { nome: "Óbidos" },
        { nome: "Oeiras do Pará" },
        { nome: "Oriximiná" },
        { nome: "Ourém" },
        { nome: "Ourilândia do Norte" },
        { nome: "Pacajá" },
        { nome: "Palestina do Pará" },
        { nome: "Paragominas" },
        { nome: "Parauapebas" },
        { nome: "Pau d'Arco" },
        { nome: "Peixe-Boi" },
        { nome: "Piçarra" },
        { nome: "Placas" },
        { nome: "Ponta de Pedras" },
        { nome: "Portel" },
        { nome: "Porto de Moz" },
        { nome: "Prainha" },
        { nome: "Primavera" },
        { nome: "Quatipuru" },
        { nome: "Redenção" },
        { nome: "Rio Maria" },
        { nome: "Rondon do Pará" },
        { nome: "Rurópolis" },
        { nome: "Salinópolis" },
        { nome: "Salvaterra" },
        { nome: "Santa Bárbara do Pará" },
        { nome: "Santa Cruz do Arari" },
        { nome: "Santa Isabel do Pará" },
        { nome: "Santa Luzia do Pará" },
        { nome: "Santa Maria das Barreiras" },
        { nome: "Santa Maria do Pará" },
        { nome: "Santana do Araguaia" },
        { nome: "Santarém" },
        { nome: "Santarém Novo" },
        { nome: "Santo Antônio do Tauá" },
        { nome: "São Caetano de Odivela" },
        { nome: "São Domingos do Araguaia" },
        { nome: "São Domingos do Capim" },
        { nome: "São Félix do Xingu" },
        { nome: "São Francisco do Pará" },
        { nome: "São Geraldo do Araguaia" },
        { nome: "São João da Ponta" },
        { nome: "São João de Pirabas" },
        { nome: "São João do Araguaia" },
        { nome: "São Miguel do Guamá" },
        { nome: "São Sebastião da Boa Vista" },
        { nome: "Sapucaia" },
        { nome: "Senador José Porfírio" },
        { nome: "Soure" },
        { nome: "Tailândia" },
        { nome: "Terra Alta" },
        { nome: "Terra Santa" },
        { nome: "Tomé-Açu" },
        { nome: "Tracuateua" },
        { nome: "Trairão" },
        { nome: "Tucumã" },
        { nome: "Tucuruí" },
        { nome: "Ulianópolis" },
        { nome: "Uruará" },
        { nome: "Vigia" },
        { nome: "Viseu" },
        { nome: "Vitória do Xingu" },
        { nome: "Xinguara" }
    ],

    "PB": [
        { nome: "Água Branca" },
        { nome: "Aguiar" },
        { nome: "Alagoa Grande" },
        { nome: "Alagoa Nova" },
        { nome: "Alagoinha" },
        { nome: "Alcantil" },
        { nome: "Algodão de Jandaíra" },
        { nome: "Alhandra" },
        { nome: "Amparo" },
        { nome: "Aparecida" },
        { nome: "Araçagi" },
        { nome: "Arara" },
        { nome: "Araruna" },
        { nome: "Areia" },
        { nome: "Areia de Baraúnas" },
        { nome: "Areial" },
        { nome: "Aroeiras" },
        { nome: "Assunção" },
        { nome: "Baía da Traição" },
        { nome: "Bananeiras" },
        { nome: "Baraúna" },
        { nome: "Barra de Santa Rosa" },
        { nome: "Barra de Santana" },
        { nome: "Barra de São Miguel" },
        { nome: "Bayeux" },
        { nome: "Belém" },
        { nome: "Belém do Brejo do Cruz" },
        { nome: "Bernardino Batista" },
        { nome: "Boa Ventura" },
        { nome: "Boa Vista" },
        { nome: "Bom Jesus" },
        { nome: "Bom Sucesso" },
        { nome: "Bonito de Santa Fé" },
        { nome: "Boqueirão" },
        { nome: "Borborema" },
        { nome: "Brejo do Cruz" },
        { nome: "Brejo dos Santos" },
        { nome: "Caaporã" },
        { nome: "Cabaceiras" },
        { nome: "Cabedelo" },
        { nome: "Cachoeira dos Índios" },
        { nome: "Cacimba de Areia" },
        { nome: "Cacimba de Dentro" },
        { nome: "Cacimbas" },
        { nome: "Caiçara" },
        { nome: "Cajazeiras" },
        { nome: "Cajazeirinhas" },
        { nome: "Caldas Brandão" },
        { nome: "Camalaú" },
        { nome: "Campina Grande" },
        { nome: "Campo de Santana" },
        { nome: "Capim" },
        { nome: "Caraúbas" },
        { nome: "Carrapateira" },
        { nome: "Casserengue" },
        { nome: "Catingueira" },
        { nome: "Catolé do Rocha" },
        { nome: "Caturité" },
        { nome: "Conceição" },
        { nome: "Condado" },
        { nome: "Conde" },
        { nome: "Congo" },
        { nome: "Coremas" },
        { nome: "Coxixola" },
        { nome: "Cruz do Espírito Santo" },
        { nome: "Cubati" },
        { nome: "Cuité" },
        { nome: "Cuité de Mamanguape" },
        { nome: "Cuitegi" },
        { nome: "Curral de Cima" },
        { nome: "Curral Velho" },
        { nome: "Damião" },
        { nome: "Desterro" },
        { nome: "Diamante" },
        { nome: "Dona Inês" },
        { nome: "Duas Estradas" },
        { nome: "Emas" },
        { nome: "Esperança" },
        { nome: "Fagundes" },
        { nome: "Frei Martinho" },
        { nome: "Gado Bravo" },
        { nome: "Guarabira" },
        { nome: "Gurinhém" },
        { nome: "Gurjão" },
        { nome: "Ibiara" },
        { nome: "Igaracy" },
        { nome: "Imaculada" },
        { nome: "Ingá" },
        { nome: "Itabaiana" },
        { nome: "Itaporanga" },
        { nome: "Itapororoca" },
        { nome: "Itatuba" },
        { nome: "Jacaraú" },
        { nome: "Jericó" },
        { nome: "João Pessoa" },
        { nome: "Juarez Távora" },
        { nome: "Juazeirinho" },
        { nome: "Junco do Seridó" },
        { nome: "Juripiranga" },
        { nome: "Juru" },
        { nome: "Lagoa" },
        { nome: "Lagoa de Dentro" },
        { nome: "Lagoa Seca" },
        { nome: "Lastro" },
        { nome: "Livramento" },
        { nome: "Logradouro" },
        { nome: "Lucena" },
        { nome: "Mãe d'Água" },
        { nome: "Malta" },
        { nome: "Mamanguape" },
        { nome: "Manaíra" },
        { nome: "Marcação" },
        { nome: "Mari" },
        { nome: "Marizópolis" },
        { nome: "Massaranduba" },
        { nome: "Mataraca" },
        { nome: "Matinhas" },
        { nome: "Mato Grosso" },
        { nome: "Maturéia" },
        { nome: "Mogeiro" },
        { nome: "Montadas" },
        { nome: "Monte Horebe" },
        { nome: "Monteiro" },
        { nome: "Mulungu" },
        { nome: "Natuba" },
        { nome: "Nazarezinho" },
        { nome: "Nova Floresta" },
        { nome: "Nova Olinda" },
        { nome: "Nova Palmeira" },
        { nome: "Olho d'Água" },
        { nome: "Olivedos" },
        { nome: "Ouro Velho" },
        { nome: "Parari" },
        { nome: "Passagem" },
        { nome: "Patos" },
        { nome: "Paulista" },
        { nome: "Pedra Branca" },
        { nome: "Pedra Lavrada" },
        { nome: "Pedras de Fogo" },
        { nome: "Pedro Régis" },
        { nome: "Piancó" },
        { nome: "Picuí" },
        { nome: "Pilar" },
        { nome: "Pilões" },
        { nome: "Pilõezinhos" },
        { nome: "Pirpirituba" },
        { nome: "Pitimbu" },
        { nome: "Pocinhos" },
        { nome: "Poço Dantas" },
        { nome: "Poço de José de Moura" },
        { nome: "Pombal" },
        { nome: "Prata" },
        { nome: "Princesa Isabel" },
        { nome: "Puxinanã" },
        { nome: "Queimadas" },
        { nome: "Quixabá" },
        { nome: "Remígio" },
        { nome: "Riachão" },
        { nome: "Riachão do Bacamarte" },
        { nome: "Riachão do Poço" },
        { nome: "Riacho de Santo Antônio" },
        { nome: "Riacho dos Cavalos" },
        { nome: "Rio Tinto" },
        { nome: "Salgadinho" },
        { nome: "Salgado de São Félix" },
        { nome: "Santa Cecília" },
        { nome: "Santa Cruz" },
        { nome: "Santa Helena" },
        { nome: "Santa Inês" },
        { nome: "Santa Luzia" },
        { nome: "Santa Rita" },
        { nome: "Santa Teresinha" },
        { nome: "Santana de Mangueira" },
        { nome: "Santana dos Garrotes" },
        { nome: "Santarém" },
        { nome: "Santo André" },
        { nome: "São Bentinho" },
        { nome: "São Bento" },
        { nome: "São Domingos de Pombal" },
        { nome: "São Domingos do Cariri" },
        { nome: "São Francisco" },
        { nome: "São João do Cariri" },
        { nome: "São João do Rio do Peixe" },
        { nome: "São João do Tigre" },
        { nome: "São José da Lagoa Tapada" },
        { nome: "São José de Caiana" },
        { nome: "São José de Espinharas" },
        { nome: "São José de Piranhas" },
        { nome: "São José de Princesa" },
        { nome: "São José do Bonfim" },
        { nome: "São José do Brejo do Cruz" },
        { nome: "São José do Sabugi" },
        { nome: "São José dos Cordeiros" },
        { nome: "São José dos Ramos" },
        { nome: "São Mamede" },
        { nome: "São Miguel de Taipu" },
        { nome: "São Sebastião de Lagoa de Roça" },
        { nome: "São Sebastião do Umbuzeiro" },
        { nome: "Sapé" },
        { nome: "Seridó" },
        { nome: "Serra Branca" },
        { nome: "Serra da Raiz" },
        { nome: "Serra Grande" },
        { nome: "Serra Redonda" },
        { nome: "Serraria" },
        { nome: "Sertãozinho" },
        { nome: "Sobrado" },
        { nome: "Solânea" },
        { nome: "Soledade" },
        { nome: "Sossêgo" },
        { nome: "Sousa" },
        { nome: "Sumé" },
        { nome: "Taperoá" },
        { nome: "Tavares" },
        { nome: "Teixeira" },
        { nome: "Tenório" },
        { nome: "Triunfo" },
        { nome: "Uiraúna" },
        { nome: "Umbuzeiro" },
        { nome: "Várzea" },
        { nome: "Vieirópolis" },
        { nome: "Vista Serrana" },
        { nome: "Zabelê" }
    ],

    "PE": [
        { nome: "Abreu e Lima" },
        { nome: "Afogados da Ingazeira" },
        { nome: "Afrânio" },
        { nome: "Agrestina" },
        { nome: "Água Preta" },
        { nome: "Águas Belas" },
        { nome: "Alagoinha" },
        { nome: "Aliança" },
        { nome: "Altinho" },
        { nome: "Amaraji" },
        { nome: "Angelim" },
        { nome: "Araçoiaba" },
        { nome: "Araripina" },
        { nome: "Arcoverde" },
        { nome: "Barra de Guabiraba" },
        { nome: "Barreiros" },
        { nome: "Belém de Maria" },
        { nome: "Belém de São Francisco" },
        { nome: "Belo Jardim" },
        { nome: "Betânia" },
        { nome: "Bezerros" },
        { nome: "Bodocó" },
        { nome: "Bom Conselho" },
        { nome: "Bom Jardim" },
        { nome: "Bonito" },
        { nome: "Brejão" },
        { nome: "Brejinho" },
        { nome: "Brejo da Madre de Deus" },
        { nome: "Buenos Aires" },
        { nome: "Buíque" },
        { nome: "Cabo de Santo Agostinho" },
        { nome: "Cabrobó" },
        { nome: "Cachoeirinha" },
        { nome: "Caetés" },
        { nome: "Calçado" },
        { nome: "Calumbi" },
        { nome: "Camaragibe" },
        { nome: "Camocim de São Félix" },
        { nome: "Camutanga" },
        { nome: "Canhotinho" },
        { nome: "Capoeiras" },
        { nome: "Carnaíba" },
        { nome: "Carnaubeira da Penha" },
        { nome: "Carpina" },
        { nome: "Caruaru" },
        { nome: "Casinhas" },
        { nome: "Catende" },
        { nome: "Cedro" },
        { nome: "Chã de Alegria" },
        { nome: "Chã Grande" },
        { nome: "Condado" },
        { nome: "Correntes" },
        { nome: "Cortês" },
        { nome: "Cumaru" },
        { nome: "Cupira" },
        { nome: "Custódia" },
        { nome: "Dormentes" },
        { nome: "Escada" },
        { nome: "Exu" },
        { nome: "Feira Nova" },
        { nome: "Fernando de Noronha" },
        { nome: "Ferreiros" },
        { nome: "Flores" },
        { nome: "Floresta" },
        { nome: "Frei Miguelinho" },
        { nome: "Gameleira" },
        { nome: "Garanhuns" },
        { nome: "Glória do Goitá" },
        { nome: "Goiana" },
        { nome: "Granito" },
        { nome: "Gravatá" },
        { nome: "Iati" },
        { nome: "Ibimirim" },
        { nome: "Ibirajuba" },
        { nome: "Igarassu" },
        { nome: "Iguaraci" },
        { nome: "Inajá" },
        { nome: "Ingazeira" },
        { nome: "Ipojuca" },
        { nome: "Ipubi" },
        { nome: "Itacuruba" },
        { nome: "Itaíba" },
        { nome: "Itamaracá" },
        { nome: "Itambé" },
        { nome: "Itapetim" },
        { nome: "Itapissuma" },
        { nome: "Itaquitinga" },
        { nome: "Jaboatão dos Guararapes" },
        { nome: "Jaqueira" },
        { nome: "Jataúba" },
        { nome: "Jatobá" },
        { nome: "João Alfredo" },
        { nome: "Joaquim Nabuco" },
        { nome: "Jucati" },
        { nome: "Jupi" },
        { nome: "Jurema" },
        { nome: "Lagoa do Carro" },
        { nome: "Lagoa do Itaenga" },
        { nome: "Lagoa do Ouro" },
        { nome: "Lagoa dos Gatos" },
        { nome: "Lagoa Grande" },
        { nome: "Lajedo" },
        { nome: "Limoeiro" },
        { nome: "Macaparana" },
        { nome: "Machados" },
        { nome: "Manari" },
        { nome: "Maraial" },
        { nome: "Mirandiba" },
        { nome: "Moreilândia" },
        { nome: "Moreno" },
        { nome: "Nazaré da Mata" },
        { nome: "Olinda" },
        { nome: "Orobó" },
        { nome: "Orocó" },
        { nome: "Ouricuri" },
        { nome: "Palmares" },
        { nome: "Palmeirina" },
        { nome: "Panelas" },
        { nome: "Paranatama" },
        { nome: "Parnamirim" },
        { nome: "Passira" },
        { nome: "Paudalho" },
        { nome: "Paulista" },
        { nome: "Pedra" },
        { nome: "Pesqueira" },
        { nome: "Petrolândia" },
        { nome: "Petrolina" },
        { nome: "Poção" },
        { nome: "Pombos" },
        { nome: "Primavera" },
        { nome: "Quipapá" },
        { nome: "Quixaba" },
        { nome: "Recife" },
        { nome: "Riacho das Almas" },
        { nome: "Ribeirão" },
        { nome: "Rio Formoso" },
        { nome: "Sairé" },
        { nome: "Salgadinho" },
        { nome: "Salgueiro" },
        { nome: "Saloá" },
        { nome: "Sanharó" },
        { nome: "Santa Cruz" },
        { nome: "Santa Cruz da Baixa Verde" },
        { nome: "Santa Cruz do Capibaribe" },
        { nome: "Santa Filomena" },
        { nome: "Santa Maria da Boa Vista" },
        { nome: "Santa Maria do Cambucá" },
        { nome: "Santa Terezinha" },
        { nome: "São Benedito do Sul" },
        { nome: "São Bento do Una" },
        { nome: "São Caitano" },
        { nome: "São João" },
        { nome: "São Joaquim do Monte" },
        { nome: "São José da Coroa Grande" },
        { nome: "São José do Belmonte" },
        { nome: "São José do Egito" },
        { nome: "São Lourenço da Mata" },
        { nome: "São Vicente Ferrer" },
        { nome: "Serra Talhada" },
        { nome: "Serrita" },
        { nome: "Sertânia" },
        { nome: "Sirinhaém" },
        { nome: "Solidão" },
        { nome: "Surubim" },
        { nome: "Tabira" },
        { nome: "Tacaimbó" },
        { nome: "Tacaratu" },
        { nome: "Tamandaré" },
        { nome: "Taquaritinga do Norte" },
        { nome: "Terezinha" },
        { nome: "Terra Nova" },
        { nome: "Timbaúba" },
        { nome: "Toritama" },
        { nome: "Tracunhaém" },
        { nome: "Trindade" },
        { nome: "Triunfo" },
        { nome: "Tupanatinga" },
        { nome: "Tuparetama" },
        { nome: "Venturosa" },
        { nome: "Verdejante" },
        { nome: "Vertente do Lério" },
        { nome: "Vertentes" },
        { nome: "Vicência" },
        { nome: "Vitória de Santo Antão" },
        { nome: "Xexéu" }
    ],

    "PI": [
        { nome: "Acauã" },
        { nome: "Agricolândia" },
        { nome: "Água Branca" },
        { nome: "Alagoinha do Piauí" },
        { nome: "Alegrete do Piauí" },
        { nome: "Alto Longá" },
        { nome: "Altos" },
        { nome: "Alvorada do Gurguéia" },
        { nome: "Amarante" },
        { nome: "Angical do Piauí" },
        { nome: "Anísio de Abreu" },
        { nome: "Antônio Almeida" },
        { nome: "Aroazes" },
        { nome: "Arraial" },
        { nome: "Assunção do Piauí" },
        { nome: "Avelino Lopes" },
        { nome: "Baixa Grande do Ribeiro" },
        { nome: "Barra d'Alcântara" },
        { nome: "Barras" },
        { nome: "Barreiras do Piauí" },
        { nome: "Barro Duro" },
        { nome: "Batalha" },
        { nome: "Bela Vista do Piauí" },
        { nome: "Belém do Piauí" },
        { nome: "Beneditinos" },
        { nome: "Bertolínia" },
        { nome: "Betânia do Piauí" },
        { nome: "Boa Hora" },
        { nome: "Bocaina" },
        { nome: "Bom Jesus" },
        { nome: "Bom Princípio do Piauí" },
        { nome: "Bonfim do Piauí" },
        { nome: "Boqueirão do Piauí" },
        { nome: "Brasileira" },
        { nome: "Brejo do Piauí" },
        { nome: "Buriti dos Lopes" },
        { nome: "Buriti dos Montes" },
        { nome: "Cabeceiras do Piauí" },
        { nome: "Cajazeiras do Piauí" },
        { nome: "Cajueiro da Praia" },
        { nome: "Caldeirão Grande do Piauí" },
        { nome: "Campinas do Piauí" },
        { nome: "Campo Alegre do Fidalgo" },
        { nome: "Campo Grande do Piauí" },
        { nome: "Campo Largo do Piauí" },
        { nome: "Campo Maior" },
        { nome: "Canavieira" },
        { nome: "Canto do Buriti" },
        { nome: "Capitão de Campos" },
        { nome: "Capitão Gervásio Oliveira" },
        { nome: "Caracol" },
        { nome: "Caraúbas do Piauí" },
        { nome: "Caridade do Piauí" },
        { nome: "Castelo do Piauí" },
        { nome: "Caxingó" },
        { nome: "Cocal" },
        { nome: "Cocal de Telha" },
        { nome: "Cocal dos Alves" },
        { nome: "Coivaras" },
        { nome: "Colônia do Gurguéia" },
        { nome: "Colônia do Piauí" },
        { nome: "Conceição do Canindé" },
        { nome: "Coronel José Dias" },
        { nome: "Corrente" },
        { nome: "Cristalândia do Piauí" },
        { nome: "Cristino Castro" },
        { nome: "Curimatá" },
        { nome: "Currais" },
        { nome: "Curral Novo do Piauí" },
        { nome: "Curralinhos" },
        { nome: "Demerval Lobão" },
        { nome: "Dirceu Arcoverde" },
        { nome: "Dom Expedito Lopes" },
        { nome: "Dom Inocêncio" },
        { nome: "Domingos Mourão" },
        { nome: "Elesbão Veloso" },
        { nome: "Eliseu Martins" },
        { nome: "Esperantina" },
        { nome: "Fartura do Piauí" },
        { nome: "Flores do Piauí" },
        { nome: "Floresta do Piauí" },
        { nome: "Floriano" },
        { nome: "Francinópolis" },
        { nome: "Francisco Ayres" },
        { nome: "Francisco Macedo" },
        { nome: "Francisco Santos" },
        { nome: "Fronteiras" },
        { nome: "Geminiano" },
        { nome: "Gilbués" },
        { nome: "Guadalupe" },
        { nome: "Guaribas" },
        { nome: "Hugo Napoleão" },
        { nome: "Ilha Grande" },
        { nome: "Inhuma" },
        { nome: "Ipiranga do Piauí" },
        { nome: "Isaías Coelho" },
        { nome: "Itainópolis" },
        { nome: "Itaueira" },
        { nome: "Jacobina do Piauí" },
        { nome: "Jaicós" },
        { nome: "Jardim do Mulato" },
        { nome: "Jatobá do Piauí" },
        { nome: "Jerumenha" },
        { nome: "João Costa" },
        { nome: "Joaquim Pires" },
        { nome: "Joca Marques" },
        { nome: "José de Freitas" },
        { nome: "Juazeiro do Piauí" },
        { nome: "Júlio Borges" },
        { nome: "Jurema" },
        { nome: "Lagoa Alegre" },
        { nome: "Lagoa de São Francisco" },
        { nome: "Lagoa do Barro do Piauí" },
        { nome: "Lagoa do Piauí" },
        { nome: "Lagoa do Sítio" },
        { nome: "Lagoinha do Piauí" },
        { nome: "Landri Sales" },
        { nome: "Luís Correia" },
        { nome: "Luzilândia" },
        { nome: "Madeiro" },
        { nome: "Manoel Emídio" },
        { nome: "Marcolândia" },
        { nome: "Marcos Parente" },
        { nome: "Massapê do Piauí" },
        { nome: "Matias Olímpio" },
        { nome: "Miguel Alves" },
        { nome: "Miguel Leão" },
        { nome: "Milton Brandão" },
        { nome: "Monsenhor Gil" },
        { nome: "Monsenhor Hipólito" },
        { nome: "Monte Alegre do Piauí" },
        { nome: "Morro Cabeça no Tempo" },
        { nome: "Morro do Chapéu do Piauí" },
        { nome: "Murici dos Portelas" },
        { nome: "Nazaré do Piauí" },
        { nome: "Nossa Senhora de Nazaré" },
        { nome: "Nossa Senhora dos Remédios" },
        { nome: "Nova Santa Rita" },
        { nome: "Novo Oriente do Piauí" },
        { nome: "Novo Santo Antônio" },
        { nome: "Oeiras" },
        { nome: "Olho d'Água do Piauí" },
        { nome: "Padre Marcos" },
        { nome: "Paes Landim" },
        { nome: "Pajeú do Piauí" },
        { nome: "Palmeira do Piauí" },
        { nome: "Palmeirais" },
        { nome: "Paquetá" },
        { nome: "Parnaguá" },
        { nome: "Parnaíba" },
        { nome: "Passagem Franca do Piauí" },
        { nome: "Patos do Piauí" },
        { nome: "Pau d'Arco do Piauí" },
        { nome: "Paulistana" },
        { nome: "Pavussu" },
        { nome: "Pedro II" },
        { nome: "Pedro Laurentino" },
        { nome: "Picos" },
        { nome: "Pimenteiras" },
        { nome: "Pio IX" },
        { nome: "Piracuruca" },
        { nome: "Piripiri" },
        { nome: "Porto" },
        { nome: "Porto Alegre do Piauí" },
        { nome: "Prata do Piauí" },
        { nome: "Queimada Nova" },
        { nome: "Redenção do Gurguéia" },
        { nome: "Regeneração" },
        { nome: "Riacho Frio" },
        { nome: "Ribeira do Piauí" },
        { nome: "Ribeiro Gonçalves" },
        { nome: "Rio Grande do Piauí" },
        { nome: "Santa Cruz do Piauí" },
        { nome: "Santa Cruz dos Milagres" },
        { nome: "Santa Filomena" },
        { nome: "Santa Luz" },
        { nome: "Santa Rosa do Piauí" },
        { nome: "Santana do Piauí" },
        { nome: "Santo Antônio de Lisboa" },
        { nome: "Santo Antônio dos Milagres" },
        { nome: "Santo Inácio do Piauí" },
        { nome: "São Braz do Piauí" },
        { nome: "São Félix do Piauí" },
        { nome: "São Francisco de Assis do Piauí" },
        { nome: "São Francisco do Piauí" },
        { nome: "São Gonçalo do Gurguéia" },
        { nome: "São Gonçalo do Piauí" },
        { nome: "São João da Canabrava" },
        { nome: "São João da Fronteira" },
        { nome: "São João da Serra" },
        { nome: "São João da Varjota" },
        { nome: "São João do Arraial" },
        { nome: "São João do Piauí" },
        { nome: "São José do Divino" },
        { nome: "São José do Peixe" },
        { nome: "São José do Piauí" },
        { nome: "São Julião" },
        { nome: "São Lourenço do Piauí" },
        { nome: "São Luis do Piauí" },
        { nome: "São Miguel da Baixa Grande" },
        { nome: "São Miguel do Fidalgo" },
        { nome: "São Miguel do Tapuio" },
        { nome: "São Pedro do Piauí" },
        { nome: "São Raimundo Nonato" },
        { nome: "Sebastião Barros" },
        { nome: "Sebastião Leal" },
        { nome: "Sigefredo Pacheco" },
        { nome: "Simões" },
        { nome: "Simplício Mendes" },
        { nome: "Socorro do Piauí" },
        { nome: "Sussuapara" },
        { nome: "Tamboril do Piauí" },
        { nome: "Tanque do Piauí" },
        { nome: "Teresina" },
        { nome: "União" },
        { nome: "Uruçuí" },
        { nome: "Valença do Piauí" },
        { nome: "Várzea Branca" },
        { nome: "Várzea Grande" },
        { nome: "Vera Mendes" },
        { nome: "Vila Nova do Piauí" },
        { nome: "Wall Ferraz" }
    ],

    "PR": [
        { nome: "Abatiá" },
        { nome: "Adrianópolis" },
        { nome: "Agudos do Sul" },
        { nome: "Almirante Tamandaré" },
        { nome: "Altamira do Paraná" },
        { nome: "Alto Paraná" },
        { nome: "Alto Piquiri" },
        { nome: "Altônia" },
        { nome: "Alvorada do Sul" },
        { nome: "Amaporã" },
        { nome: "Ampére" },
        { nome: "Anahy" },
        { nome: "Andirá" },
        { nome: "Ângulo" },
        { nome: "Antonina" },
        { nome: "Antônio Olinto" },
        { nome: "Apucarana" },
        { nome: "Arapongas" },
        { nome: "Arapoti" },
        { nome: "Arapuã" },
        { nome: "Araruna" },
        { nome: "Araucária" },
        { nome: "Ariranha do Ivaí" },
        { nome: "Assaí" },
        { nome: "Assis Chateaubriand" },
        { nome: "Astorga" },
        { nome: "Atalaia" },
        { nome: "Balsa Nova" },
        { nome: "Bandeirantes" },
        { nome: "Barbosa Ferraz" },
        { nome: "Barra do Jacaré" },
        { nome: "Barracão" },
        { nome: "Bela Vista da Caroba" },
        { nome: "Bela Vista do Paraíso" },
        { nome: "Bituruna" },
        { nome: "Boa Esperança" },
        { nome: "Boa Esperança do Iguaçu" },
        { nome: "Boa Ventura de São Roque" },
        { nome: "Boa Vista da Aparecida" },
        { nome: "Bocaiúva do Sul" },
        { nome: "Bom Jesus do Sul" },
        { nome: "Bom Sucesso" },
        { nome: "Bom Sucesso do Sul" },
        { nome: "Borrazópolis" },
        { nome: "Braganey" },
        { nome: "Brasilândia do Sul" },
        { nome: "Cafeara" },
        { nome: "Cafelândia" },
        { nome: "Cafezal do Sul" },
        { nome: "Califórnia" },
        { nome: "Cambará" },
        { nome: "Cambé" },
        { nome: "Cambira" },
        { nome: "Campina da Lagoa" },
        { nome: "Campina do Simão" },
        { nome: "Campina Grande do Sul" },
        { nome: "Campo Bonito" },
        { nome: "Campo do Tenente" },
        { nome: "Campo Largo" },
        { nome: "Campo Magro" },
        { nome: "Campo Mourão" },
        { nome: "Cândido de Abreu" },
        { nome: "Candói" },
        { nome: "Cantagalo" },
        { nome: "Capanema" },
        { nome: "Capitão Leônidas Marques" },
        { nome: "Carambeí" },
        { nome: "Carlópolis" },
        { nome: "Cascavel" },
        { nome: "Castro" },
        { nome: "Catanduvas" },
        { nome: "Centenário do Sul" },
        { nome: "Cerro Azul" },
        { nome: "Céu Azul" },
        { nome: "Chopinzinho" },
        { nome: "Cianorte" },
        { nome: "Cidade Gaúcha" },
        { nome: "Clevelândia" },
        { nome: "Colombo" },
        { nome: "Colorado" },
        { nome: "Congonhinhas" },
        { nome: "Conselheiro Mairinck" },
        { nome: "Contenda" },
        { nome: "Corbélia" },
        { nome: "Cornélio Procópio" },
        { nome: "Coronel Domingos Soares" },
        { nome: "Coronel Vivida" },
        { nome: "Corumbataí do Sul" },
        { nome: "Cruz Machado" },
        { nome: "Cruzeiro do Iguaçu" },
        { nome: "Cruzeiro do Oeste" },
        { nome: "Cruzeiro do Sul" },
        { nome: "Cruzmaltina" },
        { nome: "Curitiba" },
        { nome: "Curiúva" },
        { nome: "Diamante d'Oeste" },
        { nome: "Diamante do Norte" },
        { nome: "Diamante do Sul" },
        { nome: "Dois Vizinhos" },
        { nome: "Douradina" },
        { nome: "Doutor Camargo" },
        { nome: "Doutor Ulysses" },
        { nome: "Enéas Marques" },
        { nome: "Engenheiro Beltrão" },
        { nome: "Entre Rios do Oeste" },
        { nome: "Esperança Nova" },
        { nome: "Espigão Alto do Iguaçu" },
        { nome: "Farol" },
        { nome: "Faxinal" },
        { nome: "Fazenda Rio Grande" },
        { nome: "Fênix" },
        { nome: "Fernandes Pinheiro" },
        { nome: "Figueira" },
        { nome: "Flor da Serra do Sul" },
        { nome: "Floraí" },
        { nome: "Floresta" },
        { nome: "Florestópolis" },
        { nome: "Flórida" },
        { nome: "Formosa do Oeste" },
        { nome: "Foz do Iguaçu" },
        { nome: "Foz do Jordão" },
        { nome: "Francisco Alves" },
        { nome: "Francisco Beltrão" },
        { nome: "General Carneiro" },
        { nome: "Godoy Moreira" },
        { nome: "Goioerê" },
        { nome: "Goioxim" },
        { nome: "Grandes Rios" },
        { nome: "Guaíra" },
        { nome: "Guairaçá" },
        { nome: "Guamiranga" },
        { nome: "Guapirama" },
        { nome: "Guaporema" },
        { nome: "Guaraci" },
        { nome: "Guaraniaçu" },
        { nome: "Guarapuava" },
        { nome: "Guaraqueçaba" },
        { nome: "Guaratuba" },
        { nome: "Honório Serpa" },
        { nome: "Ibaiti" },
        { nome: "Ibema" },
        { nome: "Ibiporã" },
        { nome: "Icaraíma" },
        { nome: "Iguaraçu" },
        { nome: "Iguatu" },
        { nome: "Imbaú" },
        { nome: "Imbituva" },
        { nome: "Inácio Martins" },
        { nome: "Inajá" },
        { nome: "Indianópolis" },
        { nome: "Ipiranga" },
        { nome: "Iporã" },
        { nome: "Iracema do Oeste" },
        { nome: "Irati" },
        { nome: "Iretama" },
        { nome: "Itaguajé" },
        { nome: "Itaipulândia" },
        { nome: "Itambaracá" },
        { nome: "Itambé" },
        { nome: "Itapejara d'Oeste" },
        { nome: "Itaperuçu" },
        { nome: "Itaúna do Sul" },
        { nome: "Ivaí" },
        { nome: "Ivaiporã" },
        { nome: "Ivaté" },
        { nome: "Ivatuba" },
        { nome: "Jaboti" },
        { nome: "Jacarezinho" },
        { nome: "Jaguapitã" },
        { nome: "Jaguariaíva" },
        { nome: "Jandaia do Sul" },
        { nome: "Janiópolis" },
        { nome: "Japira" },
        { nome: "Japurá" },
        { nome: "Jardim Alegre" },
        { nome: "Jardim Olinda" },
        { nome: "Jataizinho" },
        { nome: "Jesuítas" },
        { nome: "Joaquim Távora" },
        { nome: "Jundiaí do Sul" },
        { nome: "Juranda" },
        { nome: "Jussara" },
        { nome: "Kaloré" },
        { nome: "Lapa" },
        { nome: "Laranjal" },
        { nome: "Laranjeiras do Sul" },
        { nome: "Leópolis" },
        { nome: "Lidianópolis" },
        { nome: "Lindoeste" },
        { nome: "Loanda" },
        { nome: "Lobato" },
        { nome: "Londrina" },
        { nome: "Luiziana" },
        { nome: "Lunardelli" },
        { nome: "Lupionópolis" },
        { nome: "Mallet" },
        { nome: "Mamborê" },
        { nome: "Mandaguaçu" },
        { nome: "Mandaguari" },
        { nome: "Mandirituba" },
        { nome: "Manfrinópolis" },
        { nome: "Mangueirinha" },
        { nome: "Manoel Ribas" },
        { nome: "Marechal Cândido Rondon" },
        { nome: "Maria Helena" },
        { nome: "Marialva" },
        { nome: "Marilândia do Sul" },
        { nome: "Marilena" },
        { nome: "Mariluz" },
        { nome: "Maringá" },
        { nome: "Mariópolis" },
        { nome: "Maripá" },
        { nome: "Marmeleiro" },
        { nome: "Marquinho" },
        { nome: "Marumbi" },
        { nome: "Matelândia" },
        { nome: "Matinhos" },
        { nome: "Mato Rico" },
        { nome: "Mauá da Serra" },
        { nome: "Medianeira" },
        { nome: "Mercedes" },
        { nome: "Mirador" },
        { nome: "Miraselva" },
        { nome: "Missal" },
        { nome: "Moreira Sales" },
        { nome: "Morretes" },
        { nome: "Munhoz de Melo" },
        { nome: "Nossa Senhora das Graças" },
        { nome: "Nova Aliança do Ivaí" },
        { nome: "Nova América da Colina" },
        { nome: "Nova Aurora" },
        { nome: "Nova Cantu" },
        { nome: "Nova Esperança" },
        { nome: "Nova Esperança do Sudoeste" },
        { nome: "Nova Fátima" },
        { nome: "Nova Laranjeiras" },
        { nome: "Nova Londrina" },
        { nome: "Nova Olímpia" },
        { nome: "Nova Prata do Iguaçu" },
        { nome: "Nova Santa Bárbara" },
        { nome: "Nova Santa Rosa" },
        { nome: "Nova Tebas" },
        { nome: "Novo Itacolomi" },
        { nome: "Ortigueira" },
        { nome: "Ourizona" },
        { nome: "Ouro Verde do Oeste" },
        { nome: "Paiçandu" },
        { nome: "Palmas" },
        { nome: "Palmeira" },
        { nome: "Palmital" },
        { nome: "Palotina" },
        { nome: "Paraíso do Norte" },
        { nome: "Paranacity" },
        { nome: "Paranaguá" },
        { nome: "Paranapoema" },
        { nome: "Paranavaí" },
        { nome: "Pato Bragado" },
        { nome: "Pato Branco" },
        { nome: "Paula Freitas" },
        { nome: "Paulo Frontin" },
        { nome: "Peabiru" },
        { nome: "Perobal" },
        { nome: "Pérola" },
        { nome: "Pérola d'Oeste" },
        { nome: "Piên" },
        { nome: "Pinhais" },
        { nome: "Pinhal de São Bento" },
        { nome: "Pinhalão" },
        { nome: "Pinhão" },
        { nome: "Piraí do Sul" },
        { nome: "Piraquara" },
        { nome: "Pitanga" },
        { nome: "Pitangueiras" },
        { nome: "Planaltina do Paraná" },
        { nome: "Planalto" },
        { nome: "Ponta Grossa" },
        { nome: "Pontal do Paraná" },
        { nome: "Porecatu" },
        { nome: "Porto Amazonas" },
        { nome: "Porto Barreiro" },
        { nome: "Porto Rico" },
        { nome: "Porto Vitória" },
        { nome: "Prado Ferreira" },
        { nome: "Pranchita" },
        { nome: "Presidente Castelo Branco" },
        { nome: "Primeiro de Maio" },
        { nome: "Prudentópolis" },
        { nome: "Quarto Centenário" },
        { nome: "Quatiguá" },
        { nome: "Quatro Barras" },
        { nome: "Quatro Pontes" },
        { nome: "Quedas do Iguaçu" },
        { nome: "Querência do Norte" },
        { nome: "Quinta do Sol" },
        { nome: "Quitandinha" },
        { nome: "Ramilândia" },
        { nome: "Rancho Alegre" },
        { nome: "Rancho Alegre d'Oeste" },
        { nome: "Realeza" },
        { nome: "Rebouças" },
        { nome: "Renascença" },
        { nome: "Reserva" },
        { nome: "Reserva do Iguaçu" },
        { nome: "Ribeirão Claro" },
        { nome: "Ribeirão do Pinhal" },
        { nome: "Rio Azul" },
        { nome: "Rio Bom" },
        { nome: "Rio Bonito do Iguaçu" },
        { nome: "Rio Branco do Ivaí" },
        { nome: "Rio Branco do Sul" },
        { nome: "Rio Negro" },
        { nome: "Rolândia" },
        { nome: "Roncador" },
        { nome: "Rondon" },
        { nome: "Rosário do Ivaí" },
        { nome: "Sabáudia" },
        { nome: "Salgado Filho" },
        { nome: "Salto do Itararé" },
        { nome: "Salto do Lontra" },
        { nome: "Santa Amélia" },
        { nome: "Santa Cecília do Pavão" },
        { nome: "Santa Cruz Monte Castelo" },
        { nome: "Santa Fé" },
        { nome: "Santa Helena" },
        { nome: "Santa Inês" },
        { nome: "Santa Isabel do Ivaí" },
        { nome: "Santa Izabel do Oeste" },
        { nome: "Santa Lúcia" },
        { nome: "Santa Maria do Oeste" },
        { nome: "Santa Mariana" },
        { nome: "Santa Mônica" },
        { nome: "Santa Tereza do Oeste" },
        { nome: "Santa Terezinha de Itaipu" },
        { nome: "Santana do Itararé" },
        { nome: "Santo Antônio da Platina" },
        { nome: "Santo Antônio do Caiuá" },
        { nome: "Santo Antônio do Paraíso" },
        { nome: "Santo Antônio do Sudoeste" },
        { nome: "Santo Inácio" },
        { nome: "São Carlos do Ivaí" },
        { nome: "São Jerônimo da Serra" },
        { nome: "São João" },
        { nome: "São João do Caiuá" },
        { nome: "São João do Ivaí" },
        { nome: "São João do Triunfo" },
        { nome: "São Jorge d'Oeste" },
        { nome: "São Jorge do Ivaí" },
        { nome: "São Jorge do Patrocínio" },
        { nome: "São José da Boa Vista" },
        { nome: "São José das Palmeiras" },
        { nome: "São José dos Pinhais" },
        { nome: "São Manoel do Paraná" },
        { nome: "São Mateus do Sul" },
        { nome: "São Miguel do Iguaçu" },
        { nome: "São Pedro do Iguaçu" },
        { nome: "São Pedro do Ivaí" },
        { nome: "São Pedro do Paraná" },
        { nome: "São Sebastião da Amoreira" },
        { nome: "São Tomé" },
        { nome: "Sapopema" },
        { nome: "Sarandi" },
        { nome: "Saudade do Iguaçu" },
        { nome: "Sengés" },
        { nome: "Serranópolis do Iguaçu" },
        { nome: "Sertaneja" },
        { nome: "Sertanópolis" },
        { nome: "Siqueira Campos" },
        { nome: "Sulina" },
        { nome: "Tamarana" },
        { nome: "Tamboara" },
        { nome: "Tapejara" },
        { nome: "Tapira" },
        { nome: "Teixeira Soares" },
        { nome: "Telêmaco Borba" },
        { nome: "Terra Boa" },
        { nome: "Terra Rica" },
        { nome: "Terra Roxa" },
        { nome: "Tibagi" },
        { nome: "Tijucas do Sul" },
        { nome: "Toledo" },
        { nome: "Tomazina" },
        { nome: "Três Barras do Paraná" },
        { nome: "Tunas do Paraná" },
        { nome: "Tuneiras do Oeste" },
        { nome: "Tupãssi" },
        { nome: "Turvo" },
        { nome: "Ubiratã" },
        { nome: "Umuarama" },
        { nome: "União da Vitória" },
        { nome: "Uniflor" },
        { nome: "Uraí" },
        { nome: "Ventania" },
        { nome: "Vera Cruz do Oeste" },
        { nome: "Verê" },
        { nome: "Vila Alta" },
        { nome: "Virmond" },
        { nome: "Vitorino" },
        { nome: "Wenceslau Braz" },
        { nome: "Xambrê" }
    ],

    "RJ": [
        { nome: "Angra dos Reis" },
        { nome: "Aperibé" },
        { nome: "Araruama" },
        { nome: "Areal" },
        { nome: "Armação de Búzios" },
        { nome: "Arraial do Cabo" },
        { nome: "Barra do Piraí" },
        { nome: "Barra Mansa" },
        { nome: "Belford Roxo" },
        { nome: "Bom Jardim" },
        { nome: "Bom Jesus do Itabapoana" },
        { nome: "Cabo Frio" },
        { nome: "Cachoeiras de Macacu" },
        { nome: "Cambuci" },
        { nome: "Campos dos Goytacazes" },
        { nome: "Cantagalo" },
        { nome: "Carapebus" },
        { nome: "Cardoso Moreira" },
        { nome: "Carmo" },
        { nome: "Casimiro de Abreu" },
        { nome: "Comendador Levy Gasparian" },
        { nome: "Conceição de Macabu" },
        { nome: "Cordeiro" },
        { nome: "Duas Barras" },
        { nome: "Duque de Caxias" },
        { nome: "Engenheiro Paulo de Frontin" },
        { nome: "Guapimirim" },
        { nome: "Iguaba Grande" },
        { nome: "Itaboraí" },
        { nome: "Itaguaí" },
        { nome: "Italva" },
        { nome: "Itaocara" },
        { nome: "Itaperuna" },
        { nome: "Itatiaia" },
        { nome: "Japeri" },
        { nome: "Laje do Muriaé" },
        { nome: "Macaé" },
        { nome: "Macuco" },
        { nome: "Magé" },
        { nome: "Mangaratiba" },
        { nome: "Maricá" },
        { nome: "Mendes" },
        { nome: "Mesquita" },
        { nome: "Miguel Pereira" },
        { nome: "Miracema" },
        { nome: "Natividade" },
        { nome: "Nilópolis" },
        { nome: "Niterói" },
        { nome: "Nova Friburgo" },
        { nome: "Nova Iguaçu" },
        { nome: "Paracambi" },
        { nome: "Paraíba do Sul" },
        { nome: "Parati" },
        { nome: "Paty do Alferes" },
        { nome: "Petrópolis" },
        { nome: "Pinheiral" },
        { nome: "Piraí" },
        { nome: "Porciúncula" },
        { nome: "Porto Real" },
        { nome: "Quatis" },
        { nome: "Queimados" },
        { nome: "Quissamã" },
        { nome: "Resende" },
        { nome: "Rio Bonito" },
        { nome: "Rio Claro" },
        { nome: "Rio das Flores" },
        { nome: "Rio das Ostras" },
        { nome: "Rio de Janeiro" },
        { nome: "Santa Maria Madalena" },
        { nome: "Santo Antônio de Pádua" },
        { nome: "São Fidélis" },
        { nome: "São Francisco de Itabapoana" },
        { nome: "São Gonçalo" },
        { nome: "São João da Barra" },
        { nome: "São João de Meriti" },
        { nome: "São José de Ubá" },
        { nome: "São José do Vale do Rio Preto" },
        { nome: "São Pedro da Aldeia" },
        { nome: "São Sebastião do Alto" },
        { nome: "Sapucaia" },
        { nome: "Saquarema" },
        { nome: "Seropédica" },
        { nome: "Silva Jardim" },
        { nome: "Sumidouro" },
        { nome: "Tanguá" },
        { nome: "Teresópolis" },
        { nome: "Trajano de Morais" },
        { nome: "Três Rios" },
        { nome: "Valença" },
        { nome: "Varre-Sai" },
        { nome: "Vassouras" },
        { nome: "Volta Redonda" }
    ],

    "RN": [
        { nome: "Acari" },
        { nome: "Açu" },
        { nome: "Afonso Bezerra" },
        { nome: "Água Nova" },
        { nome: "Alexandria" },
        { nome: "Almino Afonso" },
        { nome: "Alto do Rodrigues" },
        { nome: "Angicos" },
        { nome: "Antônio Martins" },
        { nome: "Apodi" },
        { nome: "Areia Branca" },
        { nome: "Arês" },
        { nome: "Augusto Severo" },
        { nome: "Baía Formosa" },
        { nome: "Baraúna" },
        { nome: "Barcelona" },
        { nome: "Bento Fernandes" },
        { nome: "Bodó" },
        { nome: "Bom Jesus" },
        { nome: "Brejinho" },
        { nome: "Caiçara do Norte" },
        { nome: "Caiçara do Rio do Vento" },
        { nome: "Caicó" },
        { nome: "Campo Redondo" },
        { nome: "Canguaretama" },
        { nome: "Caraúbas" },
        { nome: "Carnaúba dos Dantas" },
        { nome: "Carnaubais" },
        { nome: "Ceará-Mirim" },
        { nome: "Cerro Corá" },
        { nome: "Coronel Ezequiel" },
        { nome: "Coronel João Pessoa" },
        { nome: "Cruzeta" },
        { nome: "Currais Novos" },
        { nome: "Doutor Severiano" },
        { nome: "Encanto" },
        { nome: "Equador" },
        { nome: "Espírito Santo" },
        { nome: "Extremoz" },
        { nome: "Felipe Guerra" },
        { nome: "Fernando Pedroza" },
        { nome: "Florânia" },
        { nome: "Francisco Dantas" },
        { nome: "Frutuoso Gomes" },
        { nome: "Galinhos" },
        { nome: "Goianinha" },
        { nome: "Governador Dix-Sept Rosado" },
        { nome: "Grossos" },
        { nome: "Guamaré" },
        { nome: "Ielmo Marinho" },
        { nome: "Ipanguaçu" },
        { nome: "Ipueira" },
        { nome: "Itajá" },
        { nome: "Itaú" },
        { nome: "Jaçanã" },
        { nome: "Jandaíra" },
        { nome: "Janduís" },
        { nome: "Januário Cicco" },
        { nome: "Japi" },
        { nome: "Jardim de Angicos" },
        { nome: "Jardim de Piranhas" },
        { nome: "Jardim do Seridó" },
        { nome: "João Câmara" },
        { nome: "João Dias" },
        { nome: "José da Penha" },
        { nome: "Jucurutu" },
        { nome: "Jundiá" },
        { nome: "Lagoa d'Anta" },
        { nome: "Lagoa de Pedras" },
        { nome: "Lagoa de Velhos" },
        { nome: "Lagoa Nova" },
        { nome: "Lagoa Salgada" },
        { nome: "Lajes" },
        { nome: "Lajes Pintadas" },
        { nome: "Lucrécia" },
        { nome: "Luís Gomes" },
        { nome: "Macaíba" },
        { nome: "Macau" },
        { nome: "Major Sales" },
        { nome: "Marcelino Vieira" },
        { nome: "Martins" },
        { nome: "Maxaranguape" },
        { nome: "Messias Targino" },
        { nome: "Montanhas" },
        { nome: "Monte Alegre" },
        { nome: "Monte das Gameleiras" },
        { nome: "Mossoró" },
        { nome: "Natal" },
        { nome: "Nísia Floresta" },
        { nome: "Nova Cruz" },
        { nome: "Olho-d'Água do Borges" },
        { nome: "Ouro Branco" },
        { nome: "Paraná" },
        { nome: "Paraú" },
        { nome: "Parazinho" },
        { nome: "Parelhas" },
        { nome: "Parnamirim" },
        { nome: "Passa e Fica" },
        { nome: "Passagem" },
        { nome: "Patu" },
        { nome: "Pau dos Ferros" },
        { nome: "Pedra Grande" },
        { nome: "Pedra Preta" },
        { nome: "Pedro Avelino" },
        { nome: "Pedro Velho" },
        { nome: "Pendências" },
        { nome: "Pilões" },
        { nome: "Poço Branco" },
        { nome: "Portalegre" },
        { nome: "Porto do Mangue" },
        { nome: "Presidente Juscelino" },
        { nome: "Pureza" },
        { nome: "Rafael Fernandes" },
        { nome: "Rafael Godeiro" },
        { nome: "Riacho da Cruz" },
        { nome: "Riacho de Santana" },
        { nome: "Riachuelo" },
        { nome: "Rio do Fogo" },
        { nome: "Rodolfo Fernandes" },
        { nome: "Ruy Barbosa" },
        { nome: "Santa Cruz" },
        { nome: "Santa Maria" },
        { nome: "Santana do Matos" },
        { nome: "Santana do Seridó" },
        { nome: "Santo Antônio" },
        { nome: "São Bento do Norte" },
        { nome: "São Bento do Trairí" },
        { nome: "São Fernando" },
        { nome: "São Francisco do Oeste" },
        { nome: "São Gonçalo do Amarante" },
        { nome: "São João do Sabugi" },
        { nome: "São José de Mipibu" },
        { nome: "São José do Campestre" },
        { nome: "São José do Seridó" },
        { nome: "São Miguel" },
        { nome: "São Miguel de Touros" },
        { nome: "São Paulo do Potengi" },
        { nome: "São Pedro" },
        { nome: "São Rafael" },
        { nome: "São Tomé" },
        { nome: "São Vicente" },
        { nome: "Senador Elói de Souza" },
        { nome: "Senador Georgino Avelino" },
        { nome: "Serra de São Bento" },
        { nome: "Serra do Mel" },
        { nome: "Serra Negra do Norte" },
        { nome: "Serrinha" },
        { nome: "Serrinha dos Pintos" },
        { nome: "Severiano Melo" },
        { nome: "Sítio Novo" },
        { nome: "Taboleiro Grande" },
        { nome: "Taipu" },
        { nome: "Tangará" },
        { nome: "Tenente Ananias" },
        { nome: "Tenente Laurentino Cruz" },
        { nome: "Tibau" },
        { nome: "Tibau do Sul" },
        { nome: "Timbaúba dos Batistas" },
        { nome: "Touros" },
        { nome: "Triunfo Potiguar" },
        { nome: "Umarizal" },
        { nome: "Upanema" },
        { nome: "Várzea" },
        { nome: "Venha-Ver" },
        { nome: "Vera Cruz" },
        { nome: "Viçosa" },
        { nome: "Vila Flor" }
    ],

    "RO": [
        { nome: "Alta Floresta d'Oeste" },
        { nome: "Alto Alegre do Parecis" },
        { nome: "Alto Paraíso" },
        { nome: "Alvorada d'Oeste" },
        { nome: "Ariquemes" },
        { nome: "Buritis" },
        { nome: "Cabixi" },
        { nome: "Cacaulândia" },
        { nome: "Cacoal" },
        { nome: "Campo Novo de Rondônia" },
        { nome: "Candeias do Jamari" },
        { nome: "Castanheiras" },
        { nome: "Cerejeiras" },
        { nome: "Chupinguaia" },
        { nome: "Colorado do Oeste" },
        { nome: "Corumbiara" },
        { nome: "Costa Marques" },
        { nome: "Cujubim" },
        { nome: "Espigão d'Oeste" },
        { nome: "Governador Jorge Teixeira" },
        { nome: "Guajará-Mirim" },
        { nome: "Itapuã do Oeste" },
        { nome: "Jaru" },
        { nome: "Ji-Paraná" },
        { nome: "Machadinho d'Oeste" },
        { nome: "Ministro Andreazza" },
        { nome: "Mirante da Serra" },
        { nome: "Monte Negro" },
        { nome: "Nova Brasilândia d'Oeste" },
        { nome: "Nova Mamoré" },
        { nome: "Nova União" },
        { nome: "Novo Horizonte do Oeste" },
        { nome: "Ouro Preto do Oeste" },
        { nome: "Parecis" },
        { nome: "Pimenta Bueno" },
        { nome: "Pimenteiras do Oeste" },
        { nome: "Porto Velho" },
        { nome: "Presidente Médici" },
        { nome: "Primavera de Rondônia" },
        { nome: "Rio Crespo" },
        { nome: "Rolim de Moura" },
        { nome: "Santa Luzia d'Oeste" },
        { nome: "São Felipe d'Oeste" },
        { nome: "São Francisco do Guaporé" },
        { nome: "São Miguel do Guaporé" },
        { nome: "Seringueiras" },
        { nome: "Teixeirópolis" },
        { nome: "Theobroma" },
        { nome: "Urupá" },
        { nome: "Vale do Anari" },
        { nome: "Vale do Paraíso" },
        { nome: "Vilhena" }
    ],

    "RR": [
        { nome: "Alto Alegre" },
        { nome: "Amajari" },
        { nome: "Boa Vista" },
        { nome: "Bonfim" },
        { nome: "Cantá" },
        { nome: "Caracaraí" },
        { nome: "Caroebe" },
        { nome: "Iracema" },
        { nome: "Mucajaí" },
        { nome: "Normandia" },
        { nome: "Pacaraima" },
        { nome: "Rorainópolis" },
        { nome: "São João da Baliza" },
        { nome: "São Luiz" },
        { nome: "Uiramutã" }
    ],

    "RS": [
        { nome: "Aceguá" },
        { nome: "Água Santa" },
        { nome: "Agudo" },
        { nome: "Ajuricaba" },
        { nome: "Alecrim" },
        { nome: "Alegrete" },
        { nome: "Alegria" },
        { nome: "Almirante Tamandaré do Sul" },
        { nome: "Alpestre" },
        { nome: "Alto Alegre" },
        { nome: "Alto Feliz" },
        { nome: "Alvorada" },
        { nome: "Amaral Ferrador" },
        { nome: "Ametista do Sul" },
        { nome: "André da Rocha" },
        { nome: "Anta Gorda" },
        { nome: "Antônio Prado" },
        { nome: "Arambaré" },
        { nome: "Araricá" },
        { nome: "Aratiba" },
        { nome: "Arroio do Meio" },
        { nome: "Arroio do Padre" },
        { nome: "Arroio do Sal" },
        { nome: "Arroio do Tigre" },
        { nome: "Arroio dos Ratos" },
        { nome: "Arroio Grande" },
        { nome: "Arvorezinha" },
        { nome: "Augusto Pestana" },
        { nome: "Áurea" },
        { nome: "Bagé" },
        { nome: "Balneário Pinhal" },
        { nome: "Barão" },
        { nome: "Barão de Cotegipe" },
        { nome: "Barão do Triunfo" },
        { nome: "Barra do Guarita" },
        { nome: "Barra do Quaraí" },
        { nome: "Barra do Ribeiro" },
        { nome: "Barra do Rio Azul" },
        { nome: "Barra Funda" },
        { nome: "Barracão" },
        { nome: "Barros Cassal" },
        { nome: "Benjamin Constan do Sul" },
        { nome: "Bento Gonçalves" },
        { nome: "Boa Vista das Missões" },
        { nome: "Boa Vista do Buricá" },
        { nome: "Boa Vista do Cadeado" },
        { nome: "Boa Vista do Incra" },
        { nome: "Boa Vista do Sul" },
        { nome: "Bom Jesus" },
        { nome: "Bom Princípio" },
        { nome: "Bom Progresso" },
        { nome: "Bom Retiro do Sul" },
        { nome: "Boqueirão do Leão" },
        { nome: "Bossoroca" },
        { nome: "Bozano" },
        { nome: "Braga" },
        { nome: "Brochier" },
        { nome: "Butiá" },
        { nome: "Caçapava do Sul" },
        { nome: "Cacequi" },
        { nome: "Cachoeira do Sul" },
        { nome: "Cachoeirinha" },
        { nome: "Cacique Doble" },
        { nome: "Caibaté" },
        { nome: "Caiçara" },
        { nome: "Camaquã" },
        { nome: "Camargo" },
        { nome: "Cambará do Sul" },
        { nome: "Campestre da Serra" },
        { nome: "Campina das Missões" },
        { nome: "Campinas do Sul" },
        { nome: "Campo Bom" },
        { nome: "Campo Novo" },
        { nome: "Campos Borges" },
        { nome: "Candelária" },
        { nome: "Cândido Godói" },
        { nome: "Candiota" },
        { nome: "Canela" },
        { nome: "Canguçu" },
        { nome: "Canoas" },
        { nome: "Canudos do Vale" },
        { nome: "Capão Bonito do Sul" },
        { nome: "Capão da Canoa" },
        { nome: "Capão do Cipó" },
        { nome: "Capão do Leão" },
        { nome: "Capela de Santana" },
        { nome: "Capitão" },
        { nome: "Capivari do Sul" },
        { nome: "Caraá" },
        { nome: "Carazinho" },
        { nome: "Carlos Barbosa" },
        { nome: "Carlos Gomes" },
        { nome: "Casca" },
        { nome: "Caseiros" },
        { nome: "Catuípe" },
        { nome: "Caxias do Sul" },
        { nome: "Centenário" },
        { nome: "Cerrito" },
        { nome: "Cerro Branco" },
        { nome: "Cerro Grande" },
        { nome: "Cerro Grande do Sul" },
        { nome: "Cerro Largo" },
        { nome: "Chapada" },
        { nome: "Charqueadas" },
        { nome: "Charrua" },
        { nome: "Chiapeta" },
        { nome: "Chuí" },
        { nome: "Chuvisca" },
        { nome: "Cidreira" },
        { nome: "Ciríaco" },
        { nome: "Colinas" },
        { nome: "Colorado" },
        { nome: "Condor" },
        { nome: "Constantina" },
        { nome: "Coqueiro Baixo" },
        { nome: "Coqueiros do Sul" },
        { nome: "Coronel Barros" },
        { nome: "Coronel Bicaco" },
        { nome: "Coronel Pilar" },
        { nome: "Cotiporã" },
        { nome: "Coxilha" },
        { nome: "Crissiumal" },
        { nome: "Cristal" },
        { nome: "Cristal do Sul" },
        { nome: "Cruz Alta" },
        { nome: "Cruzaltense" },
        { nome: "Cruzeiro do Sul" },
        { nome: "David Canabarro" },
        { nome: "Derrubadas" },
        { nome: "Dezesseis de Novembro" },
        { nome: "Dilermando de Aguiar" },
        { nome: "Dois Irmãos" },
        { nome: "Dois Irmãos das Missões" },
        { nome: "Dois Lajeados" },
        { nome: "Dom Feliciano" },
        { nome: "Dom Pedrito" },
        { nome: "Dom Pedro de Alcântara" },
        { nome: "Dona Francisca" },
        { nome: "Doutor Maurício Cardoso" },
        { nome: "Doutor Ricardo" },
        { nome: "Eldorado do Sul" },
        { nome: "Encantado" },
        { nome: "Encruzilhada do Sul" },
        { nome: "Engenho Velho" },
        { nome: "Entre Rios do Sul" },
        { nome: "Entre-Ijuís" },
        { nome: "Erebango" },
        { nome: "Erechim" },
        { nome: "Ernestina" },
        { nome: "Erval Grande" },
        { nome: "Erval Seco" },
        { nome: "Esmeralda" },
        { nome: "Esperança do Sul" },
        { nome: "Espumoso" },
        { nome: "Estação" },
        { nome: "Estância Velha" },
        { nome: "Esteio" },
        { nome: "Estrela" },
        { nome: "Estrela Velha" },
        { nome: "Eugênio de Castro" },
        { nome: "Fagundes Varela" },
        { nome: "Farroupilha" },
        { nome: "Faxinal do Soturno" },
        { nome: "Faxinalzinho" },
        { nome: "Fazenda Vilanova" },
        { nome: "Feliz" },
        { nome: "Flores da Cunha" },
        { nome: "Floriano Peixoto" },
        { nome: "Fontoura Xavier" },
        { nome: "Formigueiro" },
        { nome: "Forquetinha" },
        { nome: "Fortaleza dos Valos" },
        { nome: "Frederico Westphalen" },
        { nome: "Garibaldi" },
        { nome: "Garruchos" },
        { nome: "Gaurama" },
        { nome: "General Câmara" },
        { nome: "Gentil" },
        { nome: "Getúlio Vargas" },
        { nome: "Giruá" },
        { nome: "Glorinha" },
        { nome: "Gramado" },
        { nome: "Gramado dos Loureiros" },
        { nome: "Gramado Xavier" },
        { nome: "Gravataí" },
        { nome: "Guabiju" },
        { nome: "Guaíba" },
        { nome: "Guaporé" },
        { nome: "Guarani das Missões" },
        { nome: "Harmonia" },
        { nome: "Herval" },
        { nome: "Herveiras" },
        { nome: "Horizontina" },
        { nome: "Hulha Negra" },
        { nome: "Humaitá" },
        { nome: "Ibarama" },
        { nome: "Ibiaçá" },
        { nome: "Ibiraiaras" },
        { nome: "Ibirapuitã" },
        { nome: "Ibirubá" },
        { nome: "Igrejinha" },
        { nome: "Ijuí" },
        { nome: "Ilópolis" },
        { nome: "Imbé" },
        { nome: "Imigrante" },
        { nome: "Independência" },
        { nome: "Inhacorá" },
        { nome: "Ipê" },
        { nome: "Ipiranga do Sul" },
        { nome: "Iraí" },
        { nome: "Itaara" },
        { nome: "Itacurubi" },
        { nome: "Itapuca" },
        { nome: "Itaqui" },
        { nome: "Itati" },
        { nome: "Itatiba do Sul" },
        { nome: "Ivorá" },
        { nome: "Ivoti" },
        { nome: "Jaboticaba" },
        { nome: "Jacuizinho" },
        { nome: "Jacutinga" },
        { nome: "Jaguarão" },
        { nome: "Jaguari" },
        { nome: "Jaquirana" },
        { nome: "Jari" },
        { nome: "Jóia" },
        { nome: "Júlio de Castilhos" },
        { nome: "Lagoa Bonita do Sul" },
        { nome: "Lagoa dos Três Cantos" },
        { nome: "Lagoa Vermelha" },
        { nome: "Lagoão" },
        { nome: "Lajeado" },
        { nome: "Lajeado do Bugre" },
        { nome: "Lavras do Sul" },
        { nome: "Liberato Salzano" },
        { nome: "Lindolfo Collor" },
        { nome: "Linha Nova" },
        { nome: "Maçambara" },
        { nome: "Machadinho" },
        { nome: "Mampituba" },
        { nome: "Manoel Viana" },
        { nome: "Maquiné" },
        { nome: "Maratá" },
        { nome: "Marau" },
        { nome: "Marcelino Ramos" },
        { nome: "Mariana Pimentel" },
        { nome: "Mariano Moro" },
        { nome: "Marques de Souza" },
        { nome: "Mata" },
        { nome: "Mato Castelhano" },
        { nome: "Mato Leitão" },
        { nome: "Mato Queimado" },
        { nome: "Maximiliano de Almeida" },
        { nome: "Minas do Leão" },
        { nome: "Miraguaí" },
        { nome: "Montauri" },
        { nome: "Monte Alegre dos Campos" },
        { nome: "Monte Belo do Sul" },
        { nome: "Montenegro" },
        { nome: "Mormaço" },
        { nome: "Morrinhos do Sul" },
        { nome: "Morro Redondo" },
        { nome: "Morro Reuter" },
        { nome: "Mostardas" },
        { nome: "Muçum" },
        { nome: "Muitos Capões" },
        { nome: "Muliterno" },
        { nome: "Não-Me-Toque" },
        { nome: "Nicolau Vergueiro" },
        { nome: "Nonoai" },
        { nome: "Nova Alvorada" },
        { nome: "Nova Araçá" },
        { nome: "Nova Bassano" },
        { nome: "Nova Boa Vista" },
        { nome: "Nova Bréscia" },
        { nome: "Nova Candelária" },
        { nome: "Nova Esperança do Sul" },
        { nome: "Nova Hartz" },
        { nome: "Nova Pádua" },
        { nome: "Nova Palma" },
        { nome: "Nova Petrópolis" },
        { nome: "Nova Prata" },
        { nome: "Nova Ramada" },
        { nome: "Nova Roma do Sul" },
        { nome: "Nova Santa Rita" },
        { nome: "Novo Barreiro" },
        { nome: "Novo Cabrais" },
        { nome: "Novo Hamburgo" },
        { nome: "Novo Machado" },
        { nome: "Novo Tiradentes" },
        { nome: "Novo Xingu" },
        { nome: "Osório" },
        { nome: "Paim Filho" },
        { nome: "Palmares do Sul" },
        { nome: "Palmeira das Missões" },
        { nome: "Palmitinho" },
        { nome: "Panambi" },
        { nome: "Pântano Grande" },
        { nome: "Paraí" },
        { nome: "Paraíso do Sul" },
        { nome: "Pareci Novo" },
        { nome: "Parobé" },
        { nome: "Passa Sete" },
        { nome: "Passo do Sobrado" },
        { nome: "Passo Fundo" },
        { nome: "Paulo Bento" },
        { nome: "Paverama" },
        { nome: "Pedras Altas" },
        { nome: "Pedro Osório" },
        { nome: "Pejuçara" },
        { nome: "Pelotas" },
        { nome: "Picada Café" },
        { nome: "Pinhal" },
        { nome: "Pinhal da Serra" },
        { nome: "Pinhal Grande" },
        { nome: "Pinheirinho do Vale" },
        { nome: "Pinheiro Machado" },
        { nome: "Pirapó" },
        { nome: "Piratini" },
        { nome: "Planalto" },
        { nome: "Poço das Antas" },
        { nome: "Pontão" },
        { nome: "Ponte Preta" },
        { nome: "Portão" },
        { nome: "Porto Alegre" },
        { nome: "Porto Lucena" },
        { nome: "Porto Mauá" },
        { nome: "Porto Vera Cruz" },
        { nome: "Porto Xavier" },
        { nome: "Pouso Novo" },
        { nome: "Presidente Lucena" },
        { nome: "Progresso" },
        { nome: "Protásio Alves" },
        { nome: "Putinga" },
        { nome: "Quaraí" },
        { nome: "Quatro Irmãos" },
        { nome: "Quevedos" },
        { nome: "Quinze de Novembro" },
        { nome: "Redentora" },
        { nome: "Relvado" },
        { nome: "Restinga Seca" },
        { nome: "Rio dos Índios" },
        { nome: "Rio Grande" },
        { nome: "Rio Pardo" },
        { nome: "Riozinho" },
        { nome: "Roca Sales" },
        { nome: "Rodeio Bonito" },
        { nome: "Rolador" },
        { nome: "Rolante" },
        { nome: "Ronda Alta" },
        { nome: "Rondinha" },
        { nome: "Roque Gonzales" },
        { nome: "Rosário do Sul" },
        { nome: "Sagrada Família" },
        { nome: "Saldanha Marinho" },
        { nome: "Salto do Jacuí" },
        { nome: "Salvador das Missões" },
        { nome: "Salvador do Sul" },
        { nome: "Sananduva" },
        { nome: "Santa Bárbara do Sul" },
        { nome: "Santa Cecília do Sul" },
        { nome: "Santa Clara do Sul" },
        { nome: "Santa Cruz do Sul" },
        { nome: "Santa Margarida do Sul" },
        { nome: "Santa Maria" },
        { nome: "Santa Maria do Herval" },
        { nome: "Santa Rosa" },
        { nome: "Santa Tereza" },
        { nome: "Santa Vitória do Palmar" },
        { nome: "Santana da Boa Vista" },
        { nome: "Santana do Livramento" },
        { nome: "Santiago" },
        { nome: "Santo Ângelo" },
        { nome: "Santo Antônio da Patrulha" },
        { nome: "Santo Antônio das Missões" },
        { nome: "Santo Antônio do Palma" },
        { nome: "Santo Antônio do Planalto" },
        { nome: "Santo Augusto" },
        { nome: "Santo Cristo" },
        { nome: "Santo Expedito do Sul" },
        { nome: "São Borja" },
        { nome: "São Domingos do Sul" },
        { nome: "São Francisco de Assis" },
        { nome: "São Francisco de Paula" },
        { nome: "São Gabriel" },
        { nome: "São Jerônimo" },
        { nome: "São João da Urtiga" },
        { nome: "São João do Polêsine" },
        { nome: "São Jorge" },
        { nome: "São José das Missões" },
        { nome: "São José do Herval" },
        { nome: "São José do Hortêncio" },
        { nome: "São José do Inhacorá" },
        { nome: "São José do Norte" },
        { nome: "São José do Ouro" },
        { nome: "São José do Sul" },
        { nome: "São José dos Ausentes" },
        { nome: "São Leopoldo" },
        { nome: "São Lourenço do Sul" },
        { nome: "São Luiz Gonzaga" },
        { nome: "São Marcos" },
        { nome: "São Martinho" },
        { nome: "São Martinho da Serra" },
        { nome: "São Miguel das Missões" },
        { nome: "São Nicolau" },
        { nome: "São Paulo das Missões" },
        { nome: "São Pedro da Serra" },
        { nome: "São Pedro das Missões" },
        { nome: "São Pedro do Butiá" },
        { nome: "São Pedro do Sul" },
        { nome: "São Sebastião do Caí" },
        { nome: "São Sepé" },
        { nome: "São Valentim" },
        { nome: "São Valentim do Sul" },
        { nome: "São Valério do Sul" },
        { nome: "São Vendelino" },
        { nome: "São Vicente do Sul" },
        { nome: "Sapiranga" },
        { nome: "Sapucaia do Sul" },
        { nome: "Sarandi" },
        { nome: "Seberi" },
        { nome: "Sede Nova" },
        { nome: "Segredo" },
        { nome: "Selbach" },
        { nome: "Senador Salgado Filho" },
        { nome: "Sentinela do Sul" },
        { nome: "Serafina Corrêa" },
        { nome: "Sério" },
        { nome: "Sertão" },
        { nome: "Sertão Santana" },
        { nome: "Sete de Setembro" },
        { nome: "Severiano de Almeida" },
        { nome: "Silveira Martins" },
        { nome: "Sinimbu" },
        { nome: "Sobradinho" },
        { nome: "Soledade" },
        { nome: "Tabaí" },
        { nome: "Tapejara" },
        { nome: "Tapera" },
        { nome: "Tapes" },
        { nome: "Taquara" },
        { nome: "Taquari" },
        { nome: "Taquaruçu do Sul" },
        { nome: "Tavares" },
        { nome: "Tenente Portela" },
        { nome: "Terra de Areia" },
        { nome: "Teutônia" },
        { nome: "Tio Hugo" },
        { nome: "Tiradentes do Sul" },
        { nome: "Toropi" },
        { nome: "Torres" },
        { nome: "Tramandaí" },
        { nome: "Travesseiro" },
        { nome: "Três Arroios" },
        { nome: "Três Cachoeiras" },
        { nome: "Três Coroas" },
        { nome: "Três de Maio" },
        { nome: "Três Forquilhas" },
        { nome: "Três Palmeiras" },
        { nome: "Três Passos" },
        { nome: "Trindade do Sul" },
        { nome: "Triunfo" },
        { nome: "Tucunduva" },
        { nome: "Tunas" },
        { nome: "Tupanci do Sul" },
        { nome: "Tupanciretã" },
        { nome: "Tupandi" },
        { nome: "Tuparendi" },
        { nome: "Turuçu" },
        { nome: "Ubiretama" },
        { nome: "União da Serra" },
        { nome: "Unistalda" },
        { nome: "Uruguaiana" },
        { nome: "Vacaria" },
        { nome: "Vale do Sol" },
        { nome: "Vale Real" },
        { nome: "Vale Verde" },
        { nome: "Vanini" },
        { nome: "Venâncio Aires" },
        { nome: "Vera Cruz" },
        { nome: "Veranópolis" },
        { nome: "Vespasiano Correa" },
        { nome: "Viadutos" },
        { nome: "Viamão" },
        { nome: "Vicente Dutra" },
        { nome: "Victor Graeff" },
        { nome: "Vila Flores" },
        { nome: "Vila Lângaro" },
        { nome: "Vila Maria" },
        { nome: "Vila Nova do Sul" },
        { nome: "Vista Alegre" },
        { nome: "Vista Alegre do Prata" },
        { nome: "Vista Gaúcha" },
        { nome: "Vitória das Missões" },
        { nome: "Westfália" },
        { nome: "Xangri-lá" }
    ],

    "SC": [
        { nome: "Abdon Batista" },
        { nome: "Abelardo Luz" },
        { nome: "Agrolândia" },
        { nome: "Agronômica" },
        { nome: "Água Doce" },
        { nome: "Águas de Chapecó" },
        { nome: "Águas Frias" },
        { nome: "Águas Mornas" },
        { nome: "Alfredo Wagner" },
        { nome: "Alto Bela Vista" },
        { nome: "Anchieta" },
        { nome: "Angelina" },
        { nome: "Anita Garibaldi" },
        { nome: "Anitápolis" },
        { nome: "Antônio Carlos" },
        { nome: "Apiúna" },
        { nome: "Arabutã" },
        { nome: "Araquari" },
        { nome: "Araranguá" },
        { nome: "Armazém" },
        { nome: "Arroio Trinta" },
        { nome: "Arvoredo" },
        { nome: "Ascurra" },
        { nome: "Atalanta" },
        { nome: "Aurora" },
        { nome: "Balneário Arroio do Silva" },
        { nome: "Balneário Barra do Sul" },
        { nome: "Balneário Camboriú" },
        { nome: "Balneário Gaivota" },
        { nome: "Bandeirante" },
        { nome: "Barra Bonita" },
        { nome: "Barra Velha" },
        { nome: "Bela Vista do Toldo" },
        { nome: "Belmonte" },
        { nome: "Benedito Novo" },
        { nome: "Biguaçu" },
        { nome: "Blumenau" },
        { nome: "Bocaina do Sul" },
        { nome: "Bom Jardim da Serra" },
        { nome: "Bom Jesus" },
        { nome: "Bom Jesus do Oeste" },
        { nome: "Bom Retiro" },
        { nome: "Bombinhas" },
        { nome: "Botuverá" },
        { nome: "Braço do Norte" },
        { nome: "Braço do Trombudo" },
        { nome: "Brunópolis" },
        { nome: "Brusque" },
        { nome: "Caçador" },
        { nome: "Caibi" },
        { nome: "Calmon" },
        { nome: "Camboriú" },
        { nome: "Campo Alegre" },
        { nome: "Campo Belo do Sul" },
        { nome: "Campo Erê" },
        { nome: "Campos Novos" },
        { nome: "Canelinha" },
        { nome: "Canoinhas" },
        { nome: "Capão Alto" },
        { nome: "Capinzal" },
        { nome: "Capivari de Baixo" },
        { nome: "Catanduvas" },
        { nome: "Caxambu do Sul" },
        { nome: "Celso Ramos" },
        { nome: "Cerro Negro" },
        { nome: "Chapadão do Lageado" },
        { nome: "Chapecó" },
        { nome: "Cocal do Sul" },
        { nome: "Concórdia" },
        { nome: "Cordilheira Alta" },
        { nome: "Coronel Freitas" },
        { nome: "Coronel Martins" },
        { nome: "Correia Pinto" },
        { nome: "Corupá" },
        { nome: "Criciúma" },
        { nome: "Cunha Porã" },
        { nome: "Cunhataí" },
        { nome: "Curitibanos" },
        { nome: "Descanso" },
        { nome: "Dionísio Cerqueira" },
        { nome: "Dona Emma" },
        { nome: "Doutor Pedrinho" },
        { nome: "Entre Rios" },
        { nome: "Ermo" },
        { nome: "Erval Velho" },
        { nome: "Faxinal dos Guedes" },
        { nome: "Flor do Sertão" },
        { nome: "Florianópolis" },
        { nome: "Formosa do Sul" },
        { nome: "Forquilhinha" },
        { nome: "Fraiburgo" },
        { nome: "Frei Rogério" },
        { nome: "Galvão" },
        { nome: "Garopaba" },
        { nome: "Garuva" },
        { nome: "Gaspar" },
        { nome: "Governador Celso Ramos" },
        { nome: "Grão Pará" },
        { nome: "Gravatal" },
        { nome: "Guabiruba" },
        { nome: "Guaraciaba" },
        { nome: "Guaramirim" },
        { nome: "Guarujá do Sul" },
        { nome: "Guatambú" },
        { nome: "Herval d'Oeste" },
        { nome: "Ibiam" },
        { nome: "Ibicaré" },
        { nome: "Ibirama" },
        { nome: "Içara" },
        { nome: "Ilhota" },
        { nome: "Imaruí" },
        { nome: "Imbituba" },
        { nome: "Imbuia" },
        { nome: "Indaial" },
        { nome: "Iomerê" },
        { nome: "Ipira" },
        { nome: "Iporã do Oeste" },
        { nome: "Ipuaçu" },
        { nome: "Ipumirim" },
        { nome: "Iraceminha" },
        { nome: "Irani" },
        { nome: "Irati" },
        { nome: "Irineópolis" },
        { nome: "Itá" },
        { nome: "Itaiópolis" },
        { nome: "Itajaí" },
        { nome: "Itapema" },
        { nome: "Itapiranga" },
        { nome: "Itapoá" },
        { nome: "Ituporanga" },
        { nome: "Jaborá" },
        { nome: "Jacinto Machado" },
        { nome: "Jaguaruna" },
        { nome: "Jaraguá do Sul" },
        { nome: "Jardinópolis" },
        { nome: "Joaçaba" },
        { nome: "Joinville" },
        { nome: "José Boiteux" },
        { nome: "Jupiá" },
        { nome: "Lacerdópolis" },
        { nome: "Lages" },
        { nome: "Laguna" },
        { nome: "Lajeado Grande" },
        { nome: "Laurentino" },
        { nome: "Lauro Muller" },
        { nome: "Lebon Régis" },
        { nome: "Leoberto Leal" },
        { nome: "Lindóia do Sul" },
        { nome: "Lontras" },
        { nome: "Luiz Alves" },
        { nome: "Luzerna" },
        { nome: "Macieira" },
        { nome: "Mafra" },
        { nome: "Major Gercino" },
        { nome: "Major Vieira" },
        { nome: "Maracajá" },
        { nome: "Maravilha" },
        { nome: "Marema" },
        { nome: "Massaranduba" },
        { nome: "Matos Costa" },
        { nome: "Meleiro" },
        { nome: "Mirim Doce" },
        { nome: "Modelo" },
        { nome: "Mondaí" },
        { nome: "Monte Carlo" },
        { nome: "Monte Castelo" },
        { nome: "Morro da Fumaça" },
        { nome: "Morro Grande" },
        { nome: "Navegantes" },
        { nome: "Nova Erechim" },
        { nome: "Nova Itaberaba" },
        { nome: "Nova Trento" },
        { nome: "Nova Veneza" },
        { nome: "Novo Horizonte" },
        { nome: "Orleans" },
        { nome: "Otacílio Costa" },
        { nome: "Ouro" },
        { nome: "Ouro Verde" },
        { nome: "Paial" },
        { nome: "Painel" },
        { nome: "Palhoça" },
        { nome: "Palma Sola" },
        { nome: "Palmeira" },
        { nome: "Palmitos" },
        { nome: "Papanduva" },
        { nome: "Paraíso" },
        { nome: "Passo de Torres" },
        { nome: "Passos Maia" },
        { nome: "Paulo Lopes" },
        { nome: "Pedras Grandes" },
        { nome: "Penha" },
        { nome: "Peritiba" },
        { nome: "Petrolândia" },
        { nome: "Piçarras" },
        { nome: "Pinhalzinho" },
        { nome: "Pinheiro Preto" },
        { nome: "Piratuba" },
        { nome: "Planalto Alegre" },
        { nome: "Pomerode" },
        { nome: "Ponte Alta" },
        { nome: "Ponte Alta do Norte" },
        { nome: "Ponte Serrada" },
        { nome: "Porto Belo" },
        { nome: "Porto União" },
        { nome: "Pouso Redondo" },
        { nome: "Praia Grande" },
        { nome: "Presidente Castelo Branco" },
        { nome: "Presidente Getúlio" },
        { nome: "Presidente Nereu" },
        { nome: "Princesa" },
        { nome: "Quilombo" },
        { nome: "Rancho Queimado" },
        { nome: "Rio das Antas" },
        { nome: "Rio do Campo" },
        { nome: "Rio do Oeste" },
        { nome: "Rio do Sul" },
        { nome: "Rio dos Cedros" },
        { nome: "Rio Fortuna" },
        { nome: "Rio Negrinho" },
        { nome: "Rio Rufino" },
        { nome: "Riqueza" },
        { nome: "Rodeio" },
        { nome: "Romelândia" },
        { nome: "Salete" },
        { nome: "Saltinho" },
        { nome: "Salto Veloso" },
        { nome: "Sangão" },
        { nome: "Santa Cecília" },
        { nome: "Santa Helena" },
        { nome: "Santa Rosa de Lima" },
        { nome: "Santa Rosa do Sul" },
        { nome: "Santa Terezinha" },
        { nome: "Santa Terezinha do Progresso" },
        { nome: "Santiago do Sul" },
        { nome: "Santo Amaro da Imperatriz" },
        { nome: "São Bento do Sul" },
        { nome: "São Bernardino" },
        { nome: "São Bonifácio" },
        { nome: "São Carlos" },
        { nome: "São Cristovão do Sul" },
        { nome: "São Domingos" },
        { nome: "São Francisco do Sul" },
        { nome: "São João Batista" },
        { nome: "São João do Itaperiú" },
        { nome: "São João do Oeste" },
        { nome: "São João do Sul" },
        { nome: "São Joaquim" },
        { nome: "São José" },
        { nome: "São José do Cedro" },
        { nome: "São José do Cerrito" },
        { nome: "São Lourenço do Oeste" },
        { nome: "São Ludgero" },
        { nome: "São Martinho" },
        { nome: "São Miguel da Boa Vista" },
        { nome: "São Miguel do Oeste" },
        { nome: "São Pedro de Alcântara" },
        { nome: "Saudades" },
        { nome: "Schroeder" },
        { nome: "Seara" },
        { nome: "Serra Alta" },
        { nome: "Siderópolis" },
        { nome: "Sombrio" },
        { nome: "Sul Brasil" },
        { nome: "Taió" },
        { nome: "Tangará" },
        { nome: "Tigrinhos" },
        { nome: "Tijucas" },
        { nome: "Timbé do Sul" },
        { nome: "Timbó" },
        { nome: "Timbó Grande" },
        { nome: "Três Barras" },
        { nome: "Treviso" },
        { nome: "Treze de Maio" },
        { nome: "Treze Tílias" },
        { nome: "Trombudo Central" },
        { nome: "Tubarão" },
        { nome: "Tunápolis" },
        { nome: "Turvo" },
        { nome: "União do Oeste" },
        { nome: "Urubici" },
        { nome: "Urupema" },
        { nome: "Urussanga" },
        { nome: "Vargeão" },
        { nome: "Vargem" },
        { nome: "Vargem Bonita" },
        { nome: "Vidal Ramos" },
        { nome: "Videira" },
        { nome: "Vitor Meireles" },
        { nome: "Witmarsum" },
        { nome: "Xanxerê" },
        { nome: "Xavantina" },
        { nome: "Xaxim" },
        { nome: "Zortéa" }
    ],

    "SE": [
        { nome: "Amparo de São Francisco" },
        { nome: "Aquidabã" },
        { nome: "Aracaju" },
        { nome: "Arauá" },
        { nome: "Areia Branca" },
        { nome: "Barra dos Coqueiros" },
        { nome: "Boquim" },
        { nome: "Brejo Grande" },
        { nome: "Campo do Brito" },
        { nome: "Canhoba" },
        { nome: "Canindé de São Francisco" },
        { nome: "Capela" },
        { nome: "Carira" },
        { nome: "Carmópolis" },
        { nome: "Cedro de São João" },
        { nome: "Cristinápolis" },
        { nome: "Cumbe" },
        { nome: "Divina Pastora" },
        { nome: "Estância" },
        { nome: "Feira Nova" },
        { nome: "Frei Paulo" },
        { nome: "Gararu" },
        { nome: "General Maynard" },
        { nome: "Gracho Cardoso" },
        { nome: "Ilha das Flores" },
        { nome: "Indiaroba" },
        { nome: "Itabaiana" },
        { nome: "Itabaianinha" },
        { nome: "Itabi" },
        { nome: "Itaporanga d'Ajuda" },
        { nome: "Japaratuba" },
        { nome: "Japoatã" },
        { nome: "Lagarto" },
        { nome: "Laranjeiras" },
        { nome: "Macambira" },
        { nome: "Malhada dos Bois" },
        { nome: "Malhador" },
        { nome: "Maruim" },
        { nome: "Moita Bonita" },
        { nome: "Monte Alegre de Sergipe" },
        { nome: "Muribeca" },
        { nome: "Neópolis" },
        { nome: "Nossa Senhora Aparecida" },
        { nome: "Nossa Senhora da Glória" },
        { nome: "Nossa Senhora das Dores" },
        { nome: "Nossa Senhora de Lourdes" },
        { nome: "Nossa Senhora do Socorro" },
        { nome: "Pacatuba" },
        { nome: "Pedra Mole" },
        { nome: "Pedrinhas" },
        { nome: "Pinhão" },
        { nome: "Pirambu" },
        { nome: "Poço Redondo" },
        { nome: "Poço Verde" },
        { nome: "Porto da Folha" },
        { nome: "Propriá" },
        { nome: "Riachão do Dantas" },
        { nome: "Riachuelo" },
        { nome: "Ribeirópolis" },
        { nome: "Rosário do Catete" },
        { nome: "Salgado" },
        { nome: "Santa Luzia do Itanhy" },
        { nome: "Santa Rosa de Lima" },
        { nome: "Santana do São Francisco" },
        { nome: "Santo Amaro das Brotas" },
        { nome: "São Cristóvão" },
        { nome: "São Domingos" },
        { nome: "São Francisco" },
        { nome: "São Miguel do Aleixo" },
        { nome: "Simão Dias" },
        { nome: "Siriri" },
        { nome: "Telha" },
        { nome: "Tobias Barreto" },
        { nome: "Tomar do Geru" },
        { nome: "Umbaúba" }
    ],

    "SP": [
        { nome: "Adamantina" },
        { nome: "Adolfo" },
        { nome: "Aguaí" },
        { nome: "Águas da Prata" },
        { nome: "Águas de Lindóia" },
        { nome: "Águas de Santa Bárbara" },
        { nome: "Águas de São Pedro" },
        { nome: "Agudos" },
        { nome: "Alambari" },
        { nome: "Alfredo Marcondes" },
        { nome: "Altair" },
        { nome: "Altinópolis" },
        { nome: "Alto Alegre" },
        { nome: "Alumínio" },
        { nome: "Álvares Florence" },
        { nome: "Álvares Machado" },
        { nome: "Álvaro de Carvalho" },
        { nome: "Alvinlândia" },
        { nome: "Americana" },
        { nome: "Américo Brasiliense" },
        { nome: "Américo de Campos" },
        { nome: "Amparo" },
        { nome: "Analândia" },
        { nome: "Andradina" },
        { nome: "Angatuba" },
        { nome: "Anhembi" },
        { nome: "Anhumas" },
        { nome: "Aparecida" },
        { nome: "Aparecida d'Oeste" },
        { nome: "Apiaí" },
        { nome: "Araçariguama" },
        { nome: "Araçatuba" },
        { nome: "Araçoiaba da Serra" },
        { nome: "Aramina" },
        { nome: "Arandu" },
        { nome: "Arapeí" },
        { nome: "Araraquara" },
        { nome: "Araras" },
        { nome: "Arco-Íris" },
        { nome: "Arealva" },
        { nome: "Areias" },
        { nome: "Areiópolis" },
        { nome: "Ariranha" },
        { nome: "Artur Nogueira" },
        { nome: "Arujá" },
        { nome: "Aspásia" },
        { nome: "Assis" },
        { nome: "Atibaia" },
        { nome: "Auriflama" },
        { nome: "Avaí" },
        { nome: "Avanhandava" },
        { nome: "Avaré" },
        { nome: "Bady Bassitt" },
        { nome: "Balbinos" },
        { nome: "Bálsamo" },
        { nome: "Bananal" },
        { nome: "Barão de Antonina" },
        { nome: "Barbosa" },
        { nome: "Bariri" },
        { nome: "Barra Bonita" },
        { nome: "Barra do Chapéu" },
        { nome: "Barra do Turvo" },
        { nome: "Barretos" },
        { nome: "Barrinha" },
        { nome: "Barueri" },
        { nome: "Bastos" },
        { nome: "Batatais" },
        { nome: "Bauru" },
        { nome: "Bebedouro" },
        { nome: "Bento de Abreu" },
        { nome: "Bernardino de Campos" },
        { nome: "Bertioga" },
        { nome: "Bilac" },
        { nome: "Birigui" },
        { nome: "Biritiba-Mirim" },
        { nome: "Boa Esperança do Sul" },
        { nome: "Bocaina" },
        { nome: "Bofete" },
        { nome: "Boituva" },
        { nome: "Bom Jesus dos Perdões" },
        { nome: "Bom Sucesso de Itararé" },
        { nome: "Borá" },
        { nome: "Boracéia" },
        { nome: "Borborema" },
        { nome: "Borebi" },
        { nome: "Botucatu" },
        { nome: "Bragança Paulista" },
        { nome: "Braúna" },
        { nome: "Brejo Alegre" },
        { nome: "Brodowski" },
        { nome: "Brotas" },
        { nome: "Buri" },
        { nome: "Buritama" },
        { nome: "Buritizal" },
        { nome: "Cabrália Paulista" },
        { nome: "Cabreúva" },
        { nome: "Caçapava" },
        { nome: "Cachoeira Paulista" },
        { nome: "Caconde" },
        { nome: "Cafelândia" },
        { nome: "Caiabu" },
        { nome: "Caieiras" },
        { nome: "Caiuá" },
        { nome: "Cajamar" },
        { nome: "Cajati" },
        { nome: "Cajobi" },
        { nome: "Cajuru" },
        { nome: "Campina do Monte Alegre" },
        { nome: "Campinas" },
        { nome: "Campo Limpo Paulista" },
        { nome: "Campos do Jordão" },
        { nome: "Campos Novos Paulista" },
        { nome: "Cananéia" },
        { nome: "Canas" },
        { nome: "Cândido Mota" },
        { nome: "Cândido Rodrigues" },
        { nome: "Canitar" },
        { nome: "Capão Bonito" },
        { nome: "Capela do Alto" },
        { nome: "Capivari" },
        { nome: "Caraguatatuba" },
        { nome: "Carapicuíba" },
        { nome: "Cardoso" },
        { nome: "Casa Branca" },
        { nome: "Cássia dos Coqueiros" },
        { nome: "Castilho" },
        { nome: "Catanduva" },
        { nome: "Catiguá" },
        { nome: "Cedral" },
        { nome: "Cerqueira César" },
        { nome: "Cerquilho" },
        { nome: "Cesário Lange" },
        { nome: "Charqueada" },
        { nome: "Chavantes" },
        { nome: "Clementina" },
        { nome: "Colina" },
        { nome: "Colômbia" },
        { nome: "Conchal" },
        { nome: "Conchas" },
        { nome: "Cordeirópolis" },
        { nome: "Coroados" },
        { nome: "Coronel Macedo" },
        { nome: "Corumbataí" },
        { nome: "Cosmópolis" },
        { nome: "Cosmorama" },
        { nome: "Cotia" },
        { nome: "Cravinhos" },
        { nome: "Cristais Paulista" },
        { nome: "Cruzália" },
        { nome: "Cruzeiro" },
        { nome: "Cubatão" },
        { nome: "Cunha" },
        { nome: "Descalvado" },
        { nome: "Diadema" },
        { nome: "Dirce Reis" },
        { nome: "Divinolândia" },
        { nome: "Dobrada" },
        { nome: "Dois Córregos" },
        { nome: "Dolcinópolis" },
        { nome: "Dourado" },
        { nome: "Dracena" },
        { nome: "Duartina" },
        { nome: "Dumont" },
        { nome: "Echaporã" },
        { nome: "Eldorado" },
        { nome: "Elias Fausto" },
        { nome: "Elisiário" },
        { nome: "Embaúba" },
        { nome: "Embu" },
        { nome: "Embu-Guaçu" },
        { nome: "Emilianópolis" },
        { nome: "Engenheiro Coelho" },
        { nome: "Espírito Santo do Pinhal" },
        { nome: "Espírito Santo do Turvo" },
        { nome: "Estiva Gerbi" },
        { nome: "Estrela d'Oeste" },
        { nome: "Estrela do Norte" },
        { nome: "Euclides da Cunha Paulista" },
        { nome: "Fartura" },
        { nome: "Fernando Prestes" },
        { nome: "Fernandópolis" },
        { nome: "Fernão" },
        { nome: "Ferraz de Vasconcelos" },
        { nome: "Flora Rica" },
        { nome: "Floreal" },
        { nome: "Florínia" },
        { nome: "Flórida Paulista" },
        { nome: "Franca" },
        { nome: "Francisco Morato" },
        { nome: "Franco da Rocha" },
        { nome: "Gabriel Monteiro" },
        { nome: "Gália" },
        { nome: "Garça" },
        { nome: "Gastão Vidigal" },
        { nome: "Gavião Peixoto" },
        { nome: "General Salgado" },
        { nome: "Getulina" },
        { nome: "Glicério" },
        { nome: "Guaiçara" },
        { nome: "Guaimbê" },
        { nome: "Guaíra" },
        { nome: "Guapiaçu" },
        { nome: "Guapiara" },
        { nome: "Guará" },
        { nome: "Guaraçaí" },
        { nome: "Guaraci" },
        { nome: "Guarani d'Oeste" },
        { nome: "Guarantã" },
        { nome: "Guararapes" },
        { nome: "Guararema" },
        { nome: "Guaratinguetá" },
        { nome: "Guareí" },
        { nome: "Guariba" },
        { nome: "Guarujá" },
        { nome: "Guarulhos" },
        { nome: "Guatapará" },
        { nome: "Guzolândia" },
        { nome: "Herculândia" },
        { nome: "Holambra" },
        { nome: "Hortolândia" },
        { nome: "Iacanga" },
        { nome: "Iacri" },
        { nome: "Iaras" },
        { nome: "Ibaté" },
        { nome: "Ibirá" },
        { nome: "Ibirarema" },
        { nome: "Ibitinga" },
        { nome: "Ibiúna" },
        { nome: "Icém" },
        { nome: "Iepê" },
        { nome: "Igaraçu do Tietê" },
        { nome: "Igarapava" },
        { nome: "Igaratá" },
        { nome: "Iguape" },
        { nome: "Ilha Comprida" },
        { nome: "Ilha Solteira" },
        { nome: "Ilhabela" },
        { nome: "Indaiatuba" },
        { nome: "Indiana" },
        { nome: "Indiaporã" },
        { nome: "Inúbia Paulista" },
        { nome: "Ipauçu" },
        { nome: "Iperó" },
        { nome: "Ipeúna" },
        { nome: "Ipiguá" },
        { nome: "Iporanga" },
        { nome: "Ipuã" },
        { nome: "Iracemápolis" },
        { nome: "Irapuã" },
        { nome: "Irapuru" },
        { nome: "Itaberá" },
        { nome: "Itaí" },
        { nome: "Itajobi" },
        { nome: "Itaju" },
        { nome: "Itanhaém" },
        { nome: "Itaóca" },
        { nome: "Itapecerica da Serra" },
        { nome: "Itapetininga" },
        { nome: "Itapeva" },
        { nome: "Itapevi" },
        { nome: "Itapira" },
        { nome: "Itapirapuã Paulista" },
        { nome: "Itápolis" },
        { nome: "Itaporanga" },
        { nome: "Itapuí" },
        { nome: "Itapura" },
        { nome: "Itaquaquecetuba" },
        { nome: "Itararé" },
        { nome: "Itariri" },
        { nome: "Itatiba" },
        { nome: "Itatinga" },
        { nome: "Itirapina" },
        { nome: "Itirapuã" },
        { nome: "Itobi" },
        { nome: "Itu" },
        { nome: "Itupeva" },
        { nome: "Ituverava" },
        { nome: "Jaborandi" },
        { nome: "Jaboticabal" },
        { nome: "Jacareí" },
        { nome: "Jaci" },
        { nome: "Jacupiranga" },
        { nome: "Jaguariúna" },
        { nome: "Jales" },
        { nome: "Jambeiro" },
        { nome: "Jandira" },
        { nome: "Jardinópolis" },
        { nome: "Jarinu" },
        { nome: "Jaú" },
        { nome: "Jeriquara" },
        { nome: "Joanópolis" },
        { nome: "João Ramalho" },
        { nome: "José Bonifácio" },
        { nome: "Júlio Mesquita" },
        { nome: "Jumirim" },
        { nome: "Jundiaí" },
        { nome: "Junqueirópolis" },
        { nome: "Juquiá" },
        { nome: "Juquitiba" },
        { nome: "Lagoinha" },
        { nome: "Laranjal Paulista" },
        { nome: "Lavínia" },
        { nome: "Lavrinhas" },
        { nome: "Leme" },
        { nome: "Lençóis Paulista" },
        { nome: "Limeira" },
        { nome: "Lindóia" },
        { nome: "Lins" },
        { nome: "Lorena" },
        { nome: "Lourdes" },
        { nome: "Louveira" },
        { nome: "Lucélia" },
        { nome: "Lucianópolis" },
        { nome: "Luís Antônio" },
        { nome: "Luiziânia" },
        { nome: "Lupércio" },
        { nome: "Lutécia" },
        { nome: "Macatuba" },
        { nome: "Macaubal" },
        { nome: "Macedônia" },
        { nome: "Magda" },
        { nome: "Mairinque" },
        { nome: "Mairiporã" },
        { nome: "Manduri" },
        { nome: "Marabá Paulista" },
        { nome: "Maracaí" },
        { nome: "Marapoama" },
        { nome: "Mariápolis" },
        { nome: "Marília" },
        { nome: "Marinópolis" },
        { nome: "Martinópolis" },
        { nome: "Matão" },
        { nome: "Mauá" },
        { nome: "Mendonça" },
        { nome: "Meridiano" },
        { nome: "Mesópolis" },
        { nome: "Miguelópolis" },
        { nome: "Mineiros do Tietê" },
        { nome: "Mira Estrela" },
        { nome: "Miracatu" },
        { nome: "Mirandópolis" },
        { nome: "Mirante do Paranapanema" },
        { nome: "Mirassol" },
        { nome: "Mirassolândia" },
        { nome: "Mococa" },
        { nome: "Mogi das Cruzes" },
        { nome: "Mogi-Guaçu" },
        { nome: "Mogi-Mirim" },
        { nome: "Mombuca" },
        { nome: "Monções" },
        { nome: "Mongaguá" },
        { nome: "Monte Alegre do Sul" },
        { nome: "Monte Alto" },
        { nome: "Monte Aprazível" },
        { nome: "Monte Azul Paulista" },
        { nome: "Monte Castelo" },
        { nome: "Monte Mor" },
        { nome: "Monteiro Lobato" },
        { nome: "Morro Agudo" },
        { nome: "Morungaba" },
        { nome: "Motuca" },
        { nome: "Murutinga do Sul" },
        { nome: "Nantes" },
        { nome: "Narandiba" },
        { nome: "Natividade da Serra" },
        { nome: "Nazaré Paulista" },
        { nome: "Neves Paulista" },
        { nome: "Nhandeara" },
        { nome: "Nipoã" },
        { nome: "Nova Aliança" },
        { nome: "Nova Campina" },
        { nome: "Nova Canaã Paulista" },
        { nome: "Nova Castilho" },
        { nome: "Nova Europa" },
        { nome: "Nova Granada" },
        { nome: "Nova Guataporanga" },
        { nome: "Nova Independência" },
        { nome: "Nova Luzitânia" },
        { nome: "Nova Odessa" },
        { nome: "Novais" },
        { nome: "Novo Horizonte" },
        { nome: "Nuporanga" },
        { nome: "Ocauçu" },
        { nome: "Óleo" },
        { nome: "Olímpia" },
        { nome: "Onda Verde" },
        { nome: "Oriente" },
        { nome: "Orindiúva" },
        { nome: "Orlândia" },
        { nome: "Osasco" },
        { nome: "Oscar Bressane" },
        { nome: "Osvaldo Cruz" },
        { nome: "Ourinhos" },
        { nome: "Ouro Verde" },
        { nome: "Ouroeste" },
        { nome: "Pacaembu" },
        { nome: "Palestina" },
        { nome: "Palmares Paulista" },
        { nome: "Palmeira d'Oeste" },
        { nome: "Palmital" },
        { nome: "Panorama" },
        { nome: "Paraguaçu Paulista" },
        { nome: "Paraibuna" },
        { nome: "Paraíso" },
        { nome: "Paranapanema" },
        { nome: "Paranapuã" },
        { nome: "Parapuã" },
        { nome: "Pardinho" },
        { nome: "Pariquera-Açu" },
        { nome: "Parisi" },
        { nome: "Patrocínio Paulista" },
        { nome: "Paulicéia" },
        { nome: "Paulínia" },
        { nome: "Paulistânia" },
        { nome: "Paulo de Faria" },
        { nome: "Pederneiras" },
        { nome: "Pedra Bela" },
        { nome: "Pedranópolis" },
        { nome: "Pedregulho" },
        { nome: "Pedreira" },
        { nome: "Pedrinhas Paulista" },
        { nome: "Pedro de Toledo" },
        { nome: "Penápolis" },
        { nome: "Pereira Barreto" },
        { nome: "Pereiras" },
        { nome: "Peruíbe" },
        { nome: "Piacatu" },
        { nome: "Piedade" },
        { nome: "Pilar do Sul" },
        { nome: "Pindamonhangaba" },
        { nome: "Pindorama" },
        { nome: "Pinhalzinho" },
        { nome: "Piquerobi" },
        { nome: "Piquete" },
        { nome: "Piracaia" },
        { nome: "Piracicaba" },
        { nome: "Piraju" },
        { nome: "Pirajuí" },
        { nome: "Pirangi" },
        { nome: "Pirapora do Bom Jesus" },
        { nome: "Pirapozinho" },
        { nome: "Pirassununga" },
        { nome: "Piratininga" },
        { nome: "Pitangueiras" },
        { nome: "Planalto" },
        { nome: "Platina" },
        { nome: "Poá" },
        { nome: "Poloni" },
        { nome: "Pompéia" },
        { nome: "Pongaí" },
        { nome: "Pontal" },
        { nome: "Pontalinda" },
        { nome: "Pontes Gestal" },
        { nome: "Populina" },
        { nome: "Porangaba" },
        { nome: "Porto Feliz" },
        { nome: "Porto Ferreira" },
        { nome: "Potim" },
        { nome: "Potirendaba" },
        { nome: "Pracinha" },
        { nome: "Pradópolis" },
        { nome: "Praia Grande" },
        { nome: "Pratânia" },
        { nome: "Presidente Alves" },
        { nome: "Presidente Bernardes" },
        { nome: "Presidente Epitácio" },
        { nome: "Presidente Prudente" },
        { nome: "Presidente Venceslau" },
        { nome: "Promissão" },
        { nome: "Quadra" },
        { nome: "Quatá" },
        { nome: "Queiroz" },
        { nome: "Queluz" },
        { nome: "Quintana" },
        { nome: "Rafard" },
        { nome: "Rancharia" },
        { nome: "Redenção da Serra" },
        { nome: "Regente Feijó" },
        { nome: "Reginópolis" },
        { nome: "Registro" },
        { nome: "Restinga" },
        { nome: "Ribeira" },
        { nome: "Ribeirão Bonito" },
        { nome: "Ribeirão Branco" },
        { nome: "Ribeirão Corrente" },
        { nome: "Ribeirão do Sul" },
        { nome: "Ribeirão dos Índios" },
        { nome: "Ribeirão Grande" },
        { nome: "Ribeirão Pires" },
        { nome: "Ribeirão Preto" },
        { nome: "Rifaina" },
        { nome: "Rincão" },
        { nome: "Rinópolis" },
        { nome: "Rio Claro" },
        { nome: "Rio das Pedras" },
        { nome: "Rio Grande da Serra" },
        { nome: "Riolândia" },
        { nome: "Riversul" },
        { nome: "Rosana" },
        { nome: "Roseira" },
        { nome: "Rubiácea" },
        { nome: "Rubinéia" },
        { nome: "Sabino" },
        { nome: "Sagres" },
        { nome: "Sales" },
        { nome: "Sales Oliveira" },
        { nome: "Salesópolis" },
        { nome: "Salmourão" },
        { nome: "Saltinho" },
        { nome: "Salto" },
        { nome: "Salto de Pirapora" },
        { nome: "Salto Grande" },
        { nome: "Sandovalina" },
        { nome: "Santa Adélia" },
        { nome: "Santa Albertina" },
        { nome: "Santa Bárbara d'Oeste" },
        { nome: "Santa Branca" },
        { nome: "Santa Clara d'Oeste" },
        { nome: "Santa Cruz da Conceição" },
        { nome: "Santa Cruz da Esperança" },
        { nome: "Santa Cruz das Palmeiras" },
        { nome: "Santa Cruz do Rio Pardo" },
        { nome: "Santa Ernestina" },
        { nome: "Santa Fé do Sul" },
        { nome: "Santa Gertrudes" },
        { nome: "Santa Isabel" },
        { nome: "Santa Lúcia" },
        { nome: "Santa Maria da Serra" },
        { nome: "Santa Mercedes" },
        { nome: "Santa Rita d'Oeste" },
        { nome: "Santa Rita do Passa Quatro" },
        { nome: "Santa Rosa de Viterbo" },
        { nome: "Santa Salete" },
        { nome: "Santana da Ponte Pensa" },
        { nome: "Santana de Parnaíba" },
        { nome: "Santo Anastácio" },
        { nome: "Santo André" },
        { nome: "Santo Antônio da Alegria" },
        { nome: "Santo Antônio de Posse" },
        { nome: "Santo Antônio do Aracanguá" },
        { nome: "Santo Antônio do Jardim" },
        { nome: "Santo Antônio do Pinhal" },
        { nome: "Santo Expedito" },
        { nome: "Santópolis do Aguapeí" },
        { nome: "Santos" },
        { nome: "São Bento do Sapucaí" },
        { nome: "São Bernardo do Campo" },
        { nome: "São Caetano do Sul" },
        { nome: "São Carlos" },
        { nome: "São Francisco" },
        { nome: "São João da Boa Vista" },
        { nome: "São João das Duas Pontes" },
        { nome: "São João de Iracema" },
        { nome: "São João do Pau d'Alho" },
        { nome: "São Joaquim da Barra" },
        { nome: "São José da Bela Vista" },
        { nome: "São José do Barreiro" },
        { nome: "São José do Rio Pardo" },
        { nome: "São José do Rio Preto" },
        { nome: "São José dos Campos" },
        { nome: "São Lourenço da Serra" },
        { nome: "São Luís do Paraitinga" },
        { nome: "São Manuel" },
        { nome: "São Miguel Arcanjo" },
        { nome: "São Paulo" },
        { nome: "São Pedro" },
        { nome: "São Pedro do Turvo" },
        { nome: "São Roque" },
        { nome: "São Sebastião" },
        { nome: "São Sebastião da Grama" },
        { nome: "São Simão" },
        { nome: "São Vicente" },
        { nome: "Sarapuí" },
        { nome: "Sarutaiá" },
        { nome: "Sebastianópolis do Sul" },
        { nome: "Serra Azul" },
        { nome: "Serra Negra" },
        { nome: "Serrana" },
        { nome: "Sertãozinho" },
        { nome: "Sete Barras" },
        { nome: "Severínia" },
        { nome: "Silveiras" },
        { nome: "Socorro" },
        { nome: "Sorocaba" },
        { nome: "Sud Mennucci" },
        { nome: "Sumaré" },
        { nome: "Suzanápolis" },
        { nome: "Suzano" },
        { nome: "Tabapuã" },
        { nome: "Tabatinga" },
        { nome: "Taboão da Serra" },
        { nome: "Taciba" },
        { nome: "Taguaí" },
        { nome: "Taiaçu" },
        { nome: "Taiúva" },
        { nome: "Tambaú" },
        { nome: "Tanabi" },
        { nome: "Tapiraí" },
        { nome: "Tapiratiba" },
        { nome: "Taquaral" },
        { nome: "Taquaritinga" },
        { nome: "Taquarituba" },
        { nome: "Taquarivaí" },
        { nome: "Tarabai" },
        { nome: "Tarumã" },
        { nome: "Tatuí" },
        { nome: "Taubaté" },
        { nome: "Tejupá" },
        { nome: "Teodoro Sampaio" },
        { nome: "Terra Roxa" },
        { nome: "Tietê" },
        { nome: "Timburi" },
        { nome: "Torre de Pedra" },
        { nome: "Torrinha" },
        { nome: "Trabiju" },
        { nome: "Tremembé" },
        { nome: "Três Fronteiras" },
        { nome: "Tuiuti" },
        { nome: "Tupã" },
        { nome: "Tupi Paulista" },
        { nome: "Turiúba" },
        { nome: "Turmalina" },
        { nome: "Ubarana" },
        { nome: "Ubatuba" },
        { nome: "Ubirajara" },
        { nome: "Uchoa" },
        { nome: "União Paulista" },
        { nome: "Urânia" },
        { nome: "Uru" },
        { nome: "Urupês" },
        { nome: "Valentim Gentil" },
        { nome: "Valinhos" },
        { nome: "Valparaíso" },
        { nome: "Vargem" },
        { nome: "Vargem Grande do Sul" },
        { nome: "Vargem Grande Paulista" },
        { nome: "Várzea Paulista" },
        { nome: "Vera Cruz" },
        { nome: "Vinhedo" },
        { nome: "Viradouro" },
        { nome: "Vista Alegre do Alto" },
        { nome: "Vitória Brasil" },
        { nome: "Votorantim" },
        { nome: "Votuporanga" },
        { nome: "Zacarias" }
    ],

    "TO": [
        { nome: "Abreulândia" },
        { nome: "Aguiarnópolis" },
        { nome: "Aliança do Tocantins" },
        { nome: "Almas" },
        { nome: "Alvorada" },
        { nome: "Ananás" },
        { nome: "Angico" },
        { nome: "Aparecida do Rio Negro" },
        { nome: "Aragominas" },
        { nome: "Araguacema" },
        { nome: "Araguaçu" },
        { nome: "Araguaína" },
        { nome: "Araguanã" },
        { nome: "Araguatins" },
        { nome: "Arapoema" },
        { nome: "Arraias" },
        { nome: "Augustinópolis" },
        { nome: "Aurora do Tocantins" },
        { nome: "Axixá do Tocantins" },
        { nome: "Babaçulândia" },
        { nome: "Bandeirantes do Tocantins" },
        { nome: "Barra do Ouro" },
        { nome: "Barrolândia" },
        { nome: "Bernardo Sayão" },
        { nome: "Bom Jesus do Tocantins" },
        { nome: "Brasilândia do Tocantins" },
        { nome: "Brejinho de Nazaré" },
        { nome: "Buriti do Tocantins" },
        { nome: "Cachoeirinha" },
        { nome: "Campos Lindos" },
        { nome: "Cariri do Tocantins" },
        { nome: "Carmolândia" },
        { nome: "Carrasco Bonito" },
        { nome: "Caseara" },
        { nome: "Centenário" },
        { nome: "Chapada da Natividade" },
        { nome: "Chapada de Areia" },
        { nome: "Colinas do Tocantins" },
        { nome: "Colméia" },
        { nome: "Combinado" },
        { nome: "Conceição do Tocantins" },
        { nome: "Couto de Magalhães" },
        { nome: "Cristalândia" },
        { nome: "Crixás do Tocantins" },
        { nome: "Darcinópolis" },
        { nome: "Dianópolis" },
        { nome: "Divinópolis do Tocantins" },
        { nome: "Dois Irmãos do Tocantins" },
        { nome: "Dueré" },
        { nome: "Esperantina" },
        { nome: "Fátima" },
        { nome: "Figueirópolis" },
        { nome: "Filadélfia" },
        { nome: "Formoso do Araguaia" },
        { nome: "Fortaleza do Tabocão" },
        { nome: "Goianorte" },
        { nome: "Goiatins" },
        { nome: "Guaraí" },
        { nome: "Gurupi" },
        { nome: "Ipueiras" },
        { nome: "Itacajá" },
        { nome: "Itaguatins" },
        { nome: "Itapiratins" },
        { nome: "Itaporã do Tocantins" },
        { nome: "Jaú do Tocantins" },
        { nome: "Juarina" },
        { nome: "Lagoa da Confusão" },
        { nome: "Lagoa do Tocantins" },
        { nome: "Lajeado" },
        { nome: "Lavandeira" },
        { nome: "Lizarda" },
        { nome: "Luzinópolis" },
        { nome: "Marianópolis do Tocantins" },
        { nome: "Mateiros" },
        { nome: "Maurilândia do Tocantins" },
        { nome: "Miracema do Tocantins" },
        { nome: "Miranorte" },
        { nome: "Monte do Carmo" },
        { nome: "Monte Santo do Tocantins" },
        { nome: "Muricilândia" },
        { nome: "Natividade" },
        { nome: "Nazaré" },
        { nome: "Nova Olinda" },
        { nome: "Nova Rosalândia" },
        { nome: "Novo Acordo" },
        { nome: "Novo Alegre" },
        { nome: "Novo Jardim" },
        { nome: "Oliveira de Fátima" },
        { nome: "Palmas" },
        { nome: "Palmeirante" },
        { nome: "Palmeiras do Tocantins" },
        { nome: "Palmeirópolis" },
        { nome: "Paraíso do Tocantins" },
        { nome: "Paranã" },
        { nome: "Pau d'Arco" },
        { nome: "Pedro Afonso" },
        { nome: "Peixe" },
        { nome: "Pequizeiro" },
        { nome: "Pindorama do Tocantins" },
        { nome: "Piraquê" },
        { nome: "Pium" },
        { nome: "Ponte Alta do Bom Jesus" },
        { nome: "Ponte Alta do Tocantins" },
        { nome: "Porto Alegre do Tocantins" },
        { nome: "Porto Nacional" },
        { nome: "Praia Norte" },
        { nome: "Presidente Kennedy" },
        { nome: "Pugmil" },
        { nome: "Recursolândia" },
        { nome: "Riachinho" },
        { nome: "Rio da Conceição" },
        { nome: "Rio dos Bois" },
        { nome: "Rio Sono" },
        { nome: "Sampaio" },
        { nome: "Sandolândia" },
        { nome: "Santa Fé do Araguaia" },
        { nome: "Santa Maria do Tocantins" },
        { nome: "Santa Rita do Tocantins" },
        { nome: "Santa Rosa do Tocantins" },
        { nome: "Santa Tereza do Tocantins" },
        { nome: "Santa Terezinha Tocantins" },
        { nome: "São Bento do Tocantins" },
        { nome: "São Félix do Tocantins" },
        { nome: "São Miguel do Tocantins" },
        { nome: "São Salvador do Tocantins" },
        { nome: "São Sebastião do Tocantins" },
        { nome: "São Valério da Natividade" },
        { nome: "Silvanópolis" },
        { nome: "Sítio Novo do Tocantins" },
        { nome: "Sucupira" },
        { nome: "Taguatinga" },
        { nome: "Taipas do Tocantins" },
        { nome: "Talismã" },
        { nome: "Tocantínia" },
        { nome: "Tocantinópolis" },
        { nome: "Tupirama" },
        { nome: "Tupiratins" },
        { nome: "Wanderlândia" },
        { nome: "Xambioá" }
    ],
}

export {
    states, cities
}