function checkTheme() {
  // const theme = localStorage.getItem('theme') ?? 'light';

  // if (theme === 'dark') {
  //   document.body.classList.add('dark');
  // } else {
  //   document.body.classList.remove('dark');
  // }

  document.body.classList.add('dark');
}

export default checkTheme;
