import React from 'react';
import * as HeroIcons from 'react-icons/hi';

export default [
  {
    title: 'Painel de Controle',
    path: '/dashboard',
    icon: <HeroIcons.HiOutlineHome size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineHome size="1.5em" />,
    iconOpen: <HeroIcons.HiHome size="1.5em" />,
    gap: false,
  },
  {
    title: 'Clientes',
    path: '/customers',
    icon: <HeroIcons.HiOutlineUser size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineUser size="1.5em" />,
    iconOpen: <HeroIcons.HiUser size="1.5em" />,
    gap: false,
  },
  {
    title: 'Serviços',
    path: '/services',
    icon: <HeroIcons.HiOutlineBriefcase size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineBriefcase size="1.5em" />,
    iconOpen: <HeroIcons.HiBriefcase size="1.5em" />,
    gap: false,
  },
  {
    title: 'Agendamentos',
    path: '/calendar',
    icon: <HeroIcons.HiOutlineCalendar size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineCalendar size="1.5em" />,
    iconOpen: <HeroIcons.HiCalendar size="1.5em" />,
    gap: false,
  },
  {
    title: 'Pagamentos',
    path: '/payments',
    icon: <HeroIcons.HiOutlineCreditCard size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineCreditCard size="1.5em" />,
    iconOpen: <HeroIcons.HiCreditCard size="1.5em" />,
    gap: false,
  },
  {
    title: 'Declarações',
    path: '/declarations',
    icon: <HeroIcons.HiOutlineClipboardList size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineClipboardList size="1.5em" />,
    iconOpen: <HeroIcons.HiClipboardList size="1.5em" />,
    gap: false,
  },
  {
    title: 'Comunicados',
    path: '/handout',
    icon: <HeroIcons.HiOutlineClipboardList size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineClipboardList size="1.5em" />,
    iconOpen: <HeroIcons.HiClipboardList size="1.5em" />,
    gap: false,
  },
  {
    title: 'Produtos',
    path: '/products',
    icon: <HeroIcons.HiOutlineCollection size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineCollection size="1.5em" />,
    iconOpen: <HeroIcons.HiCollection size="1.5em" />,
    gap: false,
  },
  {
    title: 'Relátorios',
    path: '/reports',
    icon: <HeroIcons.HiOutlineChartPie size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineChartPie size="1.5em" />,
    iconOpen: <HeroIcons.HiChartPie size="1.5em" />,
    gap: false,
  },
  {
    title: 'Configurações',
    path: '/configurations',
    icon: <HeroIcons.HiOutlineTemplate size="1.5em" />,
    iconClosed: <HeroIcons.HiOutlineTemplate size="1.5em" />,
    iconOpen: <HeroIcons.HiTemplate size="1.5em" />,
    gap: false,
  },
];
